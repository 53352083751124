import { Typography } from '@mui/material'
import React from 'react'
import { deleteAnswers } from '../../Api/Core/Processors'
import AddExercice from '../../Components/AddExercice/AddExercice'
import { PaperStyled, Container } from './Styled'

const CourseManager = () => {

  const handleDelete = async () => {
    console.log('deleting');
    await deleteAnswers()
  }

  return (
    <Container>

      <PaperStyled>
        <Typography variant="h5">Processors</Typography>
        <button onClick={handleDelete}>delete answers</button>
      </PaperStyled>
      <AddExercice />
    </Container>
  )
}

export default CourseManager
