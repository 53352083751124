import React, { Fragment, useState } from 'react'
import { useAnimation } from 'framer-motion'
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentLineState, currentStepValue } from '../../Recoil/GlobalState';
import { convertToHex } from '../../helper';
import { Steps } from '../../Api/Core/Interfaces';

interface Props {
  hex: boolean
}

const ProcessorWithoutMA = ({ hex }: Props) => {
  const [currentLine] = useRecoilState(currentLineState);
  const currentStepVal = useRecoilValue(currentStepValue)
  const [hexState, setHexState] = useState({ ma: false, pc: false, ir: true, acc: false })

  const hexColor = '#a7501e'

  const primaryFontColor = "#000000"
  const secondaryFontColor = "#fff"
  // const controls = useAnimation()
  const registreColor = "#a5d9f1"
  const otherColors = "#f1f0a5"
  const titleBoxColor = '#a7501e'
  const lineColorOn = "red"
  const lineColorOff = "white"
  const arrowColorOn = "#1279ee"
  const strokeWidth = "3"
  const titleBoxSize = "14"
  const fontSize = "20"
  const numberFontFamily = "consolas"
  // const regularFontFamily = "consolas"
  const smallTextSize = "10"
  // const circlesSize = "6"
  const dataMemColor = "#0c0"

  // useEffect(() => {
  //   if (stepList[currentStep] && stepList[currentStep].state_status === StateStatus.Fetch) {
  //     console.log('supposed to do something here')
  //     controls.start(() => ({
  //       stroke: "#f75e5e",
  //       pathLength: 1,
  //       transition: { duration: 10, ease: "easeOut" },
  //     }))
  //   }
  //   return controls.stop
  // }, [currentStep, stepList])


  const getStatus = () => {
    let status = ''
    console.log('🚀 ~ getStatus ~ currentStepVal', currentStepVal);
    switch ((currentStepVal as Steps)?.state_status) {
      case 0:
        status = 'Fetch'
        break;
      case 1:
        status = 'Decode'
        break;
      case 2:
        status = 'Execute'
        break;
    }
    return status

  }

  // const variants = {
  //   hidden: { opacity: 0, strokeWidth: 1, fill: "red" },
  // }

  const overHex = (hexValue: any) => {
    switch (hexValue) {
      case 'ma':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ma }))
        break
      case 'pc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.pc }))
        break
      case 'acc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.acc }))
        break
      case 'ir':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ir }))
        break
    }
    console.log('supposed to change')
  }

  return (
    <Fragment>{currentStepVal && (currentStepVal as Steps).lines &&
      <svg data-name="Layer 1" viewBox="0 0 1400 600" style={{ width: "100%", height: "auto" }}>
        <defs />
        <g transform="translate(150,0)">
          <g transform="translate(0,80)">
            <path fill={dataMemColor} d="M300.6 30.5h130v190h-130z" data-name="cell- 9MH3P SVimNwaclgp3E-3" />

            <g strokeWidth={strokeWidth} id="line-14" data-name="cell- 9MH3P SVimNwaclgp3E-55" stroke={(currentStepVal as Steps).lines.l14 ? lineColorOn : lineColorOff}>
              <path d="M1070.6 115h67" fill="none" strokeMiterlimit="10" />
              <circle cx="1140.6" cy="115" r="3" />
            </g>

            <g strokeWidth={strokeWidth} id="line-5" stroke={(currentStepVal as Steps).lines.l5 ? lineColorOn : lineColorOff} data-name="cell- 9MH3P SVimNwaclgp3E-43" strokeMiterlimit="10">
              <path d="M263.04 168h32.92" fill="none" />
              <path d="M301.17 168.06l-7 3.47 1.76-3.49-1.74-3.51z" />
            </g>

            <path id="multiplex-1" d="M228.04 123l35 20v50l-35 20z" fill="#fff" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-9" />

            <g strokeWidth={strokeWidth} id="line-13" data-name="cell- 9MH3P SVimNwaclgp3E-53" stroke={(currentStepVal as Steps).lines.l13 ? lineColorOn : lineColorOff}>
              <path d="M900.1 115h33.13" fill="none" strokeMiterlimit="10" />
              <path d="M939.48 115l-7 3.5 1.75-3.5-1.75-3.5z" strokeMiterlimit="10" />
            </g>

            <path id="multiplex-2" d="M868.1 70l35 20v50l-35 20z" fill="#fff" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-10" />

            <g data-name="cell- 9MH3P SVimNwaclgp3E-11">
              <path fill={registreColor} stroke="#fff" d="M30.54 110.5h130v60h-130z" data-name="cell- 9MH3P SVimNwaclgp3E-1" />
              <path fill="none" d="M25.92 109h40v20h-40z" />
              <text transform="translate(35.15 123.5)" fontSize={titleBoxSize} fill={titleBoxColor} fontFamily="ArialMT, Arial" >
                PC
              </text>
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-16">
              <path fill="none" d="M70.54 130.5h40v20h-40z" />
              <text fontWeight="bold" textAnchor="middle" transform="translate(96 144)" fontSize="20" fontFamily={numberFontFamily} >
                {currentStepVal && hexState.pc ? convertToHex((currentStepVal as Steps).pc_status) : (currentStepVal as Steps).pc_status}
              </text>
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-12">
              <path fill="none" d="M307.6 30.5h40v20h-40z" />
              <text transform="translate(305.43 44)" fontSize={titleBoxSize} fill={titleBoxColor} fontFamily="ArialMT, Arial" >
                MEMORY
              </text>
            </g>


            <g data-name="cell- 9MH3P SVimNwaclgp3E-14">
              <g transform="translate(-755 -75) scale(1.3 1)">
                <polygon points="1105.31 146.82 1105.31 223.55 1203.31 246.22 1203.31 169.48 1105.31 146.82" fill="#9a9b9b" />
                <polygon points="1105.31 312.98 1203.31 294.52 1203.31 224.08 1105.31 240.45 1105.31 312.98" fill="#9a9b9b" />
              </g>
              <path fill="none" d="M677.6 70.5h40v20h-40z" />
              <text transform="translate(685.43 94)" fontSize={titleBoxSize} fill={titleBoxColor} fontFamily="ArialMT, Arial" >
                ALU
              </text>
              {/* <g strokeWidth="20" data-name="cell- 9MH3P SVimNwaclgp3E-20">
          <path fill="none" d="M725.6 143h40v20h-40z" />
          <text transform="translate(730.2 156)" fontSize="16" fontFamily="ArialMT, Arial" >
          <tspan letterSpacing="-.07em">V</tspan><tspan x="9.48" y="0">alue</tspan>
          </text>
        </g> */}
            </g>
            <g data-name="cell- 9MH3P SVimNwaclgp3E-15">
              <path fill={otherColors} stroke={secondaryFontColor} d="M940.6 85h130v60h-130z" data-name="cell- 9MH3P SVimNwaclgp3E-6" />
              <path fill="none" d="M938.6 85h40v20h-40z" />
              <text transform="translate(945.43 98)" fontSize={titleBoxSize} fill={titleBoxColor} fontFamily="ArialMT, Arial" >
                ACC
              </text>
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-21">
              <path fill="none" d="M985.6 105h40v20h-40z" />
              <text fontWeight="bold" textAnchor="middle" transform="translate(1000 118)" fontSize="20" fontFamily={numberFontFamily} >
                {currentStepVal && hexState.acc ? convertToHex((currentStepVal as Steps).acc_status) : (currentStepVal as Steps).acc_status}
              </text>
            </g>

            <g id="line-2" strokeWidth={strokeWidth} data-name="cell- 9MH3P SVimNwaclgp3E-28" stroke={(currentStepVal as Steps).lines.l2 ? lineColorOn : lineColorOff}>
              <path d="M110.54 75.5h90v62" fill="none" strokeMiterlimit="10" />
              <circle cx="200.54" cy="140.5" r="3" />
            </g>


            <g id="+1" data-name="cell- 9MH3P SVimNwaclgp3E-23">
              <path fill="#fff" stroke="#fff" d="M80.54 60.5h30v30h-30z" data-name="cell- 9MH3P SVimNwaclgp3E-22" />
              <path fill="none" d="M81.54 62.5h28v26h-28z" />
              <text transform="translate(88.26 79)" fontSize="12" fontFamily="ArialMT, Arial" >
                +1
              </text>
            </g>

            <path d="M170.54 150.5l20-20" fill="none" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-30" />

            <g data-name="cell- 9MH3P SVimNwaclgp3E-31">
              <path fill="none" d="M155.54 121.5h40v20h-40z" />
              <text fill="#fff" transform="translate(168.43 135)" fontSize="8" fontFamily="ArialMT, Arial" >
                16
              </text>
            </g>


            <g data-name="cell- 9MH3P SVimNwaclgp3E-33">
              <path fill="none" d="M426.6 121.5h40v20h-40z" />
              <text fill="#fff" transform="translate(439.43 135)" fontSize="8" fontFamily="ArialMT, Arial" >
                16
              </text>
            </g>
            <path d="M441.6 150.5l20-20" fill="none" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-34" />

            <g data-name="cell- 9MH3P SVimNwaclgp3E-36">
              <path fill="none" d="M435.45 277.56h40v20h-40z" />
              <text fill="#fff" transform="translate(433.6 291.06)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                IR&lt;7,0&gt;
              </text>
            </g>

            <path d="M423.6 289.5l20-20" fill="none" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-37" />


            <g data-name="cell- 9MH3P SVimNwaclgp3E-39">
              <path fill="none" d="M630.6 121.5h40v20h-40z" />
              <text fill="#fff" transform="translate(643.43 135)" fontSize="8" fontFamily="ArialMT, Arial" >
                16
              </text>
            </g>
            <path d="M645.6 150.5l20-20" fill="none" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-40" />

            <g data-name="cell- 9MH3P SVimNwaclgp3E-41">
              <path fill="none" d="M1083.6 96h40v20h-40z" />
              <text fill="#fff" transform="translate(1096.43 109)" fontSize="8" fontFamily="ArialMT, Arial" >
                16
              </text>
              <path d="M1098.6 125l20-20" fill="none" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-42" />
            </g>

            <g id="line-12" strokeWidth={strokeWidth} data-name="cell- 9MH3P SVimNwaclgp3E-50" stroke={(currentStepVal as Steps).lines.l12 ? lineColorOn : lineColorOff} strokeMiterlimit="10">
              <path d="M810.6 140.06h51.13" fill="none" />
              <path d="M866.98 140l-7 3.51 1.75-3.5-1.75-3.5z" />
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-51">
              <path fill="none" d="M810.6 121.5h40v20h-40z" />
              <text fill="#fff" transform="translate(823.43 135)" fontSize="8" fontFamily="ArialMT, Arial" >
                16
              </text>
            </g>

            <path d="M825.6 150.5l20-20" fill="none" stroke="#fff" strokeMiterlimit="10" data-name="cell- 9MH3P SVimNwaclgp3E-52" />


            <g id="sel_mem_addr_arrow">
              <g strokeWidth={strokeWidth} data-name="cell- 9MH3P SVimNwaclgp3E-56" id="line-19" stroke={(currentStepVal as Steps).lines.l19 ? arrowColorOn : lineColorOff} strokeMiterlimit="10">
                <path d="M246.32 340.8l-.76-131.43" fill="none" />
                <path d="M245.53 204.12l3.54 7-3.51-1.73-3.48 1.75z" fill="red" />
              </g>
              <g data-name="cell- 9MH3P SVimNwaclgp3E-57">
                <path fill="none" d="M224.02 227.93h27v13.5h-27z" />
                <text fill="#fff" transform="translate(220.75 237.05)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                  sel_mem_addr
                </text>
              </g>
            </g>

            <g id="wr_mem_arrow">
              <g strokeWidth={strokeWidth} data-name="cell- 9MH3P SVimNwaclgp3E-58" id="line-20" stroke={(currentStepVal as Steps).lines.l20 ? arrowColorOn : lineColorOff} strokeMiterlimit="10">
                <path d="M365.66 341.34l-.13-115.22" fill="none" />
                <path d="M365.52 220.87l3.47 7-3.49-1.76-3.51 1.74z" fill="red" />
              </g>
              <g data-name="cell- 9MH3P SVimNwaclgp3E-59">
                <path fill="none" d="M345.6 241.25h40v20h-40z" />
                <text fill="#fff" transform="translate(350.6 254)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                  wr_mem
                </text>
              </g>
            </g>

            <g id="op_alu_arrow">
              <g data-name="cell- 9MH3P SVimNwaclgp3E-60" strokeWidth={strokeWidth} id="line-21" stroke={(currentStepVal as Steps).lines.l21 ? arrowColorOn : lineColorOff} strokeMiterlimit="10">
                <path d="M745.56 341.64V236.87" fill="none" />
                <path d="M745.52 231.62l3.5 7-3.5-1.75-3.5 1.75z" fill={(currentStepVal as Steps).lines.l21 ? arrowColorOn : lineColorOff} />
              </g>
              <g data-name="cell- 9MH3P SVimNwaclgp3E-61">
                <path fill="none" d="M725.6 252h40v20h-40z" />
                <text fill="#fff" transform="translate(727.08 265)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                  op_alu
                </text>
              </g>
            </g>

            <g id="sel_acc_data_arrow">
              <g strokeWidth={strokeWidth} id="line-22" stroke={(currentStepVal as Steps).lines.l22 ? arrowColorOn : lineColorOff} data-name="cell- 9MH3P SVimNwaclgp3E-62" strokeMiterlimit="10">
                <path d="M886.6 339.72l-1-182.85" fill="none" />
                <path d="M885.53 151.62l3.54 7-3.51-1.73-3.48 1.75z" fill={(currentStepVal as Steps).lines.l22 ? arrowColorOn : lineColorOff} />
              </g>
              <g data-name="cell- 9MH3P SVimNwaclgp3E-63">
                <path fill="none" d="M865.6 172h40v20h-40z" />
                <text fill="#fff" transform="translate(862.76 185)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                  sel_ac_data
                </text>
              </g>
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-64">
              <path fill="none" d="M300.6 77.5h40v20h-40z" />
              <text transform="translate(303.7 90)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                data_in
              </text>
            </g>
            <g data-name="cell- 9MH3P SVimNwaclgp3E-65">
              <path fill="none" d="M294.6 157h40v20h-40z" />
              <text transform="translate(304.09 170)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                addr
              </text>
            </g>
            <g data-name="cell- 9MH3P SVimNwaclgp3E-66">
              <path fill="none" d="M388.6 129h40v20h-40z" />
              <text transform="translate(388.64 142)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                data_out
              </text>
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-67">
              <path fill="none" d="M667.6 130.5h40v20h-40z" />
              <text transform="translate(683.77 143)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" letterSpacing="-.06em" >
                A
              </text>
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-68">
              <path fill="none" d="M667.6 192h40v20h-40z" />
              <text transform="translate(683.77 205)" fontSize={smallTextSize} fontFamily="ArialMT, Arial" >
                B
              </text>
            </g>


            {/* <path id="sys-manager-box" fill="#fff" stroke="#000" d="M180.58 340.5h820v60h-820z" /> */}

            <path id='line-3' d="M160.56 140.5h40" fill="none" stroke={(currentStepVal as Steps).lines.l3 ? lineColorOn : lineColorOff} strokeMiterlimit="10" strokeWidth={strokeWidth} />

            <g strokeWidth={strokeWidth} id="line-4" stroke={(currentStepVal as Steps).lines.l4 ? lineColorOn : lineColorOff} strokeMiterlimit="10">
              <path d="M200.58 140.5l20.79-.24" fill="none" />
              <path d="M226.62 140.2l-7 3.58 1.71-3.52-1.79-3.48z" />
            </g>

            <g id="line-16" stroke={(currentStepVal as Steps).lines.l16 ? lineColorOn : lineColorOff} strokeMiterlimit="10" strokeWidth={strokeWidth} >
              <path d="M1140.58 114.5V.5h-880v87h33.63" fill="none" />
              <path d="M299.46 87.5l-7 3.5 1.75-3.5-1.75-3.5z" />
            </g>

            <g id="line-15" stroke={(currentStepVal as Steps).lines.l15 ? lineColorOn : lineColorOff} strokeMiterlimit="10" strokeWidth={strokeWidth} >
              <path d="M674.21 202.5h-33.63v78h500" fill="none" />
              <path d="M679.48 202.5l-7 3.5 1.75-3.5-1.75-3.5z" />
            </g>

            <g id="line-17" stroke={(currentStepVal as Steps).lines.l17 ? lineColorOn : lineColorOff} strokeMiterlimit="10" strokeWidth={strokeWidth} >
              <path d="M1140.58 280.5v63.63" fill="none" />
              <path d="M1140.58 349.38l-3.5-7 3.5 1.75 3.5-1.75z" />
            </g>

            <g id="line-27" stroke={(currentStepVal as Steps).lines.l27 ? lineColorOn : lineColorOff} strokeMiterlimit="10" strokeWidth={strokeWidth} >
              <path d="M1120.58 370.5h-113.63" fill="none" />
              <path d="M1001.7 370.5l7-3.5-1.75 3.5 1.75 3.5z" />
            </g>

            <path id="calculation-box" fill="#fff" stroke="#000" d="M1120.58 350.5h40v40h-40z" />


            <g id="triangles" fill="none" stroke="#363636" strokeWidth="2">
              <path d="M300.58 190.5l14.25 9.5-14.25 9.5z" strokeMiterlimit="10" />
              <path d="M940 123l14.25 9.5-14.25 9.5z" strokeMiterlimit="10" />
              <path d="M30.52 148l14.25 9.5-14.25 9.5z" strokeMiterlimit="10" />
            </g>


            <path id="line-23" strokeWidth={strokeWidth} d="M1140.6 280.5l.12-165.8" fill="none" stroke={(currentStepVal as Steps).lines.l23 ? lineColorOn : lineColorOff} strokeMiterlimit="10" />
          </g>

          <g id="code-line-box">
            <path fill="#fff" stroke="#000" d="M560.6.5h290v60h-290z" />
            <text transform="translate(685 34)" fontWeight="bold" textAnchor="middle" fill={primaryFontColor} fontSize={fontSize} fontFamily="ArialMT, Arial" style={{ isolation: "isolate" }}>
              {currentLine ? currentLine.split("//")[0] : currentLine}
            </text>
          </g>

          <g id="state-box">
            <path fill="#fff" stroke="#000" d="M430.6.5h120v60h-120z" />
            <text transform="translate(485 34)" fontWeight="bold" textAnchor="middle" fill={primaryFontColor} fontSize={fontSize} fontFamily="ArialMT, Arial" style={{ isolation: "isolate" }}>
              {getStatus()}
            </text>
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-13">
            <path fill={registreColor} stroke="#fff" d="M30.54 281.25h130v60h-130z" data-name="cell- 9MH3P SVimNwaclgp3E-4" />
            <path fill="none" d="M23.54 280.25h40v20h-40z" />
            <text transform="translate(35 293)" fontSize="12" fill={titleBoxColor} fontFamily="ArialMT, Arial">
              IR
            </text>
            <g data-name="cell- 9MH3P SVimNwaclgp3E-21">
              <text fontWeight="bold" textAnchor="middle" fill={primaryFontColor} transform="translate(90 320)" fontSize={fontSize} fontFamily={numberFontFamily}>
                {currentStepVal && hexState.ir ? convertToHex((currentStepVal as Steps).ir_status) : (currentStepVal as Steps).ir_status}
              </text>
            </g>
            <path d="M30 320l14.25 9.5-14.25 9.5z" fill="none" stroke="#000" strokeMiterlimit="10" />
          </g>



          <g id="line-7" stroke={(currentStepVal as Steps).lines.l7 ? lineColorOn : lineColorOff} strokeMiterlimit="10" strokeWidth={strokeWidth}>
            <path d="M550.58 221.5v139H.58v-50l23.57.59" fill="none" />
            <path d="M29.4 311.22l-7.08 3.33 1.83-3.46-1.66-3.54z" fill="#9673a6" />
          </g>

          <path id="line-6" stroke={(currentStepVal as Steps).lines.l6 ? lineColorOn : lineColorOff} strokeWidth={strokeWidth} d="M430.87 222.56l119.72-1.06" fill="none" strokeMiterlimit="10" />

          <g id="line-9" stroke={(currentStepVal as Steps).lines.l9 ? lineColorOn : lineColorOff} strokeWidth={strokeWidth} strokeMiterlimit="10">
            <path d="M550.59 221.5h122.85" fill="none" />
            <path d="M678.69 221.54l-7 3.5 1.75-3.5-1.75-3.54z" />
          </g>


          <g data-name="cell- 9MH3P SVimNwaclgp3E-48" id="line-8" stroke={(currentStepVal as Steps).lines.l8 ? lineColorOn : lineColorOff} strokeWidth={strokeWidth}>
            <path d="M550.59 218.5v-88h290V199h21.1" fill="none" strokeMiterlimit="10" />
            <circle cx="550.59" cy="221.5" r="3" />
            <path d="M866.97 199l-7 3.5 1.75-3.5-1.75-3.5z" strokeMiterlimit="10" />
          </g>

          <path id="control-signal-box" fill="#ffffff" d="M220.59 420.5h780v60h-780z" />


          <path d="M160.53 311.25l40.06.25" id="line-24" stroke={(currentStepVal as Steps).lines.l24 ? lineColorOn : lineColorOff} strokeWidth={strokeWidth} strokeMiterlimit="10" />

          <g id="line-25" stroke={(currentStepVal as Steps).lines.l25 ? lineColorOn : lineColorOff} strokeWidth={strokeWidth}>
            <path d="M200.59 307.5v-51l21.56.11" fill="none" strokeMiterlimit="10" />
            <circle cx="200.59" cy="310.5" r="3" fill="#82b366" />
            <path d="M227.4 256.63l-7 3.47 1.77-3.49-1.73-3.51z" fill="#82b366" strokeMiterlimit="10" />
          </g>


        </g>

        <g transform="translate(50,0)" strokeWidth={strokeWidth}>
          {/* <g id="line-1" strokeWidth={strokeWidth} data-name="cell- 9MH3P SVimNwaclgp3E-28" stroke={(currentStepVal as Steps).lines.l1 ? lineColorOn : lineColorOff}>
          <path d="M80.54 75.5H.5v65h23.67" fill="none" />
          <path d="M29.42 140.5l-7 3.5 1.75-3.5-1.75-3.5z" />
        </g> */}
          <g id="line-29" data-name="cell-7CEJRbiM89k- FdsS5ZK-7" stroke={(currentStepVal as Steps).lines.l29 ? arrowColorOn : lineColorOff} strokeMiterlimit="10">
            <path d="M60.5 292v-33.63" fill="none" />
            <path d="M60.5 253.12l3.5 7-3.5-1.75-3.5 1.75z" fill={(currentStepVal as Steps).lines.l29 ? arrowColorOn : lineColorOff} />
          </g>
          <g id="line-1" data-name="cell-7CEJRbiM89k- FdsS5ZK-8" stroke={(currentStepVal as Steps).lines.l1 ? lineColorOn : lineColorOff} strokeMiterlimit="10">
            <path d="M180.44 157H.53v45.31h30.89" fill="none" />
            <path d="M36.67 202.31l-7 3.5 1.75-3.5-1.75-3.5z" fill={(currentStepVal as Steps).lines.l1 ? lineColorOn : lineColorOff} />
          </g>


          <g id="line-31" data-name="cell-7CEJRbiM89k- FdsS5ZK-3" stroke={(currentStepVal as Steps).lines.l31 ? lineColorOn : lineColorOff} strokeMiterlimit="10">
            <path d="M73 220.25h28.72l22.35.22" fill="none" />
            <path d="M129.32 220.49l-7 3.43 1.78-3.48-1.71-3.52z" fill={(currentStepVal as Steps).lines.l31 ? lineColorOn : lineColorOff} />
          </g>

          <path id="mux" d="M38 175.25l35 20v50l-35 20z" fill="#fff" stroke="#fff" strokeMiterlimit="10" data-name="cell-7CEJRbiM89k- FdsS5ZK-2" />

          <g id="line-26" data-name="cell-7CEJRbiM89k- FdsS5ZK-9">
            <path d="M300.5 420.5v-110" fill="none" stroke={(currentStepVal as Steps).lines.l26 ? lineColorOn : lineColorOff} strokeMiterlimit="10" />
          </g>

          <g data-name="cell-7CEJRbiM89k- FdsS5ZK-10" stroke={(currentStepVal as Steps).lines.l30 ? lineColorOn : lineColorOff} strokeMiterlimit="10">
            <path d="M300.5 420.5v30h13.63" fill="none" />
            <path d="M319.38 450.5l-7 3.5 1.75-3.5-1.75-3.5z" fill={(currentStepVal as Steps).lines.l30 ? lineColorOn : lineColorOff} />
          </g>

          <g id="line-28" data-name="cell-7CEJRbiM89k- FdsS5ZK-4" stroke={(currentStepVal as Steps).lines.l28 ? lineColorOn : lineColorOff} strokeMiterlimit="10">
            <path d="M297.5 420.5H.5v-178l31.13.21" fill="none" strokeMiterlimit="10" />
            <circle cx="300.5" cy="420.5" r="3" fill={(currentStepVal as Steps).lines.l1 ? lineColorOn : lineColorOff} />
            <path d="M36.88 242.74l-7 3.46 1.77-3.49-1.72-3.51z" fill={(currentStepVal as Steps).lines.l1 ? lineColorOn : lineColorOff} strokeMiterlimit="10" />
          </g>

          <g
            onClick={() => overHex('pc')}
            style={{ cursor: "pointer" }}
            transform="translate(10,60)"
          >
            <rect
              x="220.33"
              y="134.5"
              width="24.77"
              height="16"
              rx="2.4"
              strokeWidth="1"
              fill={hexState.pc ? hexColor : '#fff'}
              stroke={hexState.pc ? '#fff' : hexColor}
            />
            <text
              transform="translate(223.11 145)"
              fontSize="10"
              fill={hexState.pc ? 'snow' : hexColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              {hexState.pc ? "Hex" : "Dec"}
            </text>
          </g>

          <g
            onClick={() => overHex('ir')}
            style={{ cursor: "pointer" }}
            transform="translate(10,-8)"
          >
            <rect
              x="221.5"
              y="294"
              width="24.77"
              height="16"
              strokeWidth="1"
              rx="2.4"
              fill={hexState.ir ? hexColor : '#fff'}
              stroke={hexState.ir ? '#fff' : hexColor}
            />
            <text
              transform="translate(225.11 305)"
              fontSize="10"
              fill={hexState.ir ? 'snow' : hexColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              {hexState.ir ? "Hex" : "Dec"}
            </text>
          </g>

          <g
            onClick={() => overHex('acc')}
            style={{ cursor: "pointer" }}
            transform="translate(-90,21)"
          >
            <rect
              x="1232.56"
              y="147"
              width="24.77"
              height="16"
              rx="2.4"
              strokeWidth="1"
              fill={hexState.acc ? hexColor : '#fff'}
              stroke={hexState.acc ? '#fff' : hexColor}
            />
            <text
              transform="translate(1235.11 158)"
              fontSize="10"
              fill={hexState.acc ? 'snow' : hexColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              {hexState.acc ? "Hex" : "dec"}
            </text>
          </g>






        </g>


      </svg>}
    </Fragment >)
}

export default ProcessorWithoutMA