import styled from "styled-components";
import { Paper, Typography } from "@mui/material";

export const Container = styled.div`
  display: flex;
  width: 100%;
  /* height: 93vh; */
  margin: 55px 5px;
  `
export const DropzoneContainer = styled.div`
  width: 100%;
  display: flex;
  height: 33px;
  && span{
    margin-right: 10px;
  }
  margin: 1em 0;
`
export const InputForm = styled.input`
  display: none
`

export const List = styled.ul`
  display: flex;
  flex-direction: column
`
export const ListItem = styled.li`
display: flex;
margin: .5em;
`

export const Title = styled(Typography)`
/* margin: .5em; */
  align-self: center;
  margin: .5em;
`

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  background-color: #1f2227;
  padding: 1em;
  margin: 1em;
  height: 270px;
`

