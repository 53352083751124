import React, { Fragment } from 'react'
import { useQuery } from '@tanstack/react-query';
import { Navigate } from "react-router-dom";
import { useAuth } from "../../Api/Auth/testingAuth";
import { useSetRecoilState } from 'recoil';
import { loadingPageState } from '../../Recoil/GlobalState';

interface Props {
    children: JSX.Element,
    needsAdmin?: boolean
};

export const ProtectedRoute = ({ children, needsAdmin }: Props) => {
    const { checkConnected, userInfo, isConnected } = useAuth();
    const setLoadingPageState = useSetRecoilState(loadingPageState)
    const info = useQuery({
        queryKey: ["user1"],
        queryFn: async () => await checkConnected(),
        onSuccess: (data) => {
            console.log("what???: ", data)
        }
    });

    if (info.isFetching) {
        setLoadingPageState(true)
        return null
    }

    const checkAdminRestriction = (): boolean => {
        console.log("🚀 ~ file: ProtectedRoute.tsx:31 ~ checkAdminRestriction ~ needsAdmin:", needsAdmin)
        if (needsAdmin) {
            console.log("this print needs admin", userInfo.isAdmin)
            return userInfo.isAdmin
        }
        return isConnected
    }

    if (info.isFetched) {
        setLoadingPageState(false)
        console.log("can it connect yo: ", checkAdminRestriction())
    }


    return <Fragment>
        {!checkAdminRestriction() ? <Navigate to="/login" replace /> : children}
    </Fragment>
};