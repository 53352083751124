import styled from "styled-components";
import { MenuList, ListItemIcon } from "@mui/material";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  height: 100%;
  background-color: #1A1D21;
  `
export const MenuListStyled = styled(MenuList)`
  margin-top: 55px;
  width: 100%;
  color: white;
  & .MuiListItemIcon-root{
    min-width:35px;
  }
`;

interface Props {
  current: boolean;
}

export const ListItemIconStyled = styled(ListItemIcon) <Props>`
  color: ${(props: Props) => props.current ? "#2196F3" : "#fff"};
`
