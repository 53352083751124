import { AppBar, Button, Toolbar } from '@mui/material'
import styled from 'styled-components'

export const ToolbarStyled = styled(Toolbar)`
display: flex;
/* flex-direction: row; */
justify-content: space-between;
align-items: center;
width: 100%;
/* margin-bottom: 30px; */
/* padding: 1em; */
/* height: 50px */
`

export const MenuContainer = styled.div`
  margin: 0 1em;
`;

export const LoginButton = styled(Button)`
    margin: 0 1em;
`;
export const HeaderStyled = styled(AppBar)`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 1em;
    height: 50px
    `

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    img{
        width: 40px;
        height: 40px;
        margin-right: 0.3em;
    }
    a{
        margin-left: .5em;
        text-decoration: none;
    }
    `
export const LoginContainer = styled.div`
    display: flex;
    align-items: center;
    
    `

export const AdminButton = styled(Button)`
  margin-left:1em;
`;
