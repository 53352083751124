import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { useAuth } from '../../Api/Auth/use-auth'
import ChangePassword from '../../Components/ChangePassword/ChangePassword'
import { userProfileState } from '../../Recoil/GlobalState'
import { Title, Container, PaperStyled, ListItem } from './Styled'

const ProfilePage = () => {
  const { t } = useTranslation()
  const [userProfile] = useRecoilState(userProfileState)
  // const auth = useAuth()

  const redirect = () => {
    console.log("hello")
  }

  // useEffect(() => {
  //   const source = axios.CancelToken.source();
  //   console.log('🚀 ~ useEffect ~ auth.user', auth.user);
  //   if (auth.user) {
  //     axios.get(`${getApiAddress()}api/user`, {
  //       cancelToken: source.token,
  //       headers: {
  //         "x-auth-token": localStorage.getItem('token') || ''
  //       }
  //     }).then((response) => {
  //       const data = response.data
  //       setUserInfo(response.data);
  //       setUserProfile(
  //         {
  //           matricule: data.matricule,
  //           firstname: data.firstname,
  //           lastname: data.lastname,
  //           email: data.email,
  //           role: data.role
  //         })
  //       // setInfo(response.data)
  //       console.log('🚀 ~ useEffect ~ response.data', response.data);
  //     }).catch((error) => {
  //       console.log('🚀 ~ axios.get ~ error', error);
  //       if (axios.isCancel(error)) return;
  //     });
  //   }

  //   return () => source.cancel();
  // }, [auth.user]);

  return (
    <Container>
      <PaperStyled>
        <Title variant="h5">{t("profile_page.personal_information")}</Title>
        <ListItem>
          {userProfile.firstname} {userProfile.lastname}
        </ListItem>
        <ListItem>
          <Typography>{userProfile.matricule}</Typography>
        </ListItem>
        <ListItem>

          <Typography>{userProfile.email}</Typography>
        </ListItem>

      </PaperStyled>
      <PaperStyled>
        <Title variant="h5">{t("profile_page.change_password")}</Title>
        <ChangePassword matricule={userProfile.matricule.toString()} redirect={redirect} />
      </PaperStyled>
    </Container>
  )
}

export default ProfilePage
