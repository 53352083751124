import React from 'react'
import {
  IconButton,
  Typography,
  Button,
  FormControl,
  Menu,
  MenuItem,
  Select,
  AppBar,
  Box,
  LinearProgress
} from '@mui/material'
import { Link, useNavigate } from 'react-router-dom';

import logo from '../../Imgs/code_machine_logo.png'
import { AccountCircle } from '@mui/icons-material'
import '../../App.css'
import { MenuContainer, HeaderStyled, LoginButton, LoginContainer, TitleContainer, ToolbarStyled } from './Styled'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../../Api/Auth/testingAuth';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { loadingPageState } from '../../Recoil/GlobalState';

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { t, i18n } = useTranslation();
  // const [value, setValue] = useState(0);
  let navigate = useNavigate();
  const loadingState = useRecoilValue(loadingPageState)
  const { userInfo,isConnected, signOut } = useAuth()

  const handleChangeLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value);
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = () => {
    signOut()
    handleClose()
    navigate("/login")
  }

  const handleProfile = () => {
    handleClose()
    navigate("/profile")
  }

  const handleConnect = () => {
    navigate("/login")
    handleClose()
  }

  return (
    <AppBar className="header" sx={{ background: '#0e1d31' }}>
      <ToolbarStyled>
        <TitleContainer>
          <img src={logo} alt="Logo" />
          <Typography component={Link} to="/" color="textPrimary" variant="h6">
            Code Machine
          </Typography>
        </TitleContainer>
        <LoginContainer>

          <FormControl variant='standard' sx={{ margin: 2 }}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={i18n.language}
              onChange={handleChangeLanguage}
            >
              <MenuItem value="fr">FR</MenuItem>
              <MenuItem value="en">EN</MenuItem>
              <MenuItem value="de">DE</MenuItem>
            </Select>
          </FormControl>
          {isConnected ?
            <MenuContainer>
              <IconButton
                size="medium"
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="primary"
                onClick={handleClick}
              >
                <AccountCircle fontSize="large" />
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleProfile}>Profile</MenuItem>
                <MenuItem onClick={handleSignOut}>Logout</MenuItem>
              </Menu>
            </MenuContainer> :
            <LoginButton onClick={handleConnect} variant="contained" color="primary">
              {t("login.login")}
            </LoginButton>
          }
          {userInfo.isAdmin && <Button component={Link} to="/admin" variant="contained">Admin</Button>}
        </LoginContainer>
      </ToolbarStyled>
      {loadingState &&
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      }
    </AppBar>
  )
}
