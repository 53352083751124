import React, { Fragment, useEffect, useState } from 'react'
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil'
// import { compile } from '../../Api/Core/Processors'
import Assembler from '../../Containers/Assembler/Assembler'
import Compile from '../../Containers/Compile/Compile'
import Hexa from '../../Containers/Hexa/Hexa'
import Media from '../../Containers/Media/Media'
import Memory from '../../Containers/Memory/Memory'
// import Output from '../../Containers/Output/Output'
// import Waveform from '../../Containers/Waveform/Waveform'
import './processors.css'
import {
  assemblerEditorContentValue,
  assemblerEditorState,
  currentStepState,
  errorState,
  modalState,
  hexaEditorState,
  stepListState,
  userState,
  answersState,
} from './../../Recoil/GlobalState'
import Spinner from './../../Components/Spinner/Spinner'
import { CleanedData, Problem, Severity } from '../../Api/Core/Interfaces'
import { useNavigate, useLocation } from 'react-router-dom'
import MyModal from '../../Components/Modal/Modal'
// import { Typography } from '@mui/material'
import Answers from '../../Containers/Answers/Answers'
// import { useAuth } from '../../Api/Auth/use-auth'
import { data as d0, program as p0 } from '../../Api/Data/ProcessorWithoutMaDefault'
import { data as d1, program as p1 } from '../../Api/Data/ProcessorWithMaDefault'
import { data as d2, program as p2 } from '../../Api/Data/ProcessorRiscSimpleDefault'
export const defaultText =
  `.text
.data
`;

const ProcessorsPage = () => {
  const setStepList = useSetRecoilState(stepListState)
  const resetStepListState = useResetRecoilState(stepListState)
  const setHexaState = useSetRecoilState(hexaEditorState)
  const [loading, setLoading] = useState(false)
  // const setCurrentStep = useSetRecoilState(currentStepState)
  const resetCurrentStep = useResetRecoilState(currentStepState)
  // const resetAssemblerContent = useResetRecoilState(assemblerEditorState)
  const setAssemblerContent = useSetRecoilState(assemblerEditorState)
  const setCurrentErrorState = useSetRecoilState(errorState)
  const location = useLocation()
  const navigate = useNavigate()
  const locationState = location.state as { problem: Problem, answer: { program: string[], data: CleanedData | undefined }, type: number }
  console.log("🚀 ~ file: ProcessorsPage.tsx:51 ~ ProcessorsPage ~ location.state:", location.state)
  const [openModal, setOpenModal] = useRecoilState(modalState)
  const [answers] = useRecoilState(answersState)
  const [showHexaContainer, setShowHexaContainer] = useState(false)
  // const auth = useAuth()

  const getDefaultValues = (type: number) => {
    switch (type) {
      case 0:
        return { program: p0, data: d0 }
      case 1:
        return { program: p1, data: d1 }
      case 2:
        return { program: p2, data: d2 }
      default:
        return { program: p0, data: d0 }
    }
  }


  useEffect(() => {
    console.log("processor page is here")
    if (locationState === null) {
      console.log("🚀 ~ file: ProcessorsPage.tsx:73 ~ useEffect ~ locationState:", locationState)
      navigate('/')
      return;
    }
    const { problem, answer } = locationState
    console.log("🚀 ~ file: ProcessorsPage.tsx:71 ~ useEffect ~ answer:", answer)
    console.log("🚀 ~ file: ProcessorsPage.tsx:71 ~ useEffect ~ problem:", problem)

    if (!problem || !answer) {
      console.log("nothing here")
      navigate('/')
    } else {
      console.log('🚀 ~ useEffect ~ answer', answer);
      const program = answer.program.length ? answer.program : getDefaultValues(locationState.type).program
      console.log('🚀 ~ useEffect ~ program', program);

      const assemblerContent = program.join('\n')
      setAssemblerContent((prev) => ({
        ...prev, content: assemblerContent, newLoadedData: true
      }))

      resetCurrentStep()
      resetStepListState()

      if (answer.data) {
        console.log("*********************there's some data here")
        setStepList(answer.data.steps)
        setHexaState((prev) => ({
          ...prev,
          content: answer.data!.hex.join('\n'),
        }))
        // setCurrentErrorState((prev) => ({
        //   ...prev,
        //   message: 'compilation was successful',
        //   open: true,
        //   severity: Severity.Success,
        // }))
      }
      else {
        const data = JSON.parse(getDefaultValues(locationState.type).data)
        console.log("*********************there's some data here")
        setStepList(data.steps)
        setHexaState((prev) => ({
          ...prev,
          content: data!.hex.join('\n'),
        }))
        // setCurrentErrorState((prev) => ({
        //   ...prev,
        //   message: 'compilation was successful',
        //   open: true,
        //   severity: Severity.Success,
        // }))
      }
    }

  }, [locationState])

  const handleModal = () => {
    setOpenModal((prev) => ({ ...prev, showAnswers: false }))
  }

  return (
    <Fragment>
      {loading ? (
        <Spinner />
      ) : (
        locationState ? <div className="container">
          <Compile problemId={locationState.problem._id} processorId={locationState.type} />
          <Memory processor={locationState.type} />
          {/* <Output /> */}
          <div className="assembler">
            <Assembler setShowHexaContainer={setShowHexaContainer} showHexaContainer={showHexaContainer} />
            <Hexa showHexaContainer={showHexaContainer} setShowHexaContainer={setShowHexaContainer} />
          </div>
          <Media />
          <MyModal handleClose={handleModal} open={openModal.showAnswers}>
            <Answers answers={answers} problemId={locationState.problem._id} processorId={locationState.type} />
          </MyModal>
        </div> : <p>you should be redirected</p>
      )}
    </Fragment>
  )
}

export default ProcessorsPage
