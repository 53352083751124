import React from 'react'
import '../../App.css'
import { Container, SendButton } from './Styled'
import SendIcon from '@mui/icons-material/Send'
// import DescriptionIcon from '@mui/icons-material/Description';
import { useTranslation } from 'react-i18next'
import {
  answersState,
  assemblerEditorState,
  currentStepState,
  errorState,
  hexaEditorState,
  loadingState,
  modalState,
  stepListState,
} from '../../Recoil/GlobalState'
import { useRecoilState, useSetRecoilState } from 'recoil'
import CircularProgress from '@mui/material/CircularProgress'
import { compile, getAllProblemAnswers } from './../../Api/Core/Processors'
import { Severity } from '../../Api/Core/Interfaces'
import { IconButton } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useAuth } from '../../Api/Auth/testingAuth';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

interface Props { problemId: string, processorId: number }

const Compiler = ({ problemId, processorId }: Props) => {
  const { t } = useTranslation()
  const [assemblerState] =
    useRecoilState(assemblerEditorState)
  const setHexaState = useSetRecoilState(hexaEditorState)
  const [loadingValues, setLoadingState] = useRecoilState(loadingState)
  const setStepList = useSetRecoilState(stepListState)
  const setCurrentStepPos = useSetRecoilState(currentStepState)
  const setCurrentErrorState = useSetRecoilState(errorState)
  const setModalStatus = useSetRecoilState(modalState)
  const setAnswers = useSetRecoilState(answersState)
  const auth = useAuth()
  // const {steps, hex} = useRecoilValue(stepListQuery);

  // const toArray = (data: string): string[] => {
  //   console.log('this is the data', data.split('\n'))
  //   return ['2']
  // }

  // useEffect(() => {

  // },)

  const handleClick = async () => {
    console.log('🚀 ~ handleClick ~ processorId', processorId);
    console.log('🚀 ~ handleClick ~ problemId', problemId);
    setCurrentStepPos(0)
    try {
      setLoadingState((prev) => ({ ...prev, compiling: true }))
      if (auth.isConnected) {
        const data = await compile(auth.userInfo.matricule, assemblerState.content.split('\n'), processorId, problemId)

        console.log('🚀 ~ handleClick ~ assemblerState.content', assemblerState.content);
        if (data) {
          setStepList(data.steps)
          setHexaState((prev) => ({
            ...prev,
            content: data.hex.join('\n'),
          }))
        }
      } else {
        setCurrentErrorState((prev) => ({
          ...prev,
          message: "No user was found",
          open: true,
          severity: Severity.Error,
        }))
      }
      setLoadingState((prev) => ({ ...prev, compiling: false }))
    } catch (err: any) {
      setCurrentErrorState((prev) => ({
        ...prev,
        message: err.message,
        open: true,
        severity: Severity.Error,
      }))
      setLoadingState((prev) => ({ ...prev, compiling: false }))
    }
  }

  const loadAllAnswers = async () => {

    setLoadingState((prev) => ({ ...prev, answersLoad: true }))
    try {
      const data = await getAllProblemAnswers(problemId)

      if (data) {
        setAnswers(data)
      }
      setModalStatus((prev) => ({ ...prev, showAnswers: true }))
    } catch (err: any) {
      setCurrentErrorState((prev) => ({
        ...prev,
        message: err.message,
        open: true,
        severity: Severity.Error,
      }))
    }
    setLoadingState((prev) => ({ ...prev, answersLoad: false }))
  }

  return (
    <Container className="compile">
      {/* <IconButton color="primary" aria-label="add an alarm">
                <DescriptionIcon fontSize="large" />
            </IconButton> */}
      {/* <IconButton color="primary" aria-label="add an alarm">
                <CloudUploadIcon fontSize="large" />
            </IconButton> */}
      <Tooltip TransitionComponent={Zoom} enterDelay={2000} title={t("compile.tooltip.download") || ""} arrow>
        <IconButton onClick={loadAllAnswers} color="primary" aria-label="add an alarm">
          {
            loadingValues.answersLoad ?
              <CircularProgress size={24} />
              :
              <CloudDownloadIcon fontSize="large" />
          }
        </IconButton>
      </Tooltip>

      <Tooltip TransitionComponent={Zoom} enterDelay={2000} title={t("compile.tooltip.send") || ""} arrow>
        <SendButton
          disabled={loadingValues.compiling}
          onClick={handleClick}
          variant="outlined"
          color="primary"
          endIcon={
            loadingValues.compiling ? (
              <CircularProgress size={24} />
            ) : (
              <SendIcon />
            )
          }
        >
          {t('compile.t')}
        </SendButton>
      </Tooltip>
    </Container>
  )
}

export default Compiler
