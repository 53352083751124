import styled from 'styled-components'
import { FormControl, FormControlLabel } from '@mui/material'

export const FormControlStyled = styled(FormControl)`
    /* display: flex; */
    flex-direction: row;
    margin-bottom: 1em;
    
`
export const Content = styled.div`
    display: flex;
    flex-direction: column;
    `

export const ProcessorBoxContainer = styled.div`
    display: flex;
	position: relative;
	width: 100%;
    height: 500px;

    &>svg{

    }
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    img{
        width: 40px;
        height: 60px;
    }
    `

export const MemBoxContainer = styled.div`
    padding: 0;
    margin: .5em;
`;

export const MemPosition = styled.div`
    padding: 0;
    margin: 0;
    background-color: purple;
`;

export const MemoryBox = styled.span`
    margin: 0;
    width: 40px;
    height: 20px;
    background: #4a6c8b;
    border-radius: 5px;
    padding: .2em;
    justify-content: center;
    color: white;
    display: flex;

    &&:hover{
       background: red; 
    }
`

export const FormControlLabelStyled = styled(FormControlLabel)`
    color: white;
    margin: 0 1em;
`

export const ContentContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
