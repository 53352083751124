import React, { ChangeEvent, Fragment, useEffect, useRef, useState } from 'react'
import { FormControl, NativeSelect } from '@mui/material'
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
// import "ace-builds/src-noconflict/mode-java";
// import "ace-builds/src-noconflict/theme-github";
// import "ace-builds/src-noconflict/theme-monokai";
import '../../App.css'
import { Container, ArrowIconButton, EditorStyled, SettingsIconButton } from './Styled';
import { currentStepState, hexaEditorState, stepListState } from '../../Recoil/GlobalState';
import { useRecoilState, useRecoilValue } from 'recoil';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { Steps } from '../../Api/Core/Interfaces';
import CustomAssemblyMode from '../Assembler/CustomAssemblyMode';

function handleControlChange(event: ChangeEvent) {
  console.log("change", event.target);
}

interface Props {
  showHexaContainer: boolean;
  setShowHexaContainer: React.Dispatch<React.SetStateAction<boolean>>
}

const Hexa = ({ setShowHexaContainer, showHexaContainer }: Props) => {
  const [hexaState, setHexaState] = useRecoilState(hexaEditorState);
  const currentStep = useRecoilValue(currentStepState);
  const hexaEditor = useRef(null)
  const [linesPos, setlinesPos] = useState([] as number[]);
  const [stepList] = useRecoilState(stepListState);
  const { t } = useTranslation()

  useEffect(() => {
    getFullLineNumbers()
    const realPos: number = linesPos[stepList[currentStep] ? parseInt((stepList as Steps[])[currentStep].pc_status) : 0];

    if (hexaEditor && hexaEditor.current) {
      (hexaEditor as any).current.editor.gotoLine(realPos)
    }
  }, [currentStep])

  useEffect(() => {
    const customMode = new CustomAssemblyMode("");
    if (hexaEditor.current != null) {
      (hexaEditor as any).current.editor.session.setMode(customMode);
    }
  }, [hexaEditor.current])

  const getFullLineNumbers = () => {
    let pos = 1
    const fullLines = []
    const lines = hexaState.content.split("\n");
    for (const line of lines) {
      if (line !== "") {
        fullLines.push(pos)
      }
      pos++
    }
    setlinesPos(fullLines)
  }

  const onChange = (newValue: string) => {
    getFullLineNumbers()

    setHexaState((old) => ({ ...old, content: newValue }))
  }

  const handleSettings = () => {
    console.log('settings')
  }


  return (
    <Fragment>
      {showHexaContainer &&
        <Container>
          <SettingsIconButton
            size="medium"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            onClick={handleSettings}
            color={"primary"}
          >
            <SettingsIcon fontSize="large" />
          </SettingsIconButton>
          <Tooltip title={t("hexa.tooltip.hideHex") || ""} arrow>
            <ArrowIconButton
              size="medium"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={() => setShowHexaContainer(false)}
              color={"primary"}
            >
              <CloseIcon fontSize="large" />
            </ArrowIconButton>
          </Tooltip>
          <FormControl>
            <NativeSelect
              onChange={handleControlChange}
              name="age"
              inputProps={{ 'aria-label': 'age' }}
            >
              <option value={10}>Hexa</option>
            </NativeSelect>
          </FormControl>
          <EditorStyled
            ref={hexaEditor}
            width={hexaState.width}
            mode={hexaState.mode}
            fontSize={hexaState.fontSize}
            theme="monokai"
            readOnly={true}
            value={hexaState.content}
            name="hexa"
            editorProps={{ $blockScrolling: true }}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
              showLineNumbers: true,
              tabSize: 4,
            }}
          />
        </Container>
      }
    </Fragment>
  )
}

export default Hexa
