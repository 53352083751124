import styled from 'styled-components'
import { IconButton } from '@mui/material'
import AceEditor from "react-ace";

export const EditorStyled = styled(AceEditor)`
    height: 94% !important;
    width: 100%;
    margin-top: .5em;

    && .ace_scroller{
        background: #1F2227;
    }

    && .ace_gutter{
        background: #35383E;
    }

    && .ace_gutter-active-line{
        background: rgb(23, 105, 170);
    }
`


export const Container = styled.div`
    position: relative;
    
    margin-left: 1em;
`

export const SettingsIconButton = styled(IconButton)`
    position: absolute !important;
    top: 0;
    right: 45px;
    `

export const ArrowIconButton = styled(IconButton)`
    position: absolute !important;
    right: 0px;
    top: 0;
`
