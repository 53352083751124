import styled from 'styled-components'
import { Button, Typography } from '@mui/material'

export const Status = styled(Typography)`
    width: 100px;
    height: 40px;
    background: white;
    display: flex;
    justify-content: center;
    font-weight: bold;
    margin: 1em;
    align-items: center;
    `

export const Instruction = styled(Typography)`
    font-weight: bold;
    margin: 1em;
    width: 300px;
    height: 40px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Container = styled.div`
    /* min-width: 200px; */
    display: flex;
    color: black;
    flex-wrap: wrap;
`

export const MainContainer = styled.div`
    /* min-width: 200px; */
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 350px;
`

export const Title = styled(Typography)`

`

export const Hex = styled(Button)`
    position: absolute;
    top: 5px;
    margin: 2px;
    right: 10px;
    width: 35px;
    min-width: 35px;
    height: 25px;
    display: flex;
    background: #0f86d4;
    justify-content: center;
    align-items: center;
`

export const Value = styled(Typography)`

`

export const Boxes = styled.div`
    position: relative;
    width: 150px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: .5em;
    border-radius: 3px;
`

export const IrBox = styled(Boxes)`
    background: #97fcff
`

export const AccBox = styled(Boxes)`
    background: #97ffc8
`

export const PcBox = styled(Boxes)`
    background: #97fcff
`

export const MaBox = styled(Boxes)`
    background: #97fcff
`

export const MemBox = styled(Boxes)`
    background: #dfca6f
`