import styled from 'styled-components'
import { Paper } from '@mui/material'
import { Typography } from '@mui/material';

export const NoAnswersYet = styled(Typography)`
  padding: 1em;
`;

export const Code = styled.div`
  margin: .5em auto;
  background-color: #000;
  display: flex;
  padding: 1em;
  align-self: center;
  flex-direction: column;
  justify-self: center;
`

export const Wrapper = styled(Paper)`
    /* width: 300px; */
    max-height: 60vh;
    overflow-y: auto;
    display: flex;
    position: absolute;
    top: 20%;
    left: 40%;
`
