import React from 'react'
import { createBrowserRouter, Navigate } from "react-router-dom";
import { AuthProvider } from './Api/Auth/testingAuth';
import { ProvideAuth } from './Api/Auth/use-auth';

import App from './App';
import Error from './Components/Error/Error'
import ErrorBoundary from './Components/ErrorBoundary/ErrorBoundary';
import CourseManager from './Containers/CourseManager/CourseManager';
import { ProtectedRoute } from './Containers/ProtectedRoute/ProtectedRoute';
import UserManager from './Containers/UserManager/UserManager';
import AdminPage from './Pages/AdminPage/AdminPage';
import CachePage from './Pages/CachePage/CachePage';
import HomePage from './Pages/HomePage/HomePage';
import LoginPage from './Pages/LoginPage/LoginPage';
import NotFoundPage from './Pages/NotFoundPage/NotFoundPage';
import ProcessorsPage from './Pages/ProcessorsPage/ProcessorsPage';
import ProfilePage from './Pages/ProfilePage/ProfilePage';


export const router = createBrowserRouter([
  {
    path: "/",
    element: <AuthProvider><App /></AuthProvider>,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute>
          <HomePage />
        </ProtectedRoute>
        ,
      },
      {
        path: "/cache",
        element: <ProtectedRoute needsAdmin><CachePage /></ProtectedRoute>,
      },
      {
        path: "/admin",
        element: <ProtectedRoute needsAdmin>
          <AdminPage />
        </ProtectedRoute>,
        children: [
          {
            path: "/admin",
            element: <UserManager />,
          },
          {
            path: "/admin/courses",
            element: <CourseManager />,
          },
        ]
      },
      {
        path: "/profile",
        element: <ProfilePage />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/processors",
        element: <ProtectedRoute>
          <ProcessorsPage />
        </ProtectedRoute>,
      },
    ]
  }, { path: "*", element: <NotFoundPage /> }
]);