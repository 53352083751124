import React, { Fragment } from 'react'
import Button from '@mui/material/Button'
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar'
import MuiAlert, { AlertProps } from '@mui/material/Alert';
// import { useEffect } from 'react'
// import { Severity } from '../../Api/Core/Interfaces'
import { useRecoilState } from 'recoil';
import { errorState } from '../../Recoil/GlobalState'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomSnackbar() {
  const [currentErrorState, setCurrentErrorState] = useRecoilState(errorState)
  const HideDuration = 3000
  const vertical = "bottom"
  const horizontal = "left"
  // useEffect(() => {
  //     if (currentErrorState.open) {
  //         handleClick()
  //     }
  // }, [currentErrorState.open])

  // const handleClick = () => {
  //     setCurrentErrorState((prev) => ())
  // }

  const handleClose = (event: Event | React.SyntheticEvent<any, Event>, reason?: SnackbarCloseReason) => {
    if (reason === 'clickaway') {
      return
    }

    setCurrentErrorState((prev) => ({ ...prev, open: false }))
  }

  return (
    <Fragment>
      <Snackbar
        open={currentErrorState.open}
        autoHideDuration={HideDuration}
        onClose={handleClose}
        anchorOrigin={{ vertical, horizontal }}
      >
        <Alert
          onClose={handleClose}
          severity={currentErrorState.severity}
        >
          {currentErrorState.message}
        </Alert>
      </Snackbar>
    </Fragment>
  )
}
