import React from 'react'
import Modal from '@mui/material/Modal';
// import { useRecoilState } from 'recoil';
// import { modalState } from '../../Recoil/GlobalState';

interface Props {
  children: JSX.Element;
  open: boolean;
  handleClose: () => void;
}

const MyModal = (props: Props) => {

  return (
    <Modal
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {props.children}
    </Modal>
  )
}

export default MyModal
