import React, { useState } from 'react'
import { Hex, MaBox, Status, MainContainer, Container, Title, Value, IrBox, AccBox, PcBox } from './Styled'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentLineState, currentStepValue } from '../../Recoil/GlobalState'
import { convertToHex } from '../../helper'
import { Steps } from '../../Api/Core/Interfaces'
import { Button, Stack } from '@mui/material'
// import { Button, Typography } from '@mui/material'

interface Props {
  processor: number
}

const ProcessorBox = ({ processor }: Props) => {
  // const [currentLine] = useRecoilState(currentLineState)
  const currentStepVal = useRecoilValue(currentStepValue)
  const [hexState, setHexState] = useState({ pc: true, ir: true, acc: false, mem: false, ma: true })

  const getStatus = () => {
    let status = ''
    switch ((currentStepVal as Steps)?.state_status) {
      case 0:
        status = 'Fetch'
        break;
      case 1:
        status = 'Decode'
        break;
      case 2:
        status = 'Execute'
        break;
    }
    return status
  }

  const overHex = (hexValue: any) => {
    switch (hexValue) {
      case 'pc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.pc }))
        break
      case 'acc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.acc }))
        break
      case 'ir':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ir }))
        break
      case 'mem':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.mem }))
        break
      case 'ma':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ma }))
        break
    }
    console.log('supposed to change')
  }

  return (
    <MainContainer>
      <Container>
        <Status variant="subtitle1">{getStatus()}</Status>
        {/* <Instruction>
          {currentLine ? currentLine.split("//")[0] : currentLine}
        </Instruction> */}
      </Container>
      <Container>
        {/* [{fund, name, data},{fund, name, data},{fund, name, data} ].map((row) => (
          <Box fund={row.fund} name="row.name" data={row.data} />
        )) */}

        <Stack direction="column" sx={{
          p: 1, m: 1,
          justifyContent: "center",
          width: "150px",
          background: "#97fcff",
          color: "black",
          height: "150px",
          borderRadius: "3px"
        }}>
          <Title variant="h3">IR</Title>
          <Value variant="h4">{currentStepVal && (currentStepVal as Steps).ir_status && (
            hexState.ir ?
              convertToHex((currentStepVal as Steps).ir_status) :
              (currentStepVal as Steps).ir_status)}
          </Value>
          <Button variant="contained" onClick={() => overHex('ir')} color={hexState.ir ? "primary" : "secondary"}>
            {hexState.ir ? "Hex" : "Dec"}
          </Button>
        </Stack>
        <Stack direction="column" sx={{
          p: 1, m: 1,
          justifyContent: "center",
          width: "150px",
          background: "#abbde5",
          color: "black",
          height: "150px",
          borderRadius: "3px"
        }}>

          <Title variant="h3">PC</Title>
          <Value variant="h4">{currentStepVal && (currentStepVal as Steps).pc_status && (
            hexState.pc ?
              convertToHex((currentStepVal as Steps).pc_status) :
              (currentStepVal as Steps).pc_status)}
          </Value>
          <Button variant="contained" onClick={() => overHex('pc')} color={hexState.pc ? "primary" : "secondary"}>
            {hexState.pc ? "Hex" : "Dec"}
          </Button>
        </Stack>
        {
          processor === 1 &&
          <Stack direction="column" sx={{
            p: 1, m: 1,
            justifyContent: "center",
            width: "150px",
            background: "#c2ff97",
            color: "black",
            height: "150px",
            borderRadius: "3px"
          }}>
            <Hex variant="contained" onClick={() => overHex('ma')} color={hexState.ma ? "primary" : "secondary"}>
              {hexState.ma ? "Hex" : "Dec"}
            </Hex>
            <Title variant="h3">MA</Title>
            <Value variant="h4">{currentStepVal && (currentStepVal as Steps).ma_status && (
              hexState.ma ?
                convertToHex((currentStepVal as Steps).ma_status!) :
                (currentStepVal as Steps).ma_status)}
            </Value>
          </Stack>
        }
        {processor !== 2 &&
          <Stack direction="column" sx={{
            p: 1, m: 1,
            justifyContent: "center",
            width: "150px",
            background: "#97ffc8",
            color: "black",
            height: "150px",
            borderRadius: "3px"
          }}>
            <Title variant="h3">ACC</Title>
            <Value variant="h4">{currentStepVal && (currentStepVal as Steps).acc_status && (
              hexState.acc ?
                convertToHex((currentStepVal as Steps).acc_status) :
                (currentStepVal as Steps).acc_status)}
            </Value>
            <Button variant="contained" onClick={() => overHex('acc')} color={hexState.acc ? "primary" : "secondary"}>
              {hexState.acc ? "Hex" : "Dec"}
            </Button>
          </Stack>
        }

      </Container>
    </MainContainer>
  )
}

export default ProcessorBox
