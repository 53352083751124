import React, { ChangeEvent, useEffect, useState } from 'react'
import { PaperStyled, InputForm, DropzoneContainer } from './Styled'
import { Button, CircularProgress, Typography } from '@mui/material'
import { useAuth } from '../../Api/Auth/use-auth'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { allUsersState, errorState, selectedUsersState } from '../../Recoil/GlobalState'
import UserList from '../../Components/UserList/UserList'
import XLSX from 'xlsx'
import { Severity, UserData } from '../../Api/Core/Interfaces'
import AddIcon from '@mui/icons-material/Add';
import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';
import AddUser from './../../Components/AddUser/AddUser';

interface Loading {
  resetting: boolean;
  addingNewUser: boolean;
  bulking: boolean;
  fetching: boolean;
}

const UserManager = () => {

  const auth = useAuth();
  const { t } = useTranslation();
  const [allUsers, setAllUsers] = useRecoilState(allUsersState)
  const setCurrentErrorState = useSetRecoilState(errorState)
  const [loadingState, setLoadingState] = useState({ resetting: false, bulking: false, addingNewUser: false, fetching: false } as Loading)
  const [showAddUser, setShowAddUser] = useState(false)
  // const [selected, setSelected] = useRecoilState(selectedUsersState)

  useEffect(() => {
    let disposed = false;
    (async () => {

      try {
        setLoadingState((prev: any) => ({ ...prev, fetching: true }))
        const data = await auth.fetchAll()

        if (disposed) return
        if (data) {
          setAllUsers(data);
        }
        setLoadingState((prev: any) => ({ ...prev, fetching: false }))

        setCurrentErrorState((prev) => ({
          ...prev,
          message: t('notification.fetch_success'),
          open: true,
          severity: Severity.Success,
        }))
      } catch (err: any) {
        setCurrentErrorState((prev) => ({
          ...prev,
          message: err.message,
          open: true,
          severity: Severity.Error,
        }))
        setLoadingState((prev: any) => ({ ...prev, fetching: false }))
      }

    })()

    return () => {
      disposed = true
    }
  }, [])

  const handleReset = async () => {
    try {
      setLoadingState((prev) => ({ ...prev, resetting: true }))
      await auth.reset();
      setCurrentErrorState((prev) => ({
        ...prev,
        message: t('notification.database_reset_successful'),
        open: true,
        severity: Severity.Success,
      }))

      setAllUsers([])

      setLoadingState((prev) => ({ ...prev, resetting: false }))
    } catch (err: any) {
      setLoadingState((prev) => ({ ...prev, resetting: false }))
      setCurrentErrorState((prev) => ({
        ...prev,
        message: err.message,
        open: true,
        severity: Severity.Error,
      }))
    }
  }

  // const handleAddCourses = async () => {
  //   try {
  //     setLoadingState((prev) => ({ ...prev, resetting: true }))
  //     await fetch(`http://localhost:8081/api/user/bulkAddCourse`, {
  //       method: 'POST',
  //       cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'x-auth-token': localStorage.getItem("token") || ''
  //       },
  //       body: JSON.stringify({ users: selected, courseId: "61822f5adb3db400572083a3" }),
  //       referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  //     });
  //     console.log('🚀 ~ handleAddCourses ~ selected', selected);
  //     setCurrentErrorState((prev) => ({
  //       ...prev,
  //       message: t('notification.database_reset_successful'),
  //       open: true,
  //       severity: Severity.Success,
  //     }))

  //     setLoadingState((prev) => ({ ...prev, resetting: false }))
  //   } catch (err: any) {
  //     setLoadingState((prev) => ({ ...prev, resetting: false }))
  //     setCurrentErrorState((prev) => ({
  //       ...prev,
  //       message: err.message,
  //       open: true,
  //       severity: Severity.Error,
  //     }))
  //   }
  // }

  const handleFile = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('handling file')
    if (event && event.target && event.target.files) {

      if (event.target.files[0].type !== "application/vnd.ms-excel") {
        setCurrentErrorState((prev) => ({
          ...prev,
          message: t('errors.wrong_file_format'),
          open: true,
          severity: Severity.Error,
        }))
        return
      }
      const reader = new FileReader()

      try {

        reader.onload = async (event) => {
          setLoadingState((prev) => ({ ...prev, bulking: true }))
          if (event && event.target && event.target.result) {

            const bstr = event.target.result
            const workBook = XLSX.read(bstr, { type: "binary" })
            const workSheetName = workBook.SheetNames[0]
            const workSheet = workBook.Sheets[workSheetName]

            const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
            const header: (keyof UserData)[] = fileData.shift() as (keyof UserData)[]
            const last: UserData[] = [];
            console.log('🚀 ~ reader.onload= ~ header', header);

            (fileData as string[][]).forEach((row: string[]) => {
              const newRowData = {} as any
              row.forEach((ele, index: number) => {
                newRowData[header[index]] = ele;
              })
              last.push(newRowData)
            })
            console.log('🚀 ~ last', last);

            try {

              await auth.bulkSignup(last);
              setAllUsers(last)
            } catch (err: any) {
              console.error(err.message)
              setLoadingState((prev) => ({ ...prev, bulking: false }))
              setCurrentErrorState((prev) => ({
                ...prev,
                message: err.message,
                open: true,
                severity: Severity.Error,
              }))
            }
            setLoadingState((prev) => ({ ...prev, bulking: false }))
          }

        }
      } catch (err: any) {
        setCurrentErrorState((prev) => ({
          ...prev,
          message: err.message,
          open: true,
          severity: Severity.Error,
        }))
        setLoadingState((prev) => ({ ...prev, bulking: false }))
      }


      reader.readAsBinaryString(event.target.files[0])
      if (event.target && event.target.value) {

        event.target.value = ''
      }
    }
  }

  return (
    <PaperStyled elevation={5}>
      <Typography>
        {t("admin_page.handle_users")}
      </Typography>
      <DropzoneContainer>
        <InputForm
          accept=".csv"
          id="contained-button-file"
          type="file"
          onChange={handleFile}
        />
        <label htmlFor="contained-button-file">
          <Button startIcon={
            loadingState.bulking ? (
              <CircularProgress size={24} />
            ) : (<CloudUploadSharpIcon />)
          } variant="contained" color="primary" component="span">
            Charger une liste
          </Button>
        </label>
        <Button
          startIcon={
            loadingState.addingNewUser ? (
              <CircularProgress size={24} />
            ) : (<AddIcon />)
          }
          variant="contained"
          color="primary"
          onClick={() => setShowAddUser(true)}
          component="span">
          Ajouter un éléve
        </Button>
        {/* <Button
            startIcon={
              loadingState.addingNewUser ? (
                <CircularProgress size={24} />
              ) : (<AddIcon />)
            }
            variant="contained"
            color="primary"
            onClick={() => handleAddCourses()}
            component="span">
            Ajouter un cours
          </Button> */}
        <Button onClick={handleReset}
          startIcon={loadingState.resetting ? (
            <CircularProgress size={24} />
          ) : (<ClearSharpIcon />)}
          variant="contained"
          color="secondary"
          component="span">
          Nettoyer la base de donnee
        </Button>
      </DropzoneContainer>
      {showAddUser ?
        <AddUser showAddUser={setShowAddUser} /> :
        <UserList data={allUsers} />
      }
    </PaperStyled>

  )
}

export default UserManager
