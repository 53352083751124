import React from 'react'
import { Container, Content } from './Styled'
// import { Button, CircularProgress, Typography } from '@mui/material'
// import { useAuth } from '../../Api/Auth/use-auth'
// import { useTranslation } from 'react-i18next'
// import { useRecoilState, useSetRecoilState } from 'recoil'
// import { allUsersState, errorState, selectedUsersState } from '../../Recoil/GlobalState'
// import UserList from '../../Components/UserList/UserList'
// import XLSX from 'xlsx'
// import { Severity, UserData } from '../../Api/Core/Interfaces'
// import AddIcon from '@mui/icons-material/Add';
// import CloudUploadSharpIcon from '@mui/icons-material/CloudUploadSharp';
// import ClearSharpIcon from '@mui/icons-material/ClearSharp';
// import AddUser from './../../Components/AddUser/AddUser';
// import UserManager from "../../Containers/UserManager/UserManager"
// import CourseManager from '../../Containers/CourseManager/CourseManager'
import AdminSidebar from '../../Components/AdminSidebar/AdminSidebar'
import { Outlet } from 'react-router'


const AdminPage = () => {
  return (
    <Container>
      <AdminSidebar />
      <Content>
        <Outlet/>
      </Content>
    </Container>
  )
}

export default AdminPage
