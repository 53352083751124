import React, { Fragment, useEffect, useState } from 'react'
import { Container, CourseTitle, PaperStyled, ProblemContainer, ProcessorImg, ProcessorTitle } from './Styled'

import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from '../../Api/Auth/testingAuth'
import { useTranslation } from 'react-i18next'
import { useRecoilState, useSetRecoilState } from 'recoil'
import { loadingPageState, userProfileState, userState } from '../../Recoil/GlobalState'
import axios from 'axios'
import { currentProblemState } from './../../Recoil/GlobalState';
import { getApiAddress } from './../../Api/Core/apiAddress';
import { CacheRequestType, CleanedData, Processor, UserProcessorData } from '../../Api/Core/Interfaces'
import WithoutMa from '../../Imgs/sans_MA_v3.svg';
import WithMa from '../../Imgs/avec_MA_v2.svg';
import Risc from '../../Imgs/RISC_simple.svg';
import { Link } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import Spinner from '../../Components/Spinner/Spinner'
import { getUserData } from '../../Api/Core/main'


interface ProcessorSVGProps {
  processorId: number
}

const ProcessorSVG = ({ processorId }: ProcessorSVGProps) => {
  return <Fragment>

    {/* {processorId === 0 && <ProcessorImg src={WithoutMa} />}
    {processorId === 2 && <ProcessorImg src={Risc} />}
    {processorId === 1 && <ProcessorImg src={WithMa} />} */}
  </Fragment>
}

const HomePage = () => {
  const { t } = useTranslation();
  const { userConnectionInfo, userInfo } = useAuth()
  const setLoadingState = useSetRecoilState(loadingPageState)
  const [courseInfos, setCourseInfos] = useState<UserProcessorData>({ courses: [], answers: [] })
  const { isFetching, isFetched } = useQuery({
    queryKey: ["user"],
    queryFn: async () => await getUserData(),
    onSuccess: (data) => {
      console.log("🚀 ~ file: HomePage.tsx:43 ~ HomePage ~ data:", data)
      setCourseInfos({ answers: data.answers, courses: data.courses })
    }
  })

  if (isFetching) {
    console.log("fetching for the homepage")
    setLoadingState(true)
    return null
  }

  if (isFetched) {
    setLoadingState(false)
  }


  // if (currentUserQuery.isLoading) return <Spinner />
  // const setCurrentProblem = useSetRecoilState(currentProblemState)

  // useEffect(() => {
  //   const source = axios.CancelToken.source();
  //   console.log('🚀 ~ useEffect ~ auth.user', auth.user);
  //   if (auth.user) {
  //     axios.get(`${getApiAddress()}api/user`, {
  //       cancelToken: source.token,
  //       headers: {
  //         "x-auth-token": localStorage.getItem('token') || ''
  //       }
  //     }).then((response) => {
  //       const data = response.data
  //       setUserInfo(response.data);
  //       setUserProfile(
  //         {
  //           matricule: data.matricule,
  //           firstname: data.firstname,
  //           lastname: data.lastname,
  //           email: data.email,
  //           role: data.role
  //         })
  //       // setInfo(response.data)
  //       console.log('🚀 ~ useEffect ~ response.data', response.data);
  //     }).catch((error) => {
  //       console.log('🚀 ~ axios.get ~ error', error);
  //       if (axios.isCancel(error)) return;
  //     });
  //   }

  //   return () => source.cancel();
  // }, [auth.user]);

  const getProblemAnswer = (problemId: string) => {
    let currentAnswer: { program: string[], data: CleanedData | undefined } = { program: [], data: undefined };
    for (const answer of courseInfos.answers) {
      if (problemId === answer.problem) {
        currentAnswer.program = answer.content
        if (answer.compiledResult) {
          currentAnswer.data = JSON.parse(answer.compiledResult)
        }
      }
    }
    return currentAnswer
  }

  const sortedProcessors = (processors: Processor[]) => {
    console.log('🚀 ~ sortedProcessors ~ processors', processors);
    const tempProc = [...processors]
    return tempProc.sort((first, second) => { return first.type - second.type })
  }


  return (
    <Container>
      {userConnectionInfo && courseInfos && courseInfos.courses && courseInfos.courses.map((course, index) =>
        <div key={index}>
          <CourseTitle variant="h3" color="primary">{course.name}</CourseTitle>
          {sortedProcessors(course.processors).map((processor, Pindex) => {
            return <PaperStyled key={Pindex}>
              <ProcessorSVG processorId={processor.type} />
              <div>
                <ProcessorTitle variant="h5">{t("processor." + processor.name)}</ProcessorTitle>
                <ProblemContainer>

                  {processor.problems.map((problem, probId) => {

                    const answer = getProblemAnswer(problem._id)
                    let pass = true
                    if (problem.isAdmin && !userInfo.isAdmin) {
                      pass = false
                    }
                    return pass ? <Link key={probId} component={RouterLink} to="/processors" state={{ problem, answer, type: processor.type }}>{t("processor." + problem.title)}</Link> : ''
                  })}

                </ProblemContainer>
              </div>
            </PaperStyled>
          })}
          <PaperStyled>

            {
              userInfo.isAdmin && <div>
                <ProcessorTitle variant="h5">Cache</ProcessorTitle>
                <Link component={RouterLink} to="/cache" state={{ cacheType: CacheRequestType.single }}>Single</Link> /
                <Link component={RouterLink} to="/cache" state={{ cacheType: CacheRequestType.batch }}> Batch</Link>
              </div>
            }
          </PaperStyled>
        </div>
      )}
    </Container>
  )
}

export default HomePage
