import axios from "axios";
import { createContext, useContext, useMemo, Dispatch, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { loadingPageState } from "../../Recoil/GlobalState";
import { getApiAddress } from "../Core/apiAddress";
import { ApiResponse, ApiResponseStatus, CheckConnectedApiResponse, Roles, SignInApiResponse, UserApiData, UserConnectionInfo, UserConnectionResponse, UserData } from "../Core/Interfaces";
import { useLocalStorage } from "./useLocalStorage";

const UserInfoData = {
  matricule: 1111111,
  firstname: '',
  lastConnection: new Date(),
  lastname: '',
  email: '',
  isAdmin: false,
  role: Roles.STUDENT
}

const AuthContext = createContext({
  userConnectionInfo: null as UserConnectionInfo | null | undefined,
  userInfo: UserInfoData,
  signIn: (matricule: number, password: string): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  signOut: () => { },
  setUser: (user: UserConnectionInfo) => { },
  // loaderDecorator: (func: any) => Promise<(...args: any) => Promise<void>>,
  isConnected: false,
  checkConnected: (): Promise<ApiResponse> => new Promise((res, rej) => ({ status: false })),
  addUser: (data: UserApiData): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),

  // signup,
  // reset: () => { },
  // removeUser,
  // bulkSignup: (users: UserData[]) => { },
  // changePassword: (matricule: number, newPassword: string): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  // removeUser: (data: number[]): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  // addUser: (data: UserData): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  // isAdmin: false,
  // fetchAll: (): Promise<UserData[]> => new Promise((res, rej) => []),
  // checkUserExists: (matricule: number): Promise<boolean> => new Promise((res, rej) => false),
  // sendPasswordResetEmail,
  // confirmPasswordReset,
});

interface Props {
  children: JSX.Element
}

export const AuthProvider = ({ children }: Props) => {
  const [userConnectionInfo, setUserConnectionInfo] = useState<UserConnectionInfo | null>(null);
  const [isConnected, setConnected] = useState(false);
  const [userInfo, setUserInfo] = useState<UserData>(UserInfoData)
  const setLoadingState = useSetRecoilState(loadingPageState)
  const navigate = useNavigate();

  console.log("auth provider")

  const setUser = (user: UserConnectionInfo) => {
    console.log("updating user")
    setUserConnectionInfo(user)
  }

  // call this function when you want to authenticate the user
  const signIn = async (matricule: number, password: string) => {
    const { data }: { data: SignInApiResponse } = await axios.post(`${getApiAddress()}api/auth`, JSON.stringify({ matricule, password }), {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // console.log('🚀 ~ signIn ~ res', res);
    if (!data.hasErrors) {
      localStorage.setItem('token', data.payload.token)
      setUserConnectionInfo((prev: any) => (
        {
          ...prev,
          matricule,
          token: data.payload.token
        }
      ))

      setUserInfo(prev => ({
        ...prev,
        ...data.payload.user,
        isAdmin: data.payload.user.role === Roles.ADMIN || data.payload.user.role === Roles.TEACHER
      }))

      setConnected(true)
    } else {
      setUserConnectionInfo(null)
    }
    return { hasErrors: data.hasErrors, errors: data.errors }
  }

  const addUser = async (userDataPayload: UserApiData): Promise<ApiResponse> => {
    try {
      const { data } = await axios.post(`${getApiAddress()}api/user/add`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token') || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(userDataPayload) // body data type must match "Content-Type" header
      });

      if (data.errors) {
        console.log('🚀 ~ addUser ~ res.errors', data.errors);
        return { hasErrors: true, errors: data.errors }
      }
      return { hasErrors: false }

    } catch (err: any) {
      console.error(err)
      throw new Error(err.message)
    }
  }

  const loaderDecorator = async (func: () => any) => {
    return await async function (...args: any) {
      setLoadingState(true)
      await func.call(args)
      setLoadingState(false)
    }
  }

  const checkConnected = async (): Promise<ApiResponse> => {
    if (userConnectionInfo) {
      console.log("user exists")
      setConnected(true)
      return { hasErrors: false }
    }

    console.log("check connected is called")

    try {
      const { data }: { data: CheckConnectedApiResponse } = await axios.get(`${getApiAddress()}api/auth`, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem("token") || ''
        }
      })

      console.log("🚀 ~ file: testingAuth.tsx:87 ~ const{data}=awaitaxios.get ~ data:", data)

      if (data.hasErrors) {
        setConnected(false)
        return { hasErrors: data.hasErrors, errors: data.errors }
      }

      if (!data.payload) {
        setConnected(false)
        throw new Error("no_payload")
      }

      setUserConnectionInfo({ matricule: data.payload.matricule, token: localStorage.getItem("token") || '' })
      setConnected(true)
      setUserInfo(prev => ({
        ...prev,
        ...data.payload,
        isAdmin: data.payload.role === Roles.ADMIN || data.payload.role === Roles.TEACHER
      }))
      return { hasErrors: false }

    } catch (err: any) {
      console.error('🚀 ~ checkConnected ~ err', err.message);
      setConnected(false)
      return { hasErrors: true, errors: err }
    }
  }


  // call this function to sign out logged in user
  const signOut = () => {
    setUserConnectionInfo(null);
    navigate("/", { replace: true });
  };

  const value = useMemo(() => ({
    userConnectionInfo,
    setUser,
    // loaderDecorator,
    userInfo,
    signIn,
    isConnected,
    signOut,
    checkConnected,
    addUser
  }), [userInfo, userConnectionInfo])
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  console.log("once")
  return useContext(AuthContext);
};