import React, { useEffect, useState } from 'react'
import { ConnectButton, Container, Form, Logo, PaperStyled } from './Styled'
import logo from '../../Imgs/code_machine_logo.png'
import {
  FilledInput,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material'

import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { errorState } from '../../Recoil/GlobalState'
import { Severity } from '../../Api/Core/Interfaces'
import ChangePassword from './../../Components/ChangePassword/ChangePassword';
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useAuth } from '../../Api/Auth/testingAuth'

const LoginPage = () => {
  const { t } = useTranslation()
  const setCurrentErrorState = useSetRecoilState(errorState)
  const [showUpdateUser, setShowUpdateUser] = useState(false)
  let navigate = useNavigate()
  let auth = useAuth()
  let location = useLocation()
  const [values, setValues] = useState({
    matricule: '',
    password: '',
    showPassword: false,
    hasErrors: false
  })

  const [errorMsgs, setErrors] = useState({
    matricule: '',
    password: '',
  })

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        // callMyFunction();
        handleConnect()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [values]);

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value })
  }


  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword })
  }

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const redirect = () => {
    console.log("connecting normally")
    let { from } = location.state || { from: { pathname: '/' } }
    console.log("🚀 ~ file: LoginPage.tsx:73 ~ redirect ~ from:", from)

    if (from.pathname === '/login') {
      navigate('/')
    } else {
      navigate('', { replace: from })
    }
  }

  const handleConnect = async () => {
    console.log('🚀 ~ setCurrentErrorState ~ severity');
    const { errors, hasErrors } = await auth.signIn(
      parseInt(values.matricule),
      values.password
    )
    console.log('🚀 ~ handleConnect ~ hasErrors', values);

    if (!hasErrors) {
      if (values.password === "password") {
        setShowUpdateUser(true)
      } else {
        // setCurrentErrorState((prev) => ({
        //   ...prev,
        //   open: true,
        //   message: "welcome, you're now connected",
        //   severity: Severity.Success,
        // }))
        setValues({
          matricule: '',
          password: '',
          showPassword: false,
          hasErrors: false
        })
        navigate('/')
      }
    } else if (errors) {
      console.log('🚀 ~ handleConnect ~ errors', errors);

      let tempError = {
        matricule: '',
        password: '',
      };
      for (const err of errors) {
        if (err.param) {
          tempError = { ...tempError, [err.param]: err.msg };
        }
      }
      setErrors(tempError)
      console.log('🚀 ~ handleConnect ~ tempError', tempError);

      setCurrentErrorState((prev) => ({
        ...prev,
        open: true,
        message: 'something when wrong',
        severity: Severity.Error,
      }))
    }
  }


  return (
    <Container>
      <PaperStyled>
        <Logo src={logo} alt="Logo" />
        {!showUpdateUser ?
          <Typography>{t('login.welcome_to')} Code Machine</Typography> :
          <Typography>{t('login.change_password')}</Typography>
        }
        {!showUpdateUser ?
          <Form>
            <TextField
              id="filled-error-helper-text"
              label="Matricule"
              error={!!errorMsgs.matricule}
              helperText={!!errorMsgs.matricule ? errorMsgs.matricule : ""}
              variant="filled"
              type="number"
              name="matricule"
              value={values.matricule}
              onChange={handleChange('matricule')}
            />
            <FormControl variant="filled">
              <InputLabel htmlFor="filled-adornment-password">
                {t('login.password')}
              </InputLabel>
              <FilledInput
                id="filled-adornment-password"
                type={values.showPassword ? 'text' : 'password'}
                value={values.password}
                error={!!errorMsgs.password}
                name="password"
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? (
                        <Visibility />
                      ) : (
                        <VisibilityOff />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            {values.hasErrors && (
              <Typography color="error">{t('errors.Invalid_Credentials')}</Typography>
            )}

            <ConnectButton
              onClick={handleConnect}
              variant="contained"
              color="primary"
            >
              {t('login.connect')}
            </ConnectButton>
          </Form> :
          <ChangePassword
            matricule={values.matricule}
            redirect={redirect}
          />
        }
      </PaperStyled>
    </Container>
  )
}

export default LoginPage
