import { Typography, Grid, FormControlLabel } from '@mui/material'
import React, { ChangeEvent } from 'react'
import { StyledFormControlLabel, StyledSwitch } from './Styled'

interface Props {
  checked: boolean;
  fn: ((name: string, value: boolean) => void);
  name: string;
  color: string;
  position: "top" | "end" | "start" | "bottom" | undefined;
  label: string;
}

const CustomSwitch = ({ checked, fn, name, color, position, label }: Props) => {

  const handleControlChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('🚀 ~ handleControlChange ~ event', event);
    fn(event.target.name, event.target.checked)
  }

  return (

    <StyledFormControlLabel
      value={name}
      control={<StyledSwitch size="small" checked={checked} onChange={handleControlChange} name={name} />}
      label={label}
      labelPlacement={position}
    />

  )
}

export default CustomSwitch
