// export const convertToHex = (value: string) => {
//   let sign = ""
//   if (value[0] === '-') {
//     // sign = "-"
//     value = value.substring(1)
//   }
//   const currentValue = parseInt(value).toString(16)
//   let prev = "0x"
//   const hex_length = currentValue.length < 5 ? 4 : 8;

//   for (let i = 0; i < hex_length - currentValue.length; i++) {
//     prev += "0"
//   }
//   return sign + prev + currentValue
// }

export const convertToHex = (value: string) => {
  let bn = BigInt(value);

  var pos = true;
  if (bn < 0) {
    pos = false;
    bn = bitnot(bn);
  }

  var hex = bn.toString(16);
  if (hex.length % 2) { hex = '0' + hex; }

  if (pos) {
    while (hex.length % 4) { hex = '0' + hex; }
  } else {
    while (hex.length % 4) { hex = 'f' + hex; }
  }
  hex = '0x' + hex;

  return hex;
}

function bitnot(value: BigInt) {
  let bn = -value;
  var bin = (bn).toString(2)
  var prefix = '';
  while (bin.length % 8) { bin = '0' + bin; }
  if ('1' === bin[0] && -1 !== bin.slice(1).indexOf('1')) {
    prefix = '11111111';
  }
  bin = bin.split('').map(function (i) {
    return '0' === i ? '1' : '0';
  }).join('');
  return BigInt('0b' + prefix + bin) + BigInt(1);
}