import "ace-builds/src-noconflict/mode-assembly_x86";

function CustomHighlightRules (keywords) {
    return class extends window.ace.require(
        "ace/mode/text_highlight_rules"
    ).TextHighlightRules {
        constructor() {
            super();

            this.$rules = {
                start: [
                    {
                        token: "comment",
                        regex: "#.*$"
                    },
                    {
                        token: 'keyword.control.assembly',
                        regex: keywords,
                        caseInsensitive: true
                    },
                    {
                        token: 'variable.parameter.register.assembly',
                        regex: '\\b(?:r([0-9]+))\\b',
                        caseInsensitive: true
                    },
                    {
                        token: 'constant.character.hexadecimal.assembly',
                        regex: '\\b0x[A-F0-9]+\\b',
                        caseInsensitive: true
                    },
                    {
                        token: 'constant.character.decimal.assembly',
                        regex: '\\b[0-9]+\\b'
                    },
                    {
                        token: 'constant.character.hexadecimal.assembly',
                        regex: '\\b[A-F0-9]+h\\b',
                        caseInsensitive: true
                    },
                    {
                        token: "string",
                        regex: '".*?"'
                    }
                ]
            };
        }
    }
}

export default class CustomAssemblyMode extends window.ace.require("ace/mode/assembly_x86")
    .Mode {
    constructor(keywords) {
        super();
        this.HighlightRules = CustomHighlightRules(keywords);
    }
}