import styled from "styled-components";
import { Button, Paper } from "@mui/material";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  margin-top: 50px;
  flex-direction: column;
  `
export const Form = styled.div`
  margin: .5em 1em;
  display: flex;
  flex-direction: column;

  > div{
    margin: .5em 0;
  }
`

export const Logo = styled.img`
  width: 150px;
  height: 150px;
`;
export const PaperStyled = styled(Paper)`
justify-content: center;
display: flex;
align-items: center;
flex-direction: column;
  background-color: #1f2227;
  padding: 1em;
`

export const ConnectButton = styled(Button)`
  margin: 1em 0;
  margin-right: 1em;
  width: 100%;
`

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const CancelButton = styled(Button)`
  margin: 1em 0;
  margin-left: 1em;
  background-color: #f23;
  width: 100%;
`
