import React, { Fragment, useState } from 'react'
// import { useAnimation } from 'framer-motion'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  currentLineState,
  currentStepValue,
} from '../../Recoil/GlobalState'
import { convertToHex } from '../../helper'
import { Steps } from '../../Api/Core/Interfaces'

interface Props {
  hex: boolean
}

const ProcessorWithMA = ({ hex }: Props) => {
  const [currentLine] = useRecoilState(currentLineState)
  const currentStepVal = useRecoilValue(currentStepValue)
  const [hexState, setHexState] = useState({ ma: false, pc: false, ir: true, acc: false })

  const primaryFontColor = '#000000'
  const hexColor = '#a7501e'
  const secondaryFontColor = '#fff'
  const registreColor = '#a5d9f1'
  const otherColors = '#f1f0a5'
  const titleBoxColor = '#a7501e'
  const titleBoxWeight = 'bold'
  const arrowColorOn = "#1279ee"
  // const registreMemColor = "#ff3"
  const dataMemColor = "#0c0"

  const lineColorOn = 'red'
  const circlesSize = "6"
  const regularFontFamily = "Arial"
  const lineColorOff = 'white'
  const strokeWidth = '3'
  const titleBoxSize = '14'
  const fontSize = '20'
  const numberFontFamily = 'consolas'
  const smallTextSize = '10'

  // useEffect(() => {
  //   if (stepList[currentStep] && stepList[currentStep].state_status === StateStatus.Fetch) {
  //     console.log('supposed to do something here')
  //     controls.start(() => ({
  //       stroke: "#f75e5e",
  //       pathLength: 1,
  //       transition: { duration: 10, ease: "easeOut" },
  //     }))
  //   }
  //   return controls.stop
  // }, [currentStep, stepList])

  const getStatus = () => {
    let status = ''
    console.log('🚀 ~ getStatus ~ (currentStepVal as Steps)', (currentStepVal as Steps))
    switch ((currentStepVal as Steps)?.state_status) {
      case 0:
        status = 'FETCH'
        break
      case 1:
        status = 'DECODE'
        break
      case 2:
        status = 'EXECUTE'
        break
    }
    return status
  }

  const overHex = (hexValue: any) => {
    switch (hexValue) {
      case 'ma':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ma }))
        break
      case 'pc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.pc }))
        break
      case 'acc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.acc }))
        break
      case 'ir':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ir }))
        break
    }
    console.log('supposed to change')
  }

  // const variants = {
  //   hidden: { opacity: 0, strokeWidth: 1, fill: 'red' },
  // }

  return (
    <Fragment>
      {(currentStepVal as Steps) && (currentStepVal as Steps).lines && (
        <svg
          data-name="Layer 1"
          viewBox="0 0 1380 500"
          style={{ width: '100%', height: 'auto' }}
        >
          <defs />
          <g
            id="line-27"
            data-name="cell- l80S GL dAPtlU-kHrk-36"
            stroke={
              (currentStepVal as Steps).lines.l27
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M731.5 400.5h-420v-109h89.26" fill="none" />
            <path d="M406.76 291.5l-8 4 2-4-2-4z" />
          </g>
          <path
            id="line-42"
            d="M1301.5 380.5v-220"
            stroke={
              (currentStepVal as Steps).lines.l42
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          />
          <path
            id="line-6"
            d="M642.53 242.56l69-1.06"
            fill="none"
            stroke={
              (currentStepVal as Steps).lines.l6 ? lineColorOn : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          />
          <g
            id="line-9"
            stroke={
              (currentStepVal as Steps).lines.l9 ? lineColorOn : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M711.5 241.5h119.12" fill="none" />
            <path d="M837.37 241.54l-9 4.5 2.26-4.5-2.25-4.5z" />
          </g>
          <g
            id="line-13"
            data-name="cell- 9MH3P SVimNwaclgp3E-50"
            stroke={
              (currentStepVal as Steps).lines.l13
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M991.5 269.5v-69l34.35-.1" fill="none" />
            <path d="M1033.35 200.37l-10 5 2.48-5-2.51-5z" />
          </g>

          <g id="alu-box">
            <g transform="translate(-75 98) scale(.83 .73)">
              <polygon points="1105.31 146.82 1105.31 223.55 1203.31 246.22 1203.31 169.48 1105.31 146.82" fill="#9a9b9b" />
              <polygon points="1105.31 312.98 1203.31 294.52 1203.31 224.08 1105.31 240.45 1105.31 312.98" fill="#9a9b9b" />
            </g>

            {/* <path
              d="M849.75 197.5l82.5 20v93l-82.5 20z"
              transform="translate(-8)"
              stroke="#6c8ebf"
              stroke-miterlimit="10"
              fill={otherColors}
            /> */}
            <g data-name="cell- 9MH3P SVimNwaclgp3E-14">
              <path fill="none" d="M841.5 202.75h40v20h-40z" />
              <text
                transform="translate(890 240)"
                fontSize={titleBoxSize}
                fill={titleBoxColor}
                fontWeight={titleBoxWeight}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                ALU
              </text>
            </g>
            {/* <g data-name="cell- 9MH3P SVimNwaclgp3E-20">
              <path fill="none" d="M868.5 258h40v20h-40z" />
              <text
                transform="translate(873.1 271)"
                fontSize="12"
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                <tspan letter-spacing="-.07em">V</tspan>
                <tspan x="7.11" y="0">
                  alue
                </tspan>
              </text>
            </g> */}
          </g>



          <g id="pc-box">
            <path
              fill={registreColor}
              stroke="#000"
              d="M119.27 130.5h130v60h-130z"
              data-name="cell- 9MH3P SVimNwaclgp3E-1"
            />
            <g data-name="cell- 9MH3P SVimNwaclgp3E-11">
              <path fill="none" d="M139.33 130.5h40v20h-40z" />
              <text
                transform="translate(123 145)"
                fontSize={titleBoxSize}
                fontWeight={titleBoxWeight}
                fill={titleBoxColor}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                PC
              </text>
            </g>


            <g data-name="cell- 9MH3P SVimNwaclgp3E-16">
              <path fill="none" d="M159.27 150.5h40v20h-40z" />
              <text
                transform="translate(185 170)"
                fontWeight="bold"
                textAnchor="middle"
                fontSize={fontSize}
                fontFamily={numberFontFamily}
                style={{ isolation: 'isolate' }}
              >
                {(currentStepVal as Steps) && hexState.pc ? convertToHex((currentStepVal as Steps).pc_status) : (currentStepVal as Steps).pc_status}
              </text>
            </g>
            <path
              d="M119.27 168l14.25 9.5-14.25 9.5z"
              fill="none"
              stroke="#000"
              stroke-miterlimit="10"
            />

            <g
              onClick={() => overHex('pc')}
              style={{ cursor: "pointer" }}
            >
              <rect
                x="220.33"
                y="134.5"
                width="24.77"
                height="16"
                rx="2.4"
                fill={hexState.pc ? hexColor : '#fff'}
                stroke={hexState.pc ? '#fff' : hexColor}
              />
              <text
                transform="translate(223.11 145)"
                fontSize="10"
                fill={hexState.pc ? 'snow' : hexColor}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                {hexState.pc ? "Hex" : "Dec"}
              </text>
            </g>

          </g>

          <g id="ma-box">
            <g data-name="cell- l80S GL dAPtlU-kHrk-3">
              <path
                fill={registreColor}
                stroke="#000"
                d="M1131.5 286h130v60h-130z"
              />
              <text
                transform="translate(1193 323)"
                fontWeight="bold"
                textAnchor="middle"
                fontSize={fontSize}
                fontFamily={numberFontFamily}
                style={{ isolation: 'isolate' }}
              >
                {
                  (currentStepVal as Steps) &&
                    (currentStepVal as Steps).ma_status &&
                    hexState.ma ?
                    convertToHex((currentStepVal as Steps).ma_status!) :
                    (currentStepVal as Steps).ma_status
                }
              </text>
            </g>
            <text
              transform="translate(1135 301)"
              fontSize={titleBoxSize}
              fill={titleBoxColor}
              fontFamily="ArialMT, Arial"
              fontWeight={titleBoxWeight}
              style={{ isolation: 'isolate' }}
              data-name="cell- l80S GL dAPtlU-kHrk-4"
            >
              MA
            </text>
            <path
              d="M1131.5 323.5l14.25 9.5-14.25 9.5z"
              fill="none"
              stroke="#000"
              stroke-miterlimit="10"
              data-name="cell- l80S GL dAPtlU-kHrk-5"
            />

            <g
              onClick={() => overHex('ma')}
              style={{ cursor: "pointer" }}
            >
              <rect
                x="1232.56"
                y="290"
                width="24.77"
                height="16"
                rx="2.4"
                fill={hexState.ma ? hexColor : '#fff'}
                stroke={hexState.ma ? '#fff' : hexColor}
              />
              <text
                transform="translate(1236 301)"
                fontSize="10"
                fill={hexState.ma ? 'snow' : hexColor}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                {hexState.ma ? "Hex" : "dec"}
              </text>
            </g>
          </g>


          <g id="memory-box">
            <path
              fill={dataMemColor}
              stroke="#000"
              d="M512.25 130.5h130v190h-130z"
              data-name="cell- 9MH3P SVimNwaclgp3E-3"
            />

            <g data-name="cell- 9MH3P SVimNwaclgp3E-12">
              <path fill="none" d="M522.25 130.5h40v20h-40z" />
              <text
                transform="translate(515 144)"
                fontSize={titleBoxSize}
                fontWeight={titleBoxWeight}
                fill={titleBoxColor}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                Memory
              </text>
            </g>

            <path
              d="M512.25 297.5l14.25 9.5-14.25 9.5z"
              fill="none"
              stroke="#000"
              stroke-miterlimit="10"
            />

            {/* <g data-name="cell- 9MH3P SVimNwaclgp3E-18">
              <path fill="none" d="M552.25 209h40v20h-40z" />
              <text
                transform="translate(557.1 222)"
                fontWeight="bold"
                textAnchor="middle"
                fontSize={fontSize}
                fontFamily={numberFontFamily}
                style={{ isolation: 'isolate' }}
              >
                {(currentStepVal as Steps) && hex ? convertToHex((currentStepVal as Steps).memory) : (currentStepVal as Steps).memory}
              </text>
            </g> */}
          </g>


          <g id="ir-box">
            <path
              fill={registreColor}
              stroke="#000"
              d="M119.27 290.5h130v60h-130z"
              data-name="cell- 9MH3P SVimNwaclgp3E-4"
            />
            <g data-name="cell- 9MH3P SVimNwaclgp3E-13">
              <path fill="none" d="M112.27 289.5h40v20h-40z" />
              <text
                transform="translate(122 305)"
                fontSize={titleBoxSize}
                fill={titleBoxColor}
                fontWeight={titleBoxWeight}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                IR
              </text>
            </g>

            <g data-name="cell- 9MH3P SVimNwaclgp3E-19">
              <path fill="none" d="M165.77 305.5h40v20h-40z" />
              <text
                transform="translate(183 329)"
                fontWeight="bold"
                textAnchor="middle"
                fontSize={fontSize}
                fontFamily={numberFontFamily}
                style={{ isolation: 'isolate' }}
              >
                {(currentStepVal as Steps) && hexState.ir ? convertToHex((currentStepVal as Steps).ir_status) : (currentStepVal as Steps).ir_status}
              </text>
            </g>
            <path
              d="M119.27 327l14.25 9.5-14.25 9.5z"
              fill="none"
              stroke={primaryFontColor}
              stroke-miterlimit="10"
            />

            <g
              onClick={() => overHex('ir')}
              style={{ cursor: "pointer" }}
            >
              <rect
                x="221.5"
                y="294"
                width="24.77"
                height="16"
                rx="2.4"
                fill={hexState.ir ? hexColor : '#fff'}
                stroke={hexState.ir ? '#fff' : hexColor}
              />
              <text
                transform="translate(225.11 305)"
                fontSize="10"
                fill={hexState.ir ? 'snow' : hexColor}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                {hexState.ir ? "Hex" : "Dec"}
              </text>
            </g>
          </g>

          <g id="acc-box">
            <path
              fill={otherColors}
              d="M1131.5 143h130v60h-130z"
              data-name="cell- 9MH3P SVimNwaclgp3E-6"
            />
            <g data-name="cell- 9MH3P SVimNwaclgp3E-15">
              <path fill="none" d="M1131.5 143h40v20h-40z" />
              <text
                transform="translate(1132 158)"
                fontSize={titleBoxSize}
                fontWeight={titleBoxWeight}
                fill={titleBoxColor}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                ACC
              </text>
            </g>

            <path
              d="M1131.5 179.5l14.25 9.5-14.25 9.5z"
              fill="none"
              stroke={primaryFontColor}
              stroke-miterlimit="10"
            />

            <g data-name="cell- 9MH3P SVimNwaclgp3E-21">
              <path fill="none" d="M1176.5 163h40v20h-40z" />
              <text
                transform="translate(1195 180)"
                fontWeight="bold"
                textAnchor="middle"
                fontSize={fontSize}
                fontFamily={numberFontFamily}
                style={{ isolation: 'isolate' }}
              >
                {(currentStepVal as Steps) && hexState.acc ? convertToHex((currentStepVal as Steps).acc_status) : (currentStepVal as Steps).acc_status}
              </text>
            </g>
            <g
              onClick={() => overHex('acc')}
              style={{ cursor: "pointer" }}
            >
              <rect
                x="1232.56"
                y="147"
                width="24.77"
                height="16"
                rx="2.4"
                fill={hexState.acc ? hexColor : '#fff'}
                stroke={hexState.acc ? '#fff' : hexColor}
              />
              <text
                transform="translate(1235.11 158)"
                fontSize="10"
                fill={hexState.acc ? 'snow' : hexColor}
                fontFamily="ArialMT, Arial"
                style={{ isolation: 'isolate' }}
              >
                {hexState.acc ? "Hex" : "dec"}
              </text>
            </g>
          </g>

          <g id="mux-box">
            <path d="M408 122.5l35 20v50l-35 20z" fill="#d5e8d4" stroke="#82b366" stroke-miterlimit="10" />

            <path
              d="M1038 268l35 20v50l-35 20z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              data-name="cell- l80S GL dAPtlU-kHrk-8"
            />


            <path
              d="M779 258l35 20v50l-35 20z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
            />

            <path
              d="M37.3 115.5l35 20v50l-35 20z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
            />
            <path
              d="M1038 128l35 20v50l-35 20z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              data-name="cell- 9MH3P SVimNwaclgp3E-10"
            />
            <path
              d="M409 224l35 20v50l-35 20z"
              fill="#fff"
              stroke="#000"
              stroke-miterlimit="10"
              data-name="cell- 9MH3P SVimNwaclgp3E-9"
            />
          </g>

          <g
            id="line-17"
            data-name="cell- 9MH3P SVimNwaclgp3E-53"
            stroke={
              (currentStepVal as Steps).lines.l17
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth="4"
          >
            <path d="M1073 173h46.53" fill="none" />
            <path d="M1127 173l-10 5 2.5-5-2.5-5z" fill="#7f00ff" />
          </g>
          <g
            id="line-2"
            data-name="cell- 9MH3P SVimNwaclgp3E-28"
            stroke={(currentStepVal as Steps).lines.l2 ? lineColorOn : lineColorOff}
            strokeWidth={strokeWidth}
          >
            <path
              d="M199.74 99h81.76v58.5"
              fill="none"
              stroke-miterlimit="10"
            />
          </g>
          <circle cx="281.5" cy="160.5" r={circlesSize} fill={secondaryFontColor} />

          <g id="+1box" data-name="cell- 9MH3P SVimNwaclgp3E-23">
            <path
              fill="#fff"
              stroke="#000"
              d="M169.74 84h30v30h-30z"
              data-name="cell- 9MH3P SVimNwaclgp3E-22"
            />
            <path fill="none" d="M170.74 86h28v26h-28z" />
            <text
              transform="translate(177.16 102)"
              fontSize="12"
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              +1
            </text>
          </g>


          <g data-name="cell- 9MH3P SVimNwaclgp3E-33">
            <path fill="none" d="M638.25 221.5h40v20h-40z" />
            <text
              transform="translate(651.33 235)"
              fontSize={smallTextSize}
              fill={secondaryFontColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              16
            </text>
            <path
              d="M653.25 250.5l20-20"
              fill="none"
              stroke={secondaryFontColor}
              stroke-miterlimit="10"
              data-name="cell- 9MH3P SVimNwaclgp3E-34"
            />
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-39">
            <path fill="none" d="M791.5 221.5h40v20h-40z" />
            <text
              transform="translate(804.33 235)"
              fontSize={smallTextSize}
              fill={secondaryFontColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              16
            </text>
            <path
              d="M806.5 250.5l20-20"
              fill="none"
              stroke={secondaryFontColor}
              stroke-miterlimit="10"
              data-name="cell- 9MH3P SVimNwaclgp3E-40"
            />
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-41">
            <rect
              x="1261.5"
              y="151"
              width="40"
              height="20"
              rx="3"
              fill="none"
            />
            <text
              transform="translate(1274.33 164)"
              fontSize={smallTextSize}
              fill={secondaryFontColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              16
            </text>
            <path
              d="M1271.5 183.5l20-20"
              fill="none"
              stroke={secondaryFontColor}
              stroke-miterlimit="10"
              data-name="cell- 9MH3P SVimNwaclgp3E-42"
            />
          </g>


          <g data-name="cell- 9MH3P SVimNwaclgp3E-51">
            <path fill="none" d="M965.5 221.5h40v20h-40z" />
            <text
              transform="translate(978.33 235)"
              fontSize={smallTextSize}
              fill={secondaryFontColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              16
            </text>
            <path
              d="M981.5 250.5l20-20"
              fill="none"
              stroke={secondaryFontColor}
              stroke-miterlimit="10"
              data-name="cell- 9MH3P SVimNwaclgp3E-52"
            />
          </g>



          <g
            id="line-37"
            data-name="cell- 9MH3P SVimNwaclgp3E-56"
            stroke={(currentStepVal as Steps).lines.l37 ? arrowColorOn : lineColorOff}
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M426.5 340.5v-28.26" fill="none" />
            <path d="M426.5 306.24l4 8-4-2-4 2z" />
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-57">
            <path fill="none" d="M448.63 313h40v20h-40z" />
            <text
              transform="translate(432 340)"
              fill={secondaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              sel_mem_addr
            </text>
          </g>

          <g
            id="line-38"
            data-name="cell- 9MH3P SVimNwaclgp3E-58"
            stroke={
              (currentStepVal as Steps).lines.l38
                ? arrowColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M577.5 360.5l-.2-31.76" fill="none" />
            <path d="M577.26 322.74l4 8-4-2-4 2z" />
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-59">
            <path fill="none" d="M581.5 330.5h40v20h-40z" />
            <text
              transform="translate(582 360)"
              fill={secondaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              wr_mem
            </text>
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-61">
            <path fill="none" d="M908.5 333h40v20h-40z" />
            <text
              transform="translate(909.98 346)"
              fill={secondaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              op_alu
            </text>
          </g>







          <g data-name="cell- 9MH3P SVimNwaclgp3E-63">
            <path fill="none" d="M1071.5 221.5h40v20h-40z" />
            <text
              transform="translate(1062 250)"
              fill={secondaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              sel_acc_data
            </text>
          </g>
          <g data-name="cell- 9MH3P SVimNwaclgp3E-64">
            <path fill="none" d="M512.25 165.5h40v20h-40z" />
            <text
              transform="translate(515.6 178)"
              fill={primaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              data_in
            </text>
          </g>
          <g data-name="cell- 9MH3P SVimNwaclgp3E-65">
            <path fill="none" d="M512.25 258h40v20h-40z" />
            <text
              transform="translate(516 271)"
              fill={primaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              addr
            </text>
          </g>


          <g data-name="cell- 9MH3P SVimNwaclgp3E-66">
            <path fill="none" d="M600.25 229h40v20h-40z" />
            <text
              transform="translate(600 245)"
              fill={primaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              data_out
            </text>
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-67">
            <path fill="none" d="M841.5 229h20v20h-20z" />
            <text
              transform="translate(847.67 245)"
              fill={primaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              letterSpacing="-.06em"
              style={{ isolation: 'isolate' }}
            >
              A
            </text>
          </g>

          <g data-name="cell- 9MH3P SVimNwaclgp3E-68">
            <path fill="none" d="M841.5 293h20v20h-20z" />
            <text
              transform="translate(847.67 306)"
              fill={primaryFontColor}
              fontSize={smallTextSize}
              fontFamily={regularFontFamily}
              style={{ isolation: 'isolate' }}
            >
              B
            </text>
          </g>





          <g id="state-box">
            <path fill="#fff" stroke="#000" d="M501.5.5h120v60h-120z" />
            <text
              transform="translate(560 36)"
              fontSize="18"
              textAnchor="middle"
              fontWeight="bold"
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              {getStatus()}
            </text>
          </g>


          <g
            id="line-22"
            stroke={
              (currentStepVal as Steps).lines.l22
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path d="M1301.5 170.5v-57h-940v90l41.13-.43" fill="none" stroke-miterlimit="10" />
            <path d="M406.88 203l-7 3.58 1.71-3.52-1.78-3.48z" fill={
              (currentStepVal as Steps).lines.l22
                ? lineColorOn
                : lineColorOff
            } stroke-miterlimit="10" />

          </g>

          <circle cx="1301.94" cy="172.98" fill={secondaryFontColor} r={circlesSize} />


          <g id="line-24"
            stroke={
              (currentStepVal as Steps).lines.l24
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}

            stroke-miterlimit="10">
            <path d="M772.63 325.5H751.5v55h550" fill="none" />
            <path
              d="M777.88 325.5l-7 3.5 1.75-3.5-1.75-3.5z"
              fill={
                (currentStepVal as Steps).lines.l24
                  ? lineColorOn
                  : lineColorOff
              }
            />
          </g>

          <g
            id="line-21"
            stroke={
              (currentStepVal as Steps).lines.l21
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path
              d="M1301.5 383.5v46.9"
              fill="none"
              stroke-miterlimit="10"
            />
            <path
              d="M1301.5 437.15l-4.5-9 4.5 2.25 4.5-2.25z"
              stroke-miterlimit="10"
            />
          </g>

          <circle cx="1301.5" cy="380.5" fill={secondaryFontColor} r={circlesSize} />


          <g id="instruction-box">
            <path
              fill="#fff"
              stroke="#000"
              d="M639.75.5h290v60h-290z"
            />
            <text
              transform="translate(770 34)"
              fontSize={fontSize}
              textAnchor="middle"
              fontWeight="bold"
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              {currentLine ? currentLine.split("//")[0] : currentLine}            </text>
          </g>
















          <g id="line-35"
            stroke={
              (currentStepVal as Steps).lines.l35
                ? arrowColorOn
                : lineColorOff
            }
            stroke-miterlimit="10" strokeWidth={strokeWidth}>
            <path d="M54.8 234v-28.4" fill="none" />
            <path
              d="M54.8 198.85l4.5 9-4.5-2.25-4.5 2.25z"
            />
          </g>


          <g
            id="line-40"
            data-name="cell- 9MH3P SVimNwaclgp3E-60"
            stroke={
              (currentStepVal as Steps).lines.l40
                ? arrowColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M906.5 348.5l.25-24.4" fill="none" />
            <path
              d="M906.83 317.35l4.4 9-4.48-2.3-4.52 2.21z"
            />
          </g>


          <g
            id="line-11"
            data-name="cell- 9MH3P SVimNwaclgp3E-62"
            stroke={
              (currentStepVal as Steps).lines.l11
                ? arrowColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M1056.5 250.5l.24-31.6" fill="none" />
            <path
              d="M1056.8 211.4l4.92 10-5-2.54-5 2.46z"
            />
          </g>



          <path fill="none" d="M71.5 221.5h40v20h-40z" />
          <text
            transform="translate(60 235)"
            fill={secondaryFontColor}
            fontSize={smallTextSize}
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
          >
            sel_pc_source
          </text>



          <g
            id="line-34"
            stroke={
              (currentStepVal as Steps).lines.l34
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth="2"
          >
            <path d="M443 167.5h61.88" fill="none" stroke-miterlimit="10" />
            <path d="M510.13 167.5l-7 3.5 1.75-3.5-1.75-3.5z" fill={
              (currentStepVal as Steps).lines.l34
                ? lineColorOn
                : lineColorOff
            } stroke-miterlimit="10" />
          </g>

          <path d="M443 269l62.53-.16" fill="none" stroke="#000" stroke-miterlimit="10" />
          <path d="M510.78 268.82l-7 3.52 1.74-3.5-1.76-3.5z" stroke="#000" stroke-miterlimit="10" />



          <g id="line-5" stroke={
            (currentStepVal as Steps).lines.l5
              ? lineColorOn
              : lineColorOff
          }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M444 269l60.66-.16" fill="none" />
            <path d="M510.66 268.83l-8 4 2-4-2-4z" fill="#9f3" />
          </g>


          {/* <g stroke="red" stroke-miterlimit="10">
            <path d="M376.5 230.5l-.24-15.13" fill="none" />
            <path
              d="M376.18 210.12l3.61 6.94-3.53-1.69-3.47 1.8z"
              fill="red"
            />
          </g> */}


          <path fill="none" d="M401.5 201.5h40v20h-40z" />
          <text
            transform="translate(430 225)"
            fill={secondaryFontColor}
            fontSize={smallTextSize}
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
          >
            sel_mem_data
          </text>

          <g id="line-26"
            stroke={
              (currentStepVal as Steps).lines.l26
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M814 303h17.4" fill="none" />
            <path
              d="M838.15 303l-9 4.5 2.25-4.5-2.25-4.5z"
            />
          </g>


          <g id="line-39"
            stroke={
              (currentStepVal as Steps).lines.l39
                ? arrowColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}>
            <path d="M796.5 370.5v-22.4" fill="none" />
            <path
              d="M796.5 341.35l4.5 9-4.5-2.25-4.5 2.25z"
              fill={
                (currentStepVal as Steps).lines.l39
                  ? arrowColorOn
                  : lineColorOff
              }
            />
          </g>


          <path fill="none" d="M816.5 341.5h40v20h-40z" />
          <text
            transform="translate(802 368)"
            fill={secondaryFontColor}
            fontSize={smallTextSize}
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
          >
            alu_b_source
          </text>




          <g
            id="line-8"
            data-name="cell- l80S GL dAPtlU-kHrk-1"
            stroke={
              (currentStepVal as Steps).lines.l8
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path
              d="M711.5 237.5v-87h230"
              fill="none"
              stroke-miterlimit="10"
            />
          </g>
          <circle cx="711.5" cy="240.5" r={circlesSize} fill={secondaryFontColor} />












          <g
            id="line-23"
            data-name="cell- l80S GL dAPtlU-kHrk-7"
            stroke={
              (currentStepVal as Steps).lines.l23
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <circle cx="1321.54" cy="316.98" r={circlesSize} fill={secondaryFontColor} />
            <path d="M1320.5 320.5v-240h-940v65l21.13-.38" fill="none" stroke-miterlimit="10" />
            <path d="M406.88 145l-6.88 3.65 1.68-3.53-1.81-3.47z" stroke-miterlimit="10" />

          </g>



          <g
            id="line-41"
            data-name="cell- l80S GL dAPtlU-kHrk-9"
            stroke={
              (currentStepVal as Steps).lines.l41
                ? arrowColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M1056.5 370.5V358" fill="none" />
            <path
              d="M1056.51 350.5l5 10-5-2.51-5 2.5z"
              fill={
                (currentStepVal as Steps).lines.l41
                  ? arrowColorOn
                  : lineColorOff
              }
            />
          </g>
          <text
            transform="translate(1064 370)"
            fontSize={smallTextSize}
            fill={secondaryFontColor}
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
            data-name="cell- l80S GL dAPtlU-kHrk-10"
          >
            sel_ma_source
          </text>

          <g
            id="line-14"
            data-name="cell- l80S GL dAPtlU-kHrk-12"
            stroke={
              (currentStepVal as Steps).lines.l14
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path
              d="M991.5 273.5v17h34.5"
              fill="none"
              stroke-miterlimit="10"
            />
            <path
              d="M1033.53 290.5l-10 5 2.5-5-2.5-5z"
              stroke-miterlimit="10"
            />
          </g>
          <circle cx="991.5" cy="270.5" r={circlesSize} fill="#fff" />



          <g
            id="line-15"
            data-name="cell- l80S GL dAPtlU-kHrk-13"
            stroke={
              (currentStepVal as Steps).lines.l15
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path
              d="M941.5 153.5v182h86.4"
              fill="none"
              stroke-miterlimit="10"
            />
            <path
              d="M1034.65 335.5l-9 4.5 2.25-4.5-2.25-4.5z"
              stroke-miterlimit="10"
            />
          </g>
          <circle cx="941.5" cy="150.5" r={circlesSize} fill="#fff" />



          <g
            id="line-25"
            data-name="cell- l80S GL dAPtlU-kHrk-17"
            stroke={
              (currentStepVal as Steps).lines.l25
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path
              d="M731.5 397.5v-117h39.26"
              fill="none"
              stroke-miterlimit="10"
            />
            <path
              d="M776.76 280.5l-8 4 2-4-2-4z"
              stroke-miterlimit="10"
            />
          </g>
          <circle cx="731.5" cy="400.5" r={circlesSize} fill="#fff" />

          <g id="line-7"
            stroke={
              (currentStepVal as Steps).lines.l7
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path d="M711.5 380.5h-630v-60H111" fill="none" />
            <path d="M117 320.5l-8 4 2-4-2-4z" />

            <path
              d="M711.5 240.5v141"
              fill="none"
              stroke-miterlimit="10"
              data-name="cell- l80S GL dAPtlU-kHrk-19"
            />
          </g>




          <g
            id="line-29"
            data-name="cell- l80S GL dAPtlU-kHrk-20"
            stroke={
              (currentStepVal as Steps).lines.l29
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path
              d="M278.5 270.5H1.5v-87l25.7-.36"
              fill="none"
              stroke-miterlimit="10"
            />
            <path
              d="M34 183.05l-9 4.62 2.19-4.53-2.32-4.47z"
              stroke-miterlimit="10"
            />
          </g>
          <circle cx="281.5" cy="270.5" r={circlesSize} fill="#fff" />


          <text
            transform="translate(289 440)"
            fontSize={smallTextSize}
            fill={secondaryFontColor}
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
            data-name="cell- l80S GL dAPtlU-kHrk-23"
          >
            To control...
          </text>



          <g
            id="line-28"
            data-name="cell- l80S GL dAPtlU-kHrk-24"
            stroke={
              (currentStepVal as Steps).lines.l28
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path d="M281.5 270.5l119.26-1.4" fill="none" />
            <path
              d="M406.76 269l-7.95 4.09 1.95-4-2-4z"
            />
          </g>


          <g
            id="line-31"
            data-name="cell- l80S GL dAPtlU-kHrk-25"
            stroke={(currentStepVal as Steps).lines.l31 ? lineColorOn : lineColorOff}
            strokeWidth={strokeWidth}
          >
            <path
              d="M281.5 270.5v47"
              fill="none"
              stroke-miterlimit="10"
            />
          </g>
          <circle cx="281.5" cy="320.5" r={circlesSize} fill="#fff" />



          <path
            d="M249.27 320.5h32.23"
            fill="none"
            id="line-32"
            stroke={(currentStepVal as Steps).lines.l32 ? lineColorOn : lineColorOff}
            strokeWidth={strokeWidth}
            data-name="cell- l80S GL dAPtlU-kHrk-26"
          />


          <g
            data-name="cell- l80S GL dAPtlU-kHrk-27"
            id="line-33"
            stroke={(currentStepVal as Steps).lines.l33 ? lineColorOn : lineColorOff}
            strokeWidth={strokeWidth}
          >
            <path d="M281.5 320.5v111.76" fill="none" />
            <path d="M281.5 438.26l-4-8 4 2 4-2z" />
          </g>

          <path
            d="M1261.5 173l40 .5"
            id="line-18"
            fill="none"
            stroke={
              (currentStepVal as Steps).lines.l18
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
            data-name="cell- l80S GL dAPtlU-kHrk-30"
          />

          <path
            id="line-3"
            d="M249.27 160.5h32.23"
            fill="none"
            stroke={
              (currentStepVal as Steps).lines.l3 ? lineColorOn : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
            data-name="cell- l80S GL dAPtlU-kHrk-31"
          />

          <g
            id="line-4"
            data-name="cell- l80S GL dAPtlU-kHrk-32"
            stroke={
              (currentStepVal as Steps).lines.l4
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path d="M281.5 160.5v86h117.4" fill="none" />
            <path
              d="M405.65 246.5l-9 4.5 2.25-4.5-2.25-4.5z"
            />
          </g>

          <g
            id="line-30"
            data-name="cell- l80S GL dAPtlU-kHrk-33"
            stroke={
              (currentStepVal as Steps).lines.l30
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path d="M72.3 160.5h36.87" fill="none" />
            <path
              d="M115.92 160.5l-9 4.5 2.25-4.5-2.25-4.5z"
            />
          </g>


          <g
            id="line-1"
            data-name="cell- l80S GL dAPtlU-kHrk-34"
            stroke={
              (currentStepVal as Steps).lines.l1
                ? lineColorOn
                : lineColorOff
            }
            strokeWidth={strokeWidth}
          >
            <path d="M170.74 99L1.5 99.5v39l25.7-.36" fill="none" />
            <path
              d="M34 138.05l-9 4.62 2.19-4.53-2.32-4.47z"
            />
          </g>

          <path
            d="M1321.5 320.5v80h-590"
            fill="none"
            id="line-20"
            stroke={
              (currentStepVal as Steps).lines.l20
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
            data-name="cell- l80S GL dAPtlU-kHrk-35"
          />

          <path
            id="line-19"
            d="M1261.5 316l60 .5"
            fill="none"
            stroke={
              (currentStepVal as Steps).lines.l19
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
            data-name="cell- l80S GL dAPtlU-kHrk-37"
          />

          <g
            id="line-10"
            data-name="cell- l80S GL dAPtlU-kHrk-38"
            stroke={
              (currentStepVal as Steps).lines.l10
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M941.5 150.5h86.4" fill="none" />
            <path
              d="M1034.65 150.5l-9 4.5 2.25-4.5-2.25-4.5z"
            />
          </g>



          <text
            transform="translate(1309 436)"
            fontSize={smallTextSize}
            fill={secondaryFontColor}
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
            data-name="cell- l80S GL dAPtlU-kHrk-39"
          >To control...
          </text>


          <g id="line-36" stroke={
            (currentStepVal as Steps).lines.l36
              ? arrowColorOn
              : lineColorOff
          }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M425.5 223.5l-.24-15.13" fill="none" stroke-miterlimit="10" />
            <path d="M425.18 203.12l3.61 6.94-3.53-1.69-3.47 1.8z" fill={
              (currentStepVal as Steps).lines.l36
                ? arrowColorOn
                : lineColorOff
            } stroke-miterlimit="10" />

          </g>


          <path
            id="line-12"
            d="M924.66 270.37l66.84.13"
            fill="none"
            stroke={
              (currentStepVal as Steps).lines.l12
                ? lineColorOn
                : lineColorOff
            }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          />

          <g id="line-16" stroke={
            (currentStepVal as Steps).lines.l16
              ? lineColorOn
              : lineColorOff
          }
            stroke-miterlimit="10"
            strokeWidth={strokeWidth}
          >
            <path d="M1073 312.55l46.56-.17" fill="none" />
            <path
              d="M1127 312.36l-10 5 2.48-5-2.52-5z"
            />
          </g>


          <path
            fill="#fff"
            stroke="#000"
            d="M487.5 450.5h639v40h-639z"
          />

          <g>
            <text
              transform="translate(731.63 477)"
              fontSize="24"
              fill={fontSize}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              Control Signal
            </text>
          </g>

          <g>
            <text
              transform="translate(1042 295)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              0
            </text>
            <text
              transform="translate(1042 155)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              0
            </text>
            <text
              transform="translate(1042 341)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              1
            </text>
            <text
              transform="translate(1042 205)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              1
            </text>

            <text
              transform="translate(782 286)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              0
            </text>
            <text
              transform="translate(782 330)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              1
            </text>

            <text
              transform="translate(413 250)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              0
            </text>
            <text
              transform="translate(413 272)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              1
            </text>
            <text
              transform="translate(413 296)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              2
            </text>

            <text
              transform="translate(411 150)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              0
            </text>
            <text
              transform="translate(410 206)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              1
            </text>

            <text
              transform="translate(41 145)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              0
            </text>
            <text
              transform="translate(41 188)"
              fontSize={smallTextSize}
              fill="#000"
              fontFamily="ArialMT, Arial"
            >
              1
            </text>

          </g>

        </svg>
      )
      }
    </Fragment >
  )
}

export default ProcessorWithMA
