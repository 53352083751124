import './i18next'
import React, { Suspense } from "react";
import ReactDOM from 'react-dom/client';
import './index.css'
import reportWebVitals from './reportWebVitals'
import theme from './theme'
import {
  RecoilRoot,
} from 'recoil'
import Spinner from './Components/Spinner/Spinner';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { router } from './Routes';
import { ProvideAuth } from './Api/Auth/use-auth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AuthProvider } from './Api/Auth/testingAuth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <RecoilRoot>
        <RouterProvider router={router} />
      </RecoilRoot>
    </ThemeProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
