import styled from 'styled-components'
import { TableContainer, TableCell } from '@mui/material'

export const Container = styled.div`
    /* min-width: 200px; */
    margin: .5em;
`

export const ControlContainer = styled.div`
    width: 200px;
`

export const MemoryBoxContainer = styled.div`
    flex-wrap:wrap;
    display: flex;
    overflow-y: scroll;
    height: 400px;
`

export const StyledTable = styled.div`
    height: 200px;
`

interface CellProps {
  used?: boolean;
  theme?: object;
}


interface AddressProp {
  addressColor: string
}
