import React, { useState } from 'react'
import { InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import { FormControlStyled, FormControlContainer, PaperStyled } from './Styled'
import { QuestionType } from '../../Api/Core/Interfaces'

const AddExercice = () => {

  const [processor, changeProcessor] = useState(0)
  const [currentType, changeCurrentType] = useState(QuestionType.QCM)
  // const handleChange = () => {
  //   console.log('changing')
  // }
  const handleChangeProcessor = (event: SelectChangeEvent<number>) => {
    changeProcessor(event.target.value as number)
  }
  // const handleChangeType = (event: SelectChangeEvent<number>) => {
  //   changeCurrentType(event.target.value as QuestionType)
  // }
  return (
    <PaperStyled>
      <Typography variant="h5">add exercice</Typography>
      <FormControlContainer>
        <FormControlStyled variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Processors</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={processor}
            onChange={handleChangeProcessor}
            label="Processor"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value={0}>Processeur à accumulateur</MenuItem>
            <MenuItem value={1}>Processeur à accumulateur avec registre MA</MenuItem>
            <MenuItem value={2}>Processeur PolyRisc</MenuItem>
          </Select>
        </FormControlStyled>
        {/* <FormControlStyled variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={currentType as string}
            onChange={handleChangeType}
            label="exercice-type"
          >
            <MenuItem value={QuestionType.QCM}>{QuestionType.QCM}</MenuItem>
            <MenuItem value={QuestionType.Q_ONLY}>{QuestionType.Q_ONLY}</MenuItem>
            <MenuItem value={QuestionType.DYNAMIC}>{QuestionType.DYNAMIC}</MenuItem>
          </Select>
        </FormControlStyled> */}
      </FormControlContainer>
    </PaperStyled>
  )
}

export default AddExercice
