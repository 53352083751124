import styled from 'styled-components'
import { Button, IconButton } from '@mui/material'

export const Container = styled.div`
    position: relative;
`

export const SettingsIconButton = styled(IconButton)`
    position: absolute !important;
    right: 10px;
    top: 0;
`

export const ArrowIconButton = styled(Button)`
    position: absolute !important;
    right: 45px;
    width: 30px;
    min-width: 30px;
    height: 25px;
    top: 14px;
    font-weight: bold;
`
