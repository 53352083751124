import { atom, selector } from "recoil";
import {
  Animations,
  Answer,
  CacheStep,
  EditorState,
  LoadingStatus,
  MemoryTab,
  ModalStatus,
  PlayingMode,
  Problem,
  ProcessorSettings,
  Roles,
  Severity,
  Steps,
  User,
  UserData
} from "../Api/Core/Interfaces";
import { PlayingStatus } from "../Api/Core/Interfaces";
// import { compile } from './../Api/Core/Processors';

export const stepListState = atom({
  key: 'stepList',
  default: [] as Steps[] | CacheStep[],
});

export const loadingPageState = atom({
  key: 'loadingPage',
  default: false
})

export const stepCacheHistory = atom({
  key: 'stepCacheHistory',
  default: [] as CacheStep[]
})

export const errorState = atom({
  key: 'errorState',
  default: { open: false, severity: Severity.Error, message: '' }
})

export const defaultPlayingModeState = atom({
  key: 'defaultPlayingMode',
  default: PlayingMode.Regular
})

export const currentProblemState = atom({
  key: 'currentProblemState',
  default: {} as Problem
})

export const allUsersState = atom({
  key: 'allUsers',
  default: [] as UserData[]
})

export const userProfileState = atom({
  key: 'userProfile',
  default: {
    matricule: 1111111,
    firstname: '',
    courses: [],
    answers: [],
    lastConnection: new Date(),
    lastname: '',
    email: '',
    isAdmin: false,
    role: Roles.STUDENT
  } as UserData
})

export const userState = atom({
  key: 'userState',
  default: {} as User
})

export const processorSettingsState = atom({
  key: 'processorSettings',
  default: {
    primaryFontColor: "#000000",
    secondaryFontColor: "#fff",
    registreColor: "#a5d9f1",
    otherColors: "#f1f0a5",
    titleBoxColor: "#7e0fb1",
    lineColorOn: "red",
    lineColorOff: "white",
    arrowColorOn: "#1279ee",
    strokeWidth: "3",
    titleBoxSize: "14",
    fontSize: "20",
    numberFontFamily: "consolas",
    regularFontFamily: "consolas",
    smallTextSize: "10",
    circlesSize: "6"
  } as ProcessorSettings
})

// export const stepListQuery = selector({
//   key: 'stepListQuery',
//   get: async ({ get }) => {
//     console.log("recasting this guy")
//     const response = await compile(4444, get(hexaEditorState).content.split('\n'))
//     if (response)
//       return response
//     else
//       return { steps: [], hex: [] }
//   }
// })

export const currentStepValue = selector({
  key: 'currentStepValue',
  get: ({ get }) => {
    let currentStep = {} as Steps | CacheStep
    if (!get(stepListState)) return
    if (get(currentStepState) === -1) {
      currentStep = get(stepListState)[0]
    } else {
      currentStep = get(stepListState)[get(currentStepState)]
    }

    console.log('🚀 ~ currentStep', currentStep);
    return currentStep
  }
});

export const loadingState = atom({
  key: 'loadingList',
  default: {
    compiling: false,
    executing: false,
    firstLoad: false,
    answersLoad: false
  } as LoadingStatus,
});

export const selectedUsersState = atom({
  key: 'selectedUsers',
  default: [] as number[],
});

export const executionState = atom({
  key: 'executionStatus',
  default: false,
});

export const stepsValue = selector({
  key: "stepsValue",
  get: ({ get }) => {
    if (!get(stepListState)) return {
      total: 0,
    }
    return {
      total: get(stepListState).length,
    }
  },
});

export const currentStimulatedMemoryValue = selector({
  key: "currentStimulatedMemory",
  get: ({ get }): number => {
    const currentStepVal = get(currentStepValue)
    if (!currentStepVal) {
      return 0;
    } else {
      return (currentStepVal as Steps).stimulated_memory_address
    }
  },
});


export const answersState = atom({
  key: 'answersState',
  default: [] as Answer[],
});

export const modalState = atom({
  key: 'modalStatus',
  default: { showAnswers: false, showQuestion: false } as ModalStatus,
});

export const currentStepState = atom({
  key: 'currentStep',
  default: -1,
});

export const currentLineState = atom({
  key: 'currentLine',
  default: '',
});

export const MemoryTabState = atom({
  key: 'memoryTab',
  default: MemoryTab.memory,
});

export const defaultText = `
.text
.data
`;

export const hexaEditorState = atom({
  key: 'hexaEditor',
  default: {
    width: '250',
    mode: 'java',
    fontSize: 16,
    selector: "hexa",
    theme: 'monokai',
    content: '',
    name: 'hexa-editor',
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    showLineNumbers: true,
    newLoadedData: false,
    tabSize: 4
  } as EditorState,
});

export const assemblerEditorState = atom({
  key: 'assemblerEditor',
  default: {
    width: '100%',
    content: defaultText,
    newLoadedData: false,
    selector: "assembler",
    mode: 'assembly_x86',
    fontSize: 16,
    theme: 'monokai',
    name: 'assembler-editor',
    enableBasicAutocompletion: true,
    enableLiveAutocompletion: true,
    showLineNumbers: true,
    tabSize: 4
  } as EditorState,
});

export const assemblerEditorContentValue = selector({
  key: "assemblerEditorContent",
  get: ({ get }): string => {
    return get(assemblerEditorState).content
  },
});

// export const playingState = atom({
//   key: 'playingState',
//   default: PlayingStatus.Stopped,
// });

export const playingState = atom({
  key: 'playingState',
  default: {
    playingState: PlayingStatus.Stopped,
    timeoutPeriod: 2000 // 2 seconds
  },
});

export const AnimationState = atom({
  key: 'animationState',
  default: { decode: false, write: false, read: false, fetch: false } as Animations
});