import React, { useEffect, useRef, useState } from 'react'
import {  IconButton, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import '../../App.css'
import { StyledCircularProgress, FormControlStyled, IconContainer, MediaStyled, SliderContainer, SliderStyled } from './Styled'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import FastRewindIcon from '@mui/icons-material/FastRewind'
import FastForwardIcon from '@mui/icons-material/FastForward'
import PauseIcon from '@mui/icons-material/Pause'
// import { useRecoilState, useRecoilValue } from 'recoil';
import { currentStepState, playingState, loadingState, defaultPlayingModeState } from '../../Recoil/GlobalState';
import { stepsValue } from '../../Recoil/GlobalState'
import {  PlayingMode, PlayingStatus } from './../../Api/Core/Interfaces';
// import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next'
import { useRecoilValue, useRecoilState } from 'recoil'
// import { useTranslation } from 'react-i18next'


function valuetext(value: Number) {
  return `${value} cycle`
}

let timer: NodeJS.Timeout;

const Media = () => {
  const { t } = useTranslation()
  const totalSteps = useRecoilValue(stepsValue)
  const [currentPlayingMode, setPlayingMode] = useRecoilState(defaultPlayingModeState);
  const [currentStep, setCurrentStep] = useRecoilState(currentStepState);
  const [currentPlayingState, changeCurrentPlayingState] = useRecoilState(playingState);
  const [loadingValues] = useRecoilState(loadingState);
  const [startingPoint, setStartingPoint] = useState(0)
  const [stepLength, setStepLength] = useState(0)

  useEffect(() => {
    if (currentPlayingMode === PlayingMode.Execute) {
      setStepLength(3)
      setStartingPoint(2)
      setCurrentStep(2)
    } else {
      setStepLength(1)
      setStartingPoint(0)
      setCurrentStep(-1)
    }
  }, [currentPlayingMode])

  const stepRef = useRef(currentStep);
  stepRef.current = currentStep;

  function handleSliderChange(event: Event, value: number | number[]) {
    setCurrentStep(value as number)
  }

  const [marks, setMarks] = useState([{
    value: 0,
    label: '0',
  }])

  const handlePlayingState = async (buttonClicked: string) => {

    switch (buttonClicked) {
      case "fast_rewind":
        setCurrentStep(startingPoint)
        break;
      case "previous":
        console.log("previous", currentStep)
        if (currentStep < startingPoint + 1) {
          setCurrentStep(startingPoint)
        } else {
          setCurrentStep((prev) => prev - stepLength)
        }
        break;
      case "next":
        console.log("next", currentStep)
        if (currentStep < totalSteps.total - stepLength) {
          setCurrentStep((prev) => prev + stepLength)
        } else {
          setCurrentStep(totalSteps.total - stepLength)
        }
        break;
      case "play":
        await play();
        changeCurrentPlayingState((oldState) => ({ ...oldState, playingState: PlayingStatus.Playing }))
        break;
      case "pause":
        changeCurrentPlayingState((oldState) => ({ ...oldState, playingState: PlayingStatus.Paused }))
        pause();
        break;
      case "stop":
        changeCurrentPlayingState((oldState) => ({ ...oldState, playingState: PlayingStatus.Stopped }))
        stop();
        break;
      case "fast_forward":
        console.log('moving something')
        console.log(totalSteps.total - 1)
        setCurrentStep(totalSteps.total - 1)
        break;
    }
  }

  async function play() {
    // should check if we need to re-execute
    // await fetchData()
    clearInterval(timer)
    timer = setInterval(stepUp, currentPlayingState.timeoutPeriod)
  }

  const stepUp = () => {
    if (stepRef.current < totalSteps.total - 1) {
      console.log('🚀 ~ stepUp ~ currentStep', stepRef.current);
      setCurrentStep((oldStep) => oldStep + 1)
    } else {
      stop()
    }
  }

  const stop = () => {
    changeCurrentPlayingState((oldState) => ({ ...oldState, playingState: PlayingStatus.Stopped }))
    setCurrentStep(0)
    clearInterval(timer)
  }

  const pause = () => {
    console.log('supposed to stop here')
    changeCurrentPlayingState((oldState) => ({ ...oldState, playingState: PlayingStatus.Paused }))
    clearInterval(timer)
  }

  useEffect(() => {
    setMarks([
      {
        value: 0,
        label: '0',
      },
      {
        value: totalSteps.total - 1,
        label: `${totalSteps.total - 1}`,
      },
    ])
  }, [totalSteps])

  const handleChange = (event: SelectChangeEvent<PlayingMode>) => {
    setPlayingMode(event.target.value as PlayingMode);
  };

  return (
    <MediaStyled className="media">
      <FormControlStyled variant="filled">
        <InputLabel id="demo-simple-select-filled-label">Mode</InputLabel>
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={currentPlayingMode}
          onChange={handleChange}
        >
          <MenuItem value={PlayingMode.Regular}>{t("media.regular")}</MenuItem>
          {/* <MenuItem value={PlayingMode.Memory}>{t("media.memory")}</MenuItem> */}
          <MenuItem value={PlayingMode.Execute}>{t("media.execute")}</MenuItem>
        </Select>
      </FormControlStyled>
      <IconContainer>
        <IconButton
          size="medium"
          edge="end"
          aria-label="account of current user"
          onClick={() => handlePlayingState('fast_rewind')}
          aria-haspopup="true"
          color="primary"
        >
          <FastRewindIcon fontSize="large" />
        </IconButton>
        <IconButton
          size="medium"
          edge="end"
          aria-label="account of current user"
          onClick={() => handlePlayingState('previous')}
          aria-haspopup="true"
          color="primary"
        >
          <SkipPreviousIcon fontSize="large" />
        </IconButton>
        {currentPlayingState.playingState === PlayingStatus.Playing ?


          <IconButton
            size="medium"
            edge="end"
            aria-label="account of current user"
            onClick={() => handlePlayingState('pause')}
            aria-haspopup="true"
            color="primary"
          >
            <PauseIcon fontSize="large" />
          </IconButton>
          :
          (loadingValues.executing ?
            <StyledCircularProgress size={20} /> :
            <IconButton
              size="medium"
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={() => handlePlayingState('play')}
              color={"primary"}
            >
              <PlayArrowIcon fontSize="large" />
            </IconButton>
          )

        }
        <IconButton
          size="medium"
          edge="end"
          aria-label="account of current user"
          onClick={() => handlePlayingState('next')}
          aria-haspopup="true"
          color="primary"
        >
          <SkipNextIcon fontSize="large" />
        </IconButton>
        <IconButton
          size="medium"
          edge="end"
          aria-label="account of current user"
          onClick={() => handlePlayingState('fast_forward')}
          aria-haspopup="true"
          color="primary"
        >
          <FastForwardIcon fontSize="large" />
        </IconButton>
      </IconContainer>
      <SliderContainer>

        <SliderStyled
          defaultValue={0}
          getAriaValueText={valuetext}
          aria-labelledby="discrete-slider-custom"
          step={1}
          max={totalSteps.total - 1}
          valueLabelDisplay="on"
          marks={marks}
          value={currentStep}
          onChange={handleSliderChange}
        />
      </SliderContainer>
    </MediaStyled>
  )
}

export default Media
