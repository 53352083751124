import styled from "styled-components";
import { Paper } from "@mui/material";


export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 65px 1em;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  `
export const DropzoneContainer = styled.div`
  width: 100%;
  display: flex;
  height: 33px;
  && span{
    margin-right: 10px;
  }
  margin: 1em 0;
`
export const InputForm = styled.input`
  display: none
`

export const PaperStyled = styled(Paper)`
  display: flex;
  flex-direction: column;
  background-color: #1f2227;
  padding: 1em;
`

