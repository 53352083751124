// import React from 'react'

// const Memories = (props: { reg?: boolean, name: string, addressColor: string, values: string[], size: number, hex: boolean }) => {
//   return (
//     <div>Memories</div>
//   )
// }

// export default Memories

import React, { useState, Fragment } from 'react'
import {
  TableContainer, Paper,
  Table, TableBody, TableRow, TableCell, TableHead,
  NativeSelect, FormControl, Typography,
  FormControlLabel, Switch, InputLabel, Select, Stack, SelectChangeEvent
} from '@mui/material'
import { Container, ControlContainer } from './Styled'
import CustomSwitch from '../CustomSwitch/CustomSwitch'
import { useTranslation } from 'react-i18next';
import { convertToHex } from '../../helper';
import { useRecoilValue } from 'recoil';
import { currentStimulatedMemoryValue } from '../../Recoil/GlobalState';
import CustomSelect from '../CustomSelect/CustomSelect';

interface Props {
  reg?: boolean;
  startingAddress: string;
  name?: string;
  addressColor: string;
  values: string[];
  size: number;
  hex: boolean;
}

const Memories = (props: Props) => {
  const [hexs, setHexs] = useState({ "addressHex": false, "memoryHex": false })
  const [size, setSize] = useState(4);
  const currentStimulatedMemory = useRecoilValue(currentStimulatedMemoryValue)
  const { t } = useTranslation();

  const data = () => {
    const newArray: { "address": number, data: { ele: string, index: number }[] }[] = []
    let memPosLine = 0;
    let address = parseInt(props.startingAddress, 16);
    console.log("🚀 ~ file: Memories.tsx:46 ~ data ~ address:", address)
    let lineData: { ele: string, index: number }[] = [];
    // console.log('🚀 ~ data ~ props.values', props.values);
    props.values.forEach((ele, index) => {
      memPosLine += 1;
      address += 1;
      lineData.push({ ele, index })

      if (memPosLine > size - 1) {
        newArray.push({ "address": address - size, "data": lineData })
        memPosLine = 0;
        lineData = [];
      }
    })

    return newArray;
  }

  const handleChangeChecked = (name: string, value: boolean) => {
    setHexs((prev) => ({ ...prev, [name]: value }));
  }

  const handleControlChange = (key?: string | number) => (event: SelectChangeEvent<string>) => {
    setSize(parseInt(event.target.value));
    console.log('🚀 ~ handleControlChange ~ event.target.value', event.target.value);
  }

  const getHeader = () => {
    let pos = []
    for (let i = 0; i < size; i++) {
      pos.push(i)
    }
    return pos
  }

  const sxCellHeader = { "background": props.addressColor, "color": "black", "fontFamily": "consolas" }
  const sxCell = { "fontFamily": "consolas" }

  return (
    <Container>
      {props.name && <Typography variant="h5" color="primary">{props.name}</Typography>}
      <Stack direction="row" maxWidth={400}>
        {/* <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Row Size</InputLabel>
          <Select
            onChange={handleControlChange}
            name="size"
            inputProps={{ 'aria-label': 'size' }}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={4}>4</option>
            <option value={8}>8</option>
          </Select>
        </FormControl> */}

        <CustomSelect
          backgroundColor='#222'
          data={['1', '2', '3', '4', '8']}
          handleChange={handleControlChange}
          inputValue={size}
          selectName="Row Size"
        />

        <CustomSwitch checked={hexs.addressHex} name="addressHex" fn={handleChangeChecked} color="red" position="top" label={t("processor.address")} />
        <CustomSwitch checked={hexs.memoryHex} name="memoryHex" fn={handleChangeChecked} color="red" position="top" label={t("processor.values")} />

      </Stack>
      <TableContainer component={Paper} sx={{ maxHeight: "350px", overflow: "hidden", overflowY: "scroll" }}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={sxCellHeader}></TableCell >
              {
                getHeader().map((value: number, index: number) => (

                  <TableCell key={index} sx={sxCellHeader} align="right">
                    {hexs.addressHex ? value : convertToHex(value.toString())}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {data().map((row: any) => (
              <TableRow key={row.address}>
                <TableCell sx={sxCellHeader} component="th" scope="row">
                  {props.reg ? "R" + row.address :
                    hexs.addressHex ? row.address : convertToHex(row.address)
                  }
                </TableCell>
                {
                  row.data.map((value: { ele: string, index: number }, index: number) =>
                    <Fragment key={index}>
                      <TableCell
                        sx={[
                          sxCell,
                          (theme) => ({
                            '&&.MuiTableCell-root': {
                              bgcolor: currentStimulatedMemory === value.index ? theme?.palette.secondary.main : "none"
                            }
                          })
                        ]}

                        align="right">
                        {hexs.memoryHex ? value.ele : convertToHex(value.ele)}
                      </TableCell>
                    </Fragment>)
                }
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container >
  )
}

export default Memories
