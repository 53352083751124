import React from 'react'
import { FormControl, InputLabel, Select, MenuItem, SelectChangeEvent } from '@mui/material'
// import gray from '@mui/material/colors/gray'


interface Props {
    handleChange: (key?: string | number) => (event: SelectChangeEvent) => void,
    inputValue: string | number,
    backgroundColor: string,
    data: string[] | number[],
    selectName: string
}

const CustomSelect = ({ selectName, handleChange, inputValue, backgroundColor = "red", data }: Props) => {
    return (
        <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
            <InputLabel id="demo-simple-select-label">{selectName}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={[
                    { background: "gray" },
                    (theme) => ({
                        '&:hover': {
                            color: theme.palette.primary.main,
                        },
                        '&:hover &&.MuiInputLabel-filled': {
                            color: 'blue'
                        },
                        "&& .MuiFilledInput-input:focus": {
                            borderTopRightRadius: 5,
                            borderTopLeftRadius: 5
                        }
                    }),
                ]}
                value={inputValue.toString()}
                label="Memory Size"
                onChange={handleChange("size")}
                MenuProps={{
                    PaperProps: {
                        sx: {
                            bgcolor: backgroundColor,
                            '& .MuiMenuItem-root': {
                                padding: 2,
                            },
                        },
                    },
                }}
            >
                {data.map((name) => (
                    <MenuItem
                        key={name}
                        value={name}
                    >
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default CustomSelect