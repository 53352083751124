import { Answer, CleanedData, ProcessorData, Steps } from "./Interfaces";
import { convertToHex } from './../../helper';
import { getApiAddress } from "./apiAddress";

export const deleteAnswers = async () => {
  try {
    // const response = await fetch(`${getApiAddress()}api/answer/all`, {
    //   method: 'DELETE',
    //   cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // });
  } catch (err) {
    console.error(err)
  }
}

export const compile = async (userId: number, program: string[], processorId: number, problemId?: string): Promise<CleanedData | undefined> => {
  try {
    let cleanedData;
    console.log('🚀 ~ compile ~ processorId', processorId);
    console.log({ userId, program, processorId, problemId })
    const response = await fetch(`/chiselserver/compileAndRun`, {
      method: 'POST',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId, program, processorId }),
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    const data: ProcessorData = await response.json()
    console.log('🚀 ~ compile ~ data', data);

    if (data) {
      console.log("cleaning data")
      cleanedData = cleanData(data)
      try {
        await fetch(`/nodeserver/api/compile`, {
          method: 'POST',
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': localStorage.getItem("token") || ''
          },
          body: JSON.stringify({ userId, program, processorId, problemId, compiledResult: JSON.stringify(cleanedData) }),
          referrerPolicy: 'no-referrer' // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        });
      } catch (err: any) {
        console.error('🚀 ~ compile ~ err', err);
        throw err;
      }
    }
    console.log('🚀 ~ compile ~ data', data);

    return cleanedData
  } catch (err: any) {
    console.error(err)
    return Promise.reject(new Error(err))
  }
}

export const getAllProblemAnswers = async (problemId: string): Promise<Answer[] | undefined> => {
  try {

    const response = await fetch(`${getApiAddress()}api/answer/${problemId}`, {
      method: 'GET',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': localStorage.getItem("token") || ''
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    const data = await response.json()
    console.log('🚀 ~ getAllProblemAnswers ~ data', data);
    if (data && data.errors) {

      throw Error(data.errors[0].msg)
    }
    return data
  } catch (err: any) {
    console.error(err)
    return Promise.reject(new Error(err))
  }
}


export const cleanData = (data: ProcessorData): { steps: Steps[], hex: String[] } => {
  const returnData: Steps[] = []
  const hexa: string[] = []
  for (let pos = 0; pos < data.state_status.length; pos++) {
    const ele: any = {}
    ele.step = pos
    ele.pc_status = data.pc_status[pos]
    ele.ir_status = data.ir_status[pos]

    if (data.acc_status) {
      ele.acc_status = data.acc_status[pos]
    }
    if (data.ma_status) {
      ele.ma_status = data.ma_status[pos]
    }

    ele.state_status = parseInt(data.state_status[pos])

    if (data.reg_status) {
      ele.reg_status = (data.reg_status[pos]).split(',')
    }

    if (data.dm_status) {
      ele.dm_status = (data.dm_status[pos]).split(',')
    }

    if (data.internal_memory_status) {
      ele.memory = (data.internal_memory_status[pos]).split(',')
    }

    const linesString: string = data.lines_status[pos]
    const lines: string[] = linesString.split(',')
    let linePos = 0
    ele.lines = {}
    for (const lineValue of lines) {
      if (lineValue === "false") {
        ele.lines[`l${linePos + 1}`] = false;
      } else {
        ele.lines[`l${linePos + 1}`] = true;
      }
      linePos++
    }

    if (data.stimulated_memory_address) {
      ele.stimulated_memory_address = parseInt(data.stimulated_memory_address[pos])
    }
    returnData.push(ele)
  }

  if (data.hex) {
    for (const line of data.hex) {
      hexa.push(convertToHex(line))
    }
  } else if (data.hex_text) {
    for (const line of data.hex_text) {
      hexa.push(convertToHex(line))
    }
  }
  return { steps: returnData, hex: hexa }
}