import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { FormControl, NativeSelect, Typography } from '@mui/material'
// import { render } from "react-dom";
import SettingsIcon from '@mui/icons-material/Settings';
// import "ace-builds/src-noconflict/mode-java";
// import "ace-builds/src-noconflict/theme-github";
// import mode-<language> , this imports the style and colors for the selected language.
import { EditorStyled } from '../Hexa/Styled';
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-assembly_x86'
// // there are many themes to import, I liked monokai.
import 'ace-builds/src-noconflict/theme-monokai'
// // this is an optional import just improved the interaction.
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import '../../App.css'
import { Ace } from 'ace-builds';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { assemblerEditorState, currentLineState, currentStepState, stepListState } from '../../Recoil/GlobalState';
import { ArrowIconButton, SettingsIconButton, Container } from './Styled';
import { useTranslation } from 'react-i18next';
import Tooltip from '@mui/material/Tooltip';
import { Steps } from '../../Api/Core/Interfaces';
import CustomAssemblyMode from './CustomAssemblyMode';
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
// function onChange(newValue: string) {
//   console.log("change", newValue);
// }

function handleControlChange(event: ChangeEvent) {
  console.log("change", event.target);
}



interface Props {
  showHexaContainer: boolean;
  setShowHexaContainer: React.Dispatch<React.SetStateAction<boolean>>
}

const Assembler = ({ showHexaContainer, setShowHexaContainer }: Props) => {
  const [assemblerState, setAssemblerState] = useRecoilState(assemblerEditorState);
  const { t } = useTranslation();
  const [stepList] = useRecoilState(stepListState);
  const setCurrentLine = useSetRecoilState(currentLineState);

  const [currentStep] = useRecoilState(currentStepState);
  const [linesPos, setlinesPos] = useState([] as number[]);
  const [cleanContent, setCleanContent] = useState([] as string[]);
  const editor = useRef(null)

  useEffect(() => {
    const customMode = new CustomAssemblyMode('\\b(?:ld|st|yes|stop|ldi|add|shl|br|brz|brnz|lda|mul|loop|adda|addx|sti|suba|sub)\\b');
    if (editor.current != null) {
      (editor as any).current.editor.session.setMode(customMode);
    }
  }, [editor.current])


  useEffect(() => {
    getFullLineNumbers()
    if (stepList.length) {
      const realPos: number = linesPos[stepList[currentStep] ? parseInt((stepList[currentStep] as Steps).pc_status) : 0];
      if (editor && editor.current) {
        (editor as any).current.editor.gotoLine(realPos)
      }
      setCurrentLine(cleanContent[realPos - 1])
    }
  }, [currentStep, stepList])

  useEffect(() => {

    if (editor && assemblerState.newLoadedData) {
      (editor as any).current.editor.setValue(assemblerState.content)
      setAssemblerState((old) => ({ ...old, newLoadedData: false }));
      (editor as any).current.editor.gotoLine(0)
    }
    setAssemblerState(prev => ({ ...prev, newLoadedData: false }))
  }, [assemblerState.newLoadedData])

  useEffect(() => {
    if (editor && editor.current) {
      (editor as any).current.editor.resize()
    }
  }, [showHexaContainer])

  const handleLoadEditor = (editor: any) => {
    console.log('object');
    console.log("editor", editor);

    if (editor && editor.session) {
      editor.setValue(assemblerState.content)
      editor.once("change", function () {
        editor.session.getUndoManager().reset();
      });
    }

    if (editor && editor.commands) {
      editor.commands.bindKey("ctrl-Z", () => {
        editor.undo()
      })
    }
  }

  const getFullLineNumbers = () => {
    let pos = 1
    const fullLines = []
    const lines = assemblerState.content.split("\n");
    for (const line of lines) {
      line.trim()
      if (line !== "" && !line.includes(".") && !line.includes(":") && !line.trim().startsWith("//")) {
        fullLines.push(pos)
      }
      pos++
    }
    setCleanContent(lines)
    setlinesPos(fullLines)
  }

  const onChange = (newValue: string) => {
    getFullLineNumbers()
    setAssemblerState((old) => ({ ...old, content: newValue }))
  }

  const handleSettings = () => {
    console.log('settings')
  }

  return (
    <Container>
      <SettingsIconButton
        size="medium"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={handleSettings}
        color={"primary"}
      >
        <SettingsIcon fontSize="large" />
      </SettingsIconButton>
      {!showHexaContainer &&
        <Tooltip title={t("assembler.tooltip.showHex") || ""} arrow>
          <ArrowIconButton
            variant="outlined"
            color="primary"
            onClick={() => setShowHexaContainer(true)}
          >
            <Typography variant="h6">

              H
            </Typography>
          </ArrowIconButton>
        </Tooltip>
      }
      <FormControl>
        <NativeSelect
          onChange={handleControlChange}
          name="age"
          inputProps={{ 'aria-label': 'age' }}
        >
          <option value={10}>{t("assembler.dropdown.assembler")}</option>
        </NativeSelect>
      </FormControl>
      <EditorStyled
        width={assemblerState.width}
        ref={editor}
        mode="assembly_x86"
        fontSize={16}
        theme="monokai"
        onLoad={handleLoadEditor}
        onChange={onChange}
        name="assembler-ace"
        editorProps={{ $blockScrolling: true }}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          enableSnippets: true,
          showLineNumbers: true,
          tabSize: 4,
        }}
      />
    </Container>
  )
}

export default Assembler
