import React, { useEffect, useState } from 'react'
import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, Stack, Switch, TextareaAutosize, TextField, Typography } from '@mui/material'
import { AssociativityType, CacheConfig, CacheRequestBody, CacheRequestType, CacheResponse, CacheResponseBody, CacheStep, CacheType, CmdOptions, MemoryConfig, MemoryInitilisationType, MemorySize, SingleRequestData, SingleResponse } from '../../Api/Core/Interfaces'
import Memories from '../../Components/Memories/Memories'
import { currentStepValue, loadingPageState, stepListState } from '../../Recoil/GlobalState'
import axios from 'axios'
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil'
import { useAuth } from '../../Api/Auth/testingAuth'
import { useLocation } from 'react-router-dom'
import Media from '../../Containers/Media/Media'
import { useQueries, useQuery } from '@tanstack/react-query'

const MemorySizes = [
  "1 MB",
  "2 MB",
  "4 MB",
  "8 MB",
  "16 MB",
  "32 MB",
  "64 MB",
  "128 MB",
  "256 MB"
]

const CacheTypes = [
  "Set Associative",
  "Direct Mapped",
  "Fully Associative"
]

const CacheSizes = [
  "16 B",
  "32 B",
  "64 B",
  "128 B",
  "256 B",
  "512 B",
  "1 KB",
  "2 KB",
  "4 KB",
  "8 KB",
  "16 KB",
  "32 KB",
  "64 KB",
  "128 KB",
  "256 KB"
]

const CacheBlockSizes = [
  "2",
  "4",
  "8",
  "16",
  "32",
  "64",
]

const CacheAssociativity = [
  "2",
  "4",
  "8",
  "16",
  "32",
]

const CachePolicy = [
  "Write Back",
  "Write Through"
]

const CacheAllocation = [
  "Yes", "No"
]

const CacheReplacement = [
  "LRU",
  "FIFO",
  "Random",
]

const cacheApiAddress = "/_cache"

const headers = {
  headers: {
    'X-RapidAPI-Key': 'your-rapid-key',
    'X-RapidAPI-Host': 'famous-quotes4.p.rapidapi.com',
  },
};

const CachePage = () => {
  // const [requests, setRequest] = useState<CacheRequestBody[]>([])
  const [fullList, setStepList] = useRecoilState(stepListState)
  const resetStepListState = useResetRecoilState(stepListState)
  const { userInfo } = useAuth()
  const [singleRequestData, setCurrentRequestData] = useState<SingleRequestData>({ data: 0, address: 0 })
  const [singleBatchRequestData, setSingleBatchRequestData] = useState<SingleRequestData>({ data: 0, address: 0 })
  const [batchRequestData, setBatchRequestData] = useState<CacheRequestBody[]>([])
  const currentStepVal = useRecoilValue(currentStepValue)
  const [batchType, setBatchType] = useState<CmdOptions>(CmdOptions.READ)
  // const [logs, setLogs] = useState<string[]>([])
  // const [cacheContent, setCacheContent] = useState<string[]>([])
  // const [memoryContent, setMemoryContent] = useState<string[]>([])
  const [canResetCache, setCanResetCache] = useState(false)
  const [memoryConfig, setMemoryConfig] = useState<MemoryConfig>({
    size: MemorySizes[0],
    baseAddress: "0",
    initialisationType: MemoryInitilisationType.Zero,
    count: 125
  })
  const location = useLocation()
  const locationState = location.state as { cacheType: CacheRequestType }
  const setLoadingState = useSetRecoilState(loadingPageState)
  const [cacheConfig, setCacheConfig] = useState({
    "Associativity": AssociativityType.THIRTY,
    "Block Size": "2",
    "Cache Size": "16 B",
    "Cache Type": "Direct Mapped",
    "Replacement Policy": "LRU",
    "Write Allocation": "Yes",
    "Write Hit Policy": "Write Through"
  })


  const [cacheData, memData] = useQueries({
    queries: [{
      queryKey: ["cache"],
      queryFn: async () => await axios.post(`${cacheApiAddress}/cache/reset/${userInfo.matricule}`, JSON.stringify(cacheConfig)),
      onSuccess: (data: any) => {
        if (data.statusText) {
          console.log("🚀 ~ file: HomePage.tsx:43 ~ HomePage ~ data:", data)
        }
      }
    }, {
      queryKey: ["memory"],
      queryFn: async () => await axios.post(`${cacheApiAddress}/memory/reset/${userInfo.matricule}`, JSON.stringify({
        "Initialization": memoryConfig.initialisationType,
        "Memory Size": memoryConfig.size,
        "Count": memoryConfig.count,
        "Starting Address": memoryConfig.baseAddress
      })),
      onSuccess: (data: any) => {
        if (data.statusText) {
          console.log("🚀 ~ file: HomePage.tsx:43 ~ HomePage ~ data:", data)
        }
      }
    }]
  })

  if (cacheData.isFetching || memData.isFetching) {
    setLoadingState(true)
    return null
  }

  if (cacheData.isFetched && memData.isFetched) {
    setLoadingState(false)
  } else {
    setLoadingState(true)
    return null
  }

  const updateCache = async () => {
    setLoadingState(true)
    try {
      const { data } = await axios.get(`${cacheApiAddress}/cache/content/${userInfo.matricule}`);

      console.log("🚀 ~ file: CachePage.tsx:113 ~ updateCache ~ resCache:", data)

      setStepList(prev => (prev as CacheStep[]).map((stepData: CacheStep, i) => {
        if (i === currentStepVal?.step) {
          return { ...stepData, cache: data }
        }
        return stepData as CacheStep
      }))

      // setCacheContent(data)
    } catch (err) {
      console.error(err)
    }
    setLoadingState(false)
  }

  const updateMemoryContent = async () => {
    try {
      const { data }: { data: { Content: number[] } } = await axios.get(`${cacheApiAddress}/memory/content/${userInfo.matricule}/${memoryConfig.baseAddress}/${memoryConfig.count}`);
      console.log("🚀 ~ file: CachePage.tsx:122 ~ updateMemoryContent ~ resMemory:", data)
      const newMem = data.Content.map(val => val.toString())

      setStepList(prev => (prev as CacheStep[]).map((stepData: CacheStep, i) => {
        if (i === currentStepVal?.step) {
          return { ...stepData, memory: newMem }
        }
        return stepData as CacheStep
      }))

    } catch (err) {
      console.error(err)
    }
  }

  const singleRequest = async (cmdType: CmdOptions) => {
    setLoadingState(true)
    let dest = `${cacheApiAddress}/req/${cmdType.toLowerCase()}/${userInfo.matricule}/0x${singleRequestData.address.toString(16)}`

    if (cmdType === CmdOptions.WRITE) {
      dest += `/${singleRequestData.data}`
    }
    try {
      const { data }: { data: SingleResponse } = await axios.get(dest);

      console.log("🚀 ~ file: CachePage.tsx:144 ~ singleRequest ~ CacheResponse:", data)

      const newStep: CacheStep = {
        step: fullList.length, ...data
      }
      console.log("🚀 ~ file: CachePage.tsx:228 ~ constnewStep:CacheStep[]=data.History.map ~ newStep:", newStep)

      setStepList(prev => ([...prev, newStep] as CacheStep[]))

      // await updateCache()
      // await updateMemoryContent()
    } catch (err) {
      console.error(err)
    }
    setLoadingState(false)
  }

  const resetCacheRequest = async () => {

    setLoadingState(true)
    const data = await axios.post(`${cacheApiAddress}/cache/reset/${userInfo.matricule}`, JSON.stringify(cacheConfig));
    if (data.statusText) {
      console.log("🚀 ~ file: CachePage.tsx:74 ~ resetCacheRequest ~ response", data)
      await updateCache()
      setCanResetCache(false)
    } else {
      console.log("something when wrong when resetting cache")
    }
    setLoadingState(false)
  }

  const resetMemoryRequest = async () => {
    setLoadingState(true)
    await axios.post(`${cacheApiAddress}/memory/reset/${userInfo.matricule}`,
      JSON.stringify({
        "Initialization": memoryConfig.initialisationType,
        "Memory Size": memoryConfig.size,
        "Count": memoryConfig.count,
        "Starting Address": memoryConfig.baseAddress
      }));
    await updateMemoryContent()
    console.log("🚀 ~ file: CachePage.tsx:190 ~ resetMemoryRequest ~ memoryConfig.initialisationType:", memoryConfig.initialisationType)
    setLoadingState(false)
  }

  const batchRequest = async () => {
    setLoadingState(true)
    console.log(JSON.stringify(batchRequestData))

    const { data }: { data: CacheResponseBody } = await axios.post(`${cacheApiAddress}/req/batch/${userInfo.matricule}`, JSON.stringify(batchRequestData));

    console.log("🚀 ~ file: CachePage.tsx:239 ~ batchRequest ~ batchRequestData:", JSON.stringify(batchRequestData))
    console.log("🚀 ~ file: CachePage.tsx:11 ~ singleRequest ~ response", data)

    data.History.forEach(ele => console.log(ele.cache))

    const newStep: CacheStep[] = data.History.map((hist, i) => ({
      step: fullList.length + i,
      log: data.Commands[i].log,
      memory: hist.mem.map(mem => mem.toString()),
      cache: hist.cache,
      request: hist.request
    }))
    console.log("🚀 ~ file: CachePage.tsx:228 ~ constnewStep:CacheStep[]=data.History.map ~ newStep:", newStep)

    setStepList(prev => ([...newStep] as CacheStep[]))

    // const newLogs = data.Commands.map(val => val.log)
    // const newMem = data.Commands.map(val => val.log)
    // const newCache = data.Commands.map(val => val.log)
    // const newRequest = data.History.map(val => val.request)
    // setLogs(olds => ([...olds, ...newLogs]))
    // setCacheContent(prev => ([...prev, ...newCache]))
    // await updateMemoryContent()

    // await updateCache()
    setLoadingState(false)

  }

  const clearLogs = () => {
    // setLogs([])
  }

  const changeSingleRequestData = (type: keyof SingleRequestData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentRequestData(prev => ({ ...prev, [type]: event.target.value }))
  }

  const changeBatchRequestData = (type: keyof SingleRequestData) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setSingleBatchRequestData(prev => ({ ...prev, [type]: event.target.value }))
  }

  const addBatchRequest = () => {
    const newRequest: CacheRequestBody = { cmd: batchType, address: "0x" + singleBatchRequestData.address.toString(16), data: singleBatchRequestData.data }
    setBatchRequestData(prev => ([...prev, newRequest]))
  }

  const handleCacheConfigChange = (key: string) => (event: SelectChangeEvent) => {
    // await resetCacheRequest()
    setCacheConfig(prev => ({ ...prev, [key]: event.target.value }))
    setCanResetCache(true)
  }

  const handleMemoryConfigChange = (key: string) => (event: SelectChangeEvent) => {
    setMemoryConfig(prev => ({ ...prev, [key]: event.target.value }))
  }

  const handleInitilizationType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMemoryConfig(prev => ({ ...prev, initialisationType: event.target.checked ? MemoryInitilisationType.Random : MemoryInitilisationType.Zero }))
  }

  return (
    <Grid container sx={{ mt: 4, p: 2, overflowY: "scroll" }} spacing={2}>
      <Grid item xs={12} md={6} lg={4}>

        <Stack sx={{ color: 'black' }} direction="column">
          <Media />
          {locationState.cacheType === CacheRequestType.single ? <Stack sx={{ mt: 2, color: 'black' }}>
            <Paper elevation={3} sx={{ padding: 1, background: '#243346' }}>
              <Stack direction='column'>
                <Typography variant='h6'>Single Request</Typography>
                <Stack direction='row' sx={{ width: '100%' }} alignItems='center' justifyContent='space-evenly'>
                  <TextField
                    value={singleRequestData?.address}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">0x</InputAdornment>,
                    }}
                    onChange={changeSingleRequestData("address")}
                    id="outlined-basic"
                    sx={{ background: 'gray', color: 'black', margin: .5 }}
                    label="Address"
                    variant="filled"
                  />
                  <TextField
                    value={singleRequestData?.data}
                    id="outlined-basic"
                    sx={{ background: 'gray', color: 'black', margin: .5 }}
                    onChange={changeSingleRequestData("data")}
                    label="Data"
                    type="number"
                    variant="filled"
                  />

                  <Box maxWidth='100px' sx={{ width: '100px' }}>
                    <Button sx={{ width: '75px', margin: .4 }} variant='contained' onClick={() => singleRequest(CmdOptions.READ)}>Read</Button>
                    <Button sx={{ width: '75px', margin: .4 }} variant='contained' onClick={() => singleRequest(CmdOptions.WRITE)}>Write</Button>
                  </Box>
                </Stack>
              </Stack>
            </Paper>
          </Stack> :
            <Stack sx={{ mt: 2, color: 'black' }}>
              <Paper elevation={3} sx={{ padding: 1, background: '#243346' }}>
                <Stack direction='column'>
                  <Typography variant='h6'>Batch Request</Typography>
                  <Stack direction='row' sx={{ width: '100%' }} alignItems='center' justifyContent='space-evenly'>
                    <TextField id="outlined-basic" onChange={changeBatchRequestData("address")}
                      sx={{ background: 'gray', color: 'black', margin: .5 }} label="Address" variant="filled" />
                    <TextField id="outlined-basic" type="number" onChange={changeBatchRequestData("data")} sx={{ background: 'gray', color: 'black', margin: .5 }} label="Data" variant="filled" />

                    <Stack direction="row" alignItems="center">
                      <Typography>Read</Typography>
                      <Switch value={batchType === CmdOptions.READ} onChange={() => setBatchType(prev => prev === CmdOptions.READ ? CmdOptions.WRITE : CmdOptions.READ)} />
                      <Typography>Write</Typography>
                    </Stack>
                    <Button sx={{ width: '75px', margin: .4 }} onClick={addBatchRequest} variant='contained'>Add Request</Button>
                  </Stack>
                  <Divider orientation="horizontal" />
                  <Stack direction='column' sx={{ width: '100%' }} alignItems='center' justifyContent='space-evenly'>

                    <Box
                      sx={{
                        mb: 2,
                        mt: 2,
                        width: "100%",
                        padding: .5,
                        display: "flex",
                        flexDirection: "column",
                        height: 270,
                        overflow: "hidden",
                        background: '#999',
                        overflowY: "scroll",
                        // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                      }}
                    >
                      {batchRequestData && batchRequestData.map((batchInfo, id) =>
                        <Typography sx={{ margin: .1, background: id === currentStepVal?.step ? "#c9eb5150" : "none", color: batchInfo.cmd === CmdOptions.READ ? "green" : "red" }} key={id}>{batchInfo.cmd} | Address: {batchInfo.address} {batchInfo.cmd === CmdOptions.WRITE && `| data: ${batchInfo.data}`}</Typography>
                      )}
                    </Box>

                    <Stack direction="row">
                      <Button sx={{ width: '75px', margin: .4 }} variant='contained' onClick={batchRequest} >Apply</Button>
                      {/* <Button sx={{ width: '75px', margin: .4 }} variant='contained'>Copy</Button>
                    <Button sx={{ width: '75px', margin: .4 }} variant='contained'>Paste</Button> */}
                      <Button sx={{ width: '75px', margin: .4 }} variant='contained' onClick={() => setBatchRequestData([])}>Clear List</Button>
                    </Stack>
                  </Stack>
                </Stack>
              </Paper>
            </Stack>}
          <Stack sx={{ mt: 2, color: 'black' }}>
            <Paper elevation={3} sx={{ padding: 1, background: '#243346' }}>
              <Stack direction='column'>
                <Typography variant='h6'>Logs</Typography>

                <Stack direction='column' sx={{ width: '100%' }} alignItems='center' justifyContent='space-evenly'>

                  <Box
                    sx={{
                      mb: 2,
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      height: 250,
                      overflow: "hidden",
                      background: '#999',
                      overflowY: "scroll",
                      // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                    }}
                  >
                    {(fullList as CacheStep[]).map((step, id) => <Typography sx={{ color: step.log.includes("Hit") ? "green" : "red" }} key={id}>{step.log}</Typography>
                    )}
                  </Box>

                  <Stack direction="row">
                    <Button sx={{ width: '75px', margin: .4 }} variant='contained'>Copy</Button>
                    <Button sx={{ width: '75px', margin: .4 }} onClick={clearLogs} variant='contained'>Clear</Button>
                  </Stack>
                </Stack>
              </Stack>
            </Paper>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12} md={6} lg={4}>
        <Paper elevation={3} sx={{ padding: 1, background: '#243346' }}>

          <Typography variant="h6">Cache</Typography>
          <Stack sx={{ margin: 2 }}>
            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Cache Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={cacheConfig["Cache Type"]}
                label="Age"
                onChange={handleCacheConfigChange("Cache Type")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {CacheTypes.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Cache Size</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={cacheConfig["Cache Size"]}
                label="Age"
                onChange={handleCacheConfigChange("Cache Size")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {CacheSizes.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Block Size</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={cacheConfig["Block Size"]}
                label="Age"
                onChange={handleCacheConfigChange("Block Size")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {CacheBlockSizes.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Associativity</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={cacheConfig["Associativity"]}
                label="Age"
                onChange={handleCacheConfigChange("Associativity")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {CacheAssociativity.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Write Hit Policy</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={cacheConfig["Write Hit Policy"]}
                label="Age"
                onChange={handleCacheConfigChange("Write Hit Policy")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {CachePolicy.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>


            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Write Allocation</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={cacheConfig["Write Allocation"]}
                label="Age"
                onChange={handleCacheConfigChange("Write Allocation")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {CacheAllocation.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Replacement Policy</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={cacheConfig["Replacement Policy"]}
                label="Age"
                onChange={handleCacheConfigChange("Replacement Policy")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {CacheReplacement.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Button disabled={!canResetCache} sx={{ width: '100%', mt: 2, mb: 2 }} onClick={resetCacheRequest} variant='contained'>Reset Cache</Button>
            <Divider sx={{ mb: 2 }} orientation='horizontal' />

            <Stack direction="column">
              <Typography>Cache Content</Typography>
              <Box
                sx={{
                  mb: 2,
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  height: 250,
                  overflow: "hidden",
                  background: '#999',
                  overflowY: "scroll",
                  // justifyContent="flex-end" # DO NOT USE THIS WITH 'scroll'
                }}
              >
                <div className="content" dangerouslySetInnerHTML={{ __html: currentStepVal ? (currentStepVal as CacheStep).cache : "" }}></div>

                {/* <Typography>2020/01/12 - 23:27:52</Typography>
                <Typography>A Memory of 13</Typography> */}
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Paper elevation={3} sx={{ mb: { md: 2, xs: 1 }, padding: 1, background: '#243346' }}>

          <Typography variant="h6">Main Memory</Typography>

          <Stack sx={{ margin: 2 }}>

            <FormControl sx={{ mt: 1 }} variant="filled" fullWidth>
              <InputLabel id="demo-simple-select-label">Mamory Size</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                sx={{ background: "gray" }}
                value={memoryConfig.size}
                label="Memory Size"
                onChange={handleMemoryConfigChange("size")}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "#222",
                      '& .MuiMenuItem-root': {
                        padding: 2,
                      },
                    },
                  },
                }}
              >
                {MemorySizes.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                  >
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack direction="row" alignItems="center">
              <Typography>Zero Initialisation</Typography>
              <Switch
                onChange={handleInitilizationType}
              />
              <Typography>Random Initialisation</Typography>
            </Stack>
            <Divider orientation='horizontal' />

            <TextField
              value={memoryConfig.baseAddress}
              InputProps={{
                startAdornment: <InputAdornment position="start">0x</InputAdornment>,
              }}
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => setMemoryConfig(prev => ({ ...prev, baseAddress: event.target.value }))
              }
              id="outlined-basic"
              sx={{ background: 'gray', color: 'black', mt: 1 }}
              label="Base Address"
              variant="filled"
            />

            <TextField
              onChange={
                (event: React.ChangeEvent<HTMLInputElement>) => setMemoryConfig(prev => ({ ...prev, count: parseInt(event.target.value) }))
              } value={memoryConfig.count}
              fullWidth
              id="outlined-basic"
              type="number"
              sx={{ background: 'gray', color: 'black', mt: 1 }}
              label="Count"
              variant="filled"
            />

            <Stack direction="row" gap={2}>
              <Button sx={{ width: '100%', mt: 1 }} color="warning" onClick={resetMemoryRequest} variant='contained'>Reset Memory</Button>
              <Button sx={{ width: '100%', mt: 1 }} onClick={updateMemoryContent} variant='contained'>Show Content</Button>
            </Stack>

            <Memories
              startingAddress={memoryConfig.baseAddress}
              addressColor='#2374AB'
              hex={false}
              size={4}
              values={currentStepVal ? (currentStepVal as CacheStep).memory : []}
            />
          </Stack>
        </Paper>
      </Grid>
    </Grid >
  )
}

export default CachePage