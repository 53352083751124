import axios from "axios";
import { getApiAddress } from "./apiAddress";

export const getUserData = async () => {
    try {
        const { data } = await axios.get(`${getApiAddress()}api/user`, {
            headers: {
                'Content-Type': 'application/json',
                'x-auth-token': localStorage.getItem('token') || ''
            }
        });

        // const data: ApiResponse = await response.json()
        return data
    } catch (err: any) {
        console.error("this is an error: ", err)
        throw err
    }
}
