import React from 'react'
import { Divider, ListItemText, MenuItem } from '@mui/material'
import { Container, ListItemIconStyled, MenuListStyled } from './Styled'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BorderClearIcon from '@mui/icons-material/BorderClear';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

const AdminSidebar = () => {
  const location = useLocation();
  return (
    <Container>
      <MenuListStyled>
        <MenuItem component={Link} to={`${location.pathname}`}>
          <ListItemIconStyled current={location.pathname.split("/").length === 2}>
            <AccountCircleIcon fontSize="large" />
          </ListItemIconStyled>
          <ListItemText primary="User Management" />
        </MenuItem>
        <Divider light />

        <MenuItem component={Link} to={`${location.pathname}/processors`}>
          <ListItemIconStyled current={location.pathname.split("/")[2] === "processors"}>
            <BorderClearIcon fontSize="large" />
          </ListItemIconStyled>
          <ListItemText primary="Processors" />
        </MenuItem>

        <Divider light />

        <MenuItem component={Link} to={`${location.pathname}/courses`}>

          <ListItemIconStyled current={location.pathname.split("/")[2] === "courses"}>
            <DashboardIcon fontSize="large" />
          </ListItemIconStyled>
          <ListItemText primary="Courses" />
        </MenuItem>

        <Divider light />

      </MenuListStyled>
    </Container>
  )
}

export default AdminSidebar
