import styled from 'styled-components'
import { FormControlLabel, Switch, Typography } from '@mui/material'

export const StyledSwitch = styled(Switch)`

`

interface Props {
  fontColor: string;
}

export const StyledFormControlLabel = styled(FormControlLabel)`
  color: white;
`

