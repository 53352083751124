import React, { Fragment, useState } from 'react'
import { Checkbox, NativeSelect, Stack } from '@mui/material'
import '../../App.css'
import { ContentContainer, FormControlLabelStyled, FormControlStyled, ProcessorBoxContainer } from './Styled'
import { MemoryTab, Steps } from '../../Api/Core/Interfaces'
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { MemoryTabState, currentStepValue } from '../../Recoil/GlobalState';
import { useTranslation } from 'react-i18next'
import Memories from '../../Components/Memories/Memories'
import ProcessorWithoutMA from '../../Components/Processors/ProcessorWithoutMA';
import ProcessorWithMA from '../../Components/Processors/ProcessorWithMA'
import ProcessorBox from './../../Components/ProcessorBox/ProcessorBox';
import ProcessorRiscSimple from '../../Components/Processors/ProcessorRiskSimple'
// import { useAuth } from '../../Api/Auth/use-auth'

interface Props { processor: number }

const Memory = ({ processor }: Props) => {
  console.log('🚀 ~ Memory ~ processor', processor);
  const { t } = useTranslation();

  const setCurrentMemoryTabState = useSetRecoilState(MemoryTabState);
  const currentStepVal = useRecoilValue(currentStepValue)
  const [isShowMemory, setShowMemory] = useState(false);
  const [isShowDataMemory, setShowDataMemory] = useState(false);
  const [isShowRegMemory, setShowRegMemory] = useState(false);
  const [isShowBoxes, setShowBoxes] = useState(false);
  const [hex] = useState(true);

  const handleShowMemory = (event: any) => {
    console.log('🚀 ~ handleShowMemory ~ event.target.checked', event.target.checked);
    setShowMemory(event.target.checked)
  }
  const handleShowBoxes = (event: any) => {
    console.log('🚀 ~ handleShowMemory ~ event.target.checked', event.target.checked);
    setShowBoxes(event.target.checked)
  }

  const handleControlChange = (event: any) => {
    console.log('🚀 ~ handleControlChange ~ event', event.target.value);
    setCurrentMemoryTabState(event.target.value)
  }

  // const handleChangeHex = (event: ChangeEvent<HTMLInputElement>) => {
  //   setHex(event.target.checked)
  // }


  return (
    <div className="memory">
      <Stack direction="row" gap={1}>
        <NativeSelect
          onChange={handleControlChange}
          name="age"
          inputProps={{ 'aria-label': 'age' }}
        >
          <option value={MemoryTab.processor}>{t("processor.dropdown.processor")}</option>
        </NativeSelect>
        {processor < 2 &&
          <FormControlLabelStyled
            control={<Checkbox checked={isShowMemory} onChange={handleShowMemory} name="showMemory" />}

            label={t("processor.show_memory")}
          />
        }
        {processor === 2 &&
          <>
            <FormControlLabelStyled
              control={<Checkbox checked={isShowRegMemory} onChange={(e) => setShowRegMemory(e.target.checked)} name="showRegMemory" />}

              label={t("processor.show_reg_memory")}
            />
            <FormControlLabelStyled
              control={<Checkbox checked={isShowDataMemory} onChange={(e) => setShowDataMemory(e.target.checked)} name="showDataMemory" />}
              label={t("processor.show_data_memory")}
            />
          </>
        }
        <FormControlLabelStyled
          control={<Checkbox checked={isShowBoxes} onChange={handleShowBoxes} name="showBoxes" />}

          label={t("processor.show_boxes")}
        />
        {/* <FormControlLabelStyled
          control={<Switch checked={hex} onChange={handleChangeHex} name="checkedA" />}
          label="Hex"
        /> */}
      </Stack>
      <div>

        {currentStepVal &&
          <ContentContainer>

            <ProcessorBoxContainer>
              {isShowBoxes ? <ProcessorBox processor={processor} /> :
                <Fragment>
                  {
                    processor === 2 &&
                    <ProcessorRiscSimple hex={hex} />
                  }
                  {
                    processor === 1 &&
                    <ProcessorWithMA hex={hex} />
                  }
                  {
                    processor === 0 &&
                    <ProcessorWithoutMA hex={hex} />
                  }
                </Fragment>

              }
            </ProcessorBoxContainer>

            {isShowMemory && currentStepVal.memory && <Memories startingAddress="0" name={t("processor.memory") as string} addressColor="#0c0" hex={false} values={currentStepVal.memory} size={12} />}
            {isShowRegMemory && (currentStepVal as Steps).reg_status && <Memories  startingAddress="0" name={t("processor.reg_memory") as string} addressColor="#ff3" reg={true} hex={false} values={(currentStepVal as Steps).reg_status!} size={12} />}
            {isShowDataMemory && (currentStepVal as Steps).dm_status && <Memories  startingAddress="0" name={t("processor.data_memory") as string} addressColor="#0c0" hex={false} values={(currentStepVal as Steps).dm_status!} size={12} />}
          </ContentContainer>
        }

      </div>
    </div>
  )
}

export default Memory
