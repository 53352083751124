import styled from 'styled-components'
import { Typography, TextField, Button } from '@mui/material'

export const Wrapper = styled.div`
    position: relative;
    
`

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    
    `

export const OutputValue = styled(Typography)`
    color: rgb(82, 240, 82) !important;
    margin-left: .5em !important;
    `

export const Cycle = styled(TextField)`
    width: 100px;
    `

export const SendButton = styled(Button)`
    height: 37px;
    `
