export interface Steps {
  step: Number;
  memory: string[];
  acc_status: string;
  pc_status: string;
  state_status: StateStatus;
  ir_status: string;
  lines: WithoutMALines & WithMALines & RiscSimple;
  stimulated_memory_address: number
  dm_status?: string[];
  reg_status?: string[];
  hex_text?: string[];
  ma_status?: string;
}

export interface CacheStep {
  step: Number;
  log: string;
  memory: string[];
  cache: string;
  request: {action: string, address: number, data: number};
}

export interface SingleResponse {
  log: string;
  memory: string[];
  cache: string;
  request: {action: string, address: number, data: number};
}

export interface stateType {
  from: { pathname: string }
}

export interface ApiResponse {
  hasErrors: boolean;
  type?: string;
  errors?: [{ msg: string, param?: keyof UserData }];
}

export interface ApiResponseStatus { status: boolean }

export enum CacheRequestType {
  single,
  batch
}

export interface UserData {
  matricule: number;
  firstname: string;
  lastname: string;
  email: string;
  role: Roles;
  lastConnection: Date;
  isAdmin: boolean;
}

export interface UserApiData {
  matricule: number;
  firstname: string;
  lastname: string;
  email: string;
  role: Roles;
}

export interface SignInApiResponse extends ApiResponse {
  payload: { token: string, user: UserData }
}
export interface CheckConnectedApiResponse extends ApiResponse {
  payload: UserData
}

export interface Problem {
  _id: string;
  title: string;
  type: string;
  processor: number;
  question: string;
  answers?: [string];
  isAdmin: boolean
}

export interface UserProcessorData {
  courses: Course[];
  answers: Answer[];
}

export interface Course {
  name: string;
  processors: Processor[]
}

export interface Processor {
  name: string;
  type: number;
  problems: Problem[]
}

export interface User {
  _id: string;
  lastConnection: Date;
  courses: Course[];
  answers: Answer[];
}


export interface UserConnectionResponse {
  matricule: number;
  password: string;
  token: string;
}

export interface UserConnectionInfo {
  matricule: number;
  token: string;
}

export interface Answer {
  _id: string;
  problem: string;
  published: Date;
  content: string[];
  compiledResult: string;
}

export interface CleanedData {
  steps: Steps[], hex: String[]
}

export enum QuestionType {
  QCM = "QCM",
  Q_ONLY = "Q_ONLY",
  DYNAMIC = "DYNAMIC"
}

export enum Severity {
  Warning = "warning",
  Error = "error",
  Info = "info",
  Success = "success",
}

export enum PlayingMode {
  Regular = "regular",
  Memory = "memory",
  Execute = "execute",
}


export interface SnackbarData {
  severity: Severity;
  message: string;
  open: boolean;
}

interface WithoutMALines {
  l1: boolean;
  l2: boolean;
  l3: boolean;
  l4: boolean;
  l5: boolean;
  l6: boolean;
  l7: boolean;
  l8: boolean;
  l9: boolean;
  l10: boolean;
  l11: boolean;
  l12: boolean;
  l13: boolean;
  l14: boolean;
  l15: boolean;
  l16: boolean;
  l17: boolean;
  l18: boolean;
  l19: boolean;
  l20: boolean;
  l21: boolean;
  l22: boolean;
  l23: boolean;
  l24: boolean;
  l25: boolean;
  l26: boolean;
  l27: boolean;
}

interface RiscSimple {
  l1: boolean;
  l2: boolean;
  l3: boolean;
  l4: boolean;
  l5: boolean;
  l6: boolean;
  l7: boolean;
  l8: boolean;
  l9: boolean;
  l10: boolean;
  l11: boolean;
  l12: boolean;
  l13: boolean;
  l14: boolean;
  l15: boolean;
  l16: boolean;
  l17: boolean;
  l18: boolean;
  l19: boolean;
  l20: boolean;
  l21: boolean;
  l22: boolean;
  l23: boolean;
  l24: boolean;
  l25: boolean;
  l26: boolean;
  l27: boolean;
  l28: boolean;
  l29: boolean;
}

interface WithMALines {
  l1: boolean;
  l2: boolean;
  l3: boolean;
  l4: boolean;
  l5: boolean;
  l6: boolean;
  l7: boolean;
  l8: boolean;
  l9: boolean;
  l10: boolean;
  l11: boolean;
  l12: boolean;
  l13: boolean;
  l14: boolean;
  l15: boolean;
  l16: boolean;
  l17: boolean;
  l18: boolean;
  l19: boolean;
  l20: boolean;
  l21: boolean;
  l22: boolean;
  l23: boolean;
  l24: boolean;
  l25: boolean;
  l26: boolean;
  l27: boolean;
  l28: boolean;
  l29: boolean;
  l30: boolean;
  l31: boolean;
  l32: boolean;
  l33: boolean;
  l34: boolean;
  l35: boolean;
  l36: boolean;
  l37: boolean;
  l38: boolean;
  l39: boolean;
  l40: boolean;
  l41: boolean;
  l42: boolean;
}

export interface Animations {
  decode: boolean;
  fetch: boolean;
  write: boolean;
  read: boolean;
}

export enum MemoryTab {
  memory,
  processor,
  other
}

export enum StateStatus {
  Fetch,
  Decode,
  Execute,
  Write,
  Read
}

export enum PlayingStatus {
  Playing,
  Paused,
  Stopped
}

export interface ProcessorSettings {

  primaryFontColor: string;
  secondaryFontColor: string;
  controls: string;
  registreColor: string;
  otherColors: string;
  titleBoxColor: string;
  lineColorOn: string;
  lineColorOff: string;
  arrowColorOn: string;
  strokeWidth: string;
  titleBoxSize: string;
  fontSize: string;
  numberFontFamily: string;
  regularFontFamily: string;
  smallTextSize: string;
  circlesSize: string;
}

export interface LoadingStatus {
  compiling: boolean;
  executing: boolean;
  firstLoad: boolean;
  answersLoad: boolean;
}
export interface MediaStatus {
  playingState: PlayingStatus;
  timeoutPeriode: number;
}

export enum Roles {
  STUDENT = "STUDENT",
  ADMIN = "ADMIN",
  TEACHER = "TEACHER"
}

export interface EditorState {
  width: string;
  mode: string;
  fontSize: number;
  theme: string;
  newLoadedData: boolean;
  name: string;
  enableBasicAutocompletion: boolean;
  enableLiveAutocompletion: boolean;
  showLineNumbers: boolean;
  tabSize: number;
  selector: string;
  content: string;
}

export interface ProcessorData {
  acc_status: string[];
  hex: string[];
  internal_memory_status: string[];
  ir_status: string[];
  lines_status: string[];
  pc_status: string[];
  ma_status?: string[];
  state_status: string[];
  stimulated_memory_address: string;
  dm_status?: string[];
  reg_status?: string[];
  hex_text?: string[];
}

export interface ModalStatus {
  showAnswers: boolean;
  showQuestion: boolean;
}


//########################### Cache ##################################
export enum CmdOptions {
  READ = 'Read',
  WRITE = 'Write'
}
export interface CacheRequestBody {
  cmd: CmdOptions,
  address: string,
  data?: number
}

export interface SingleRequestData {
  address: number,
  data: number
}

export interface CacheResponse {
  address: string,
  data: number,
  log: string
}

export interface CacheResponseBody {
  Commands: CacheResponse[],
  History: CacheHistory[],
  Note: string
}

export interface CacheHistory {
  cache: string,
  mem: number[],
  request: { action: string, address: number, data: number }
}

export enum MemoryInitilisationType {
  Zero = "Zero Initialised",
  Random = "Random Initialised"
}

export enum MemorySize {
  "1 MB",
  "2 MB",
  "4 MB",
  "8 MB",
  "16 MB",
  "32 MB",
  "64 MB",
  "128 MB",
  "256 MB"
}

export interface MemoryConfig {
  initialisationType: MemoryInitilisationType,
  baseAddress: string,
  count: number,
  size: string
}

export enum ReplacementPolicy {
  LRU = "LRU",
  FIFO = "FIFO",
  RANDOM = "Random"
}

export enum CacheType {
  DirectMapped = "Direct Mapped",
  SetAssociative = "Set Associative",
  FullyAssociative = "Fully Associative"
}

export enum AssociativityType {
  TWO = "2", THIRTY = "32"
}

export enum WriteHitPolicy {
  WriteBack = "Write Back",
  WriteThrough = "Write Through"
}

export enum WriteAllocation {
  YES = "Yes",
  NO = "No"
}

export interface CacheConfig {
  Associativity: AssociativityType,
  BlockSize: string,
  CacheSize: number,
  CacheType: CacheType,
  ReplacementPolicy: ReplacementPolicy,
  WriteAllocation: WriteAllocation,
  WriteHitPolicy: WriteHitPolicy
}
//#############################################################