import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, FilledInput, InputAdornment, IconButton, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Form, ConnectButton } from '../AddUser/Styled'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { useAuth } from '../../Api/Auth/use-auth'
import { Severity } from '../../Api/Core/Interfaces'
import { errorState } from '../../Recoil/GlobalState'

interface Props {
  matricule: string;
  redirect: () => void;
}

const ChangePassword = ({ matricule, redirect }: Props) => {
  const { t } = useTranslation()
  const setCurrentErrorState = useSetRecoilState(errorState)
  let auth = useAuth()

  const [errorMsgs, setErrors] = useState({
    matricule: '',
    password: '',
  })

  const [newValues, setNewValues] = useState({
    password: '',
    confirmationPassword: '',
    showPassword: false,
    showConfirmationPassword: false,
    hasErrors: false
  })

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        // callMyFunction();
        handleUpdateUserInfo()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [newValues]);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  const handleChangeNew = (prop: any) => (event: any) => {
    setNewValues({ ...newValues, [prop]: event.target.value })
  }

  const handleClickShowPasswordNew = () => {
    setNewValues({ ...newValues, showPassword: !newValues.showPassword })
  }

  const handleUpdateUserInfo = async () => {
    if (newValues.password === newValues.confirmationPassword) {
      const response = await auth.changePassword(parseInt(matricule), newValues.password)
      console.log('🚀 ~ handleUpdateUserInfo ~ response', response);

      console.log('🚀 ~ handleUpdateUserInfo ~ parseInt(values.matricule)', parseInt(matricule));
      if (response.hasErrors) {
        setCurrentErrorState((prev) => ({
          ...prev,
          open: true,
          message: t("errors.Invalid_Credentials"),
          severity: Severity.Error,
        }))
      } else {
        setNewValues({
          password: '',
          confirmationPassword: '',
          showPassword: false,
          showConfirmationPassword: false,
          hasErrors: false
        })
        setCurrentErrorState((prev) => ({
          ...prev,
          open: true,
          message: t("notification.password_changed"),
          severity: Severity.Success,
        }))
        redirect()
      }

    } else {

      setNewValues(prev => ({ ...prev, hasErrors: true }))
      setErrors(prev => ({ ...prev, password: t("login.not_same_password") }))
    }
  }


  return (
    <Form>
      <FormControl variant="filled">
        <InputLabel htmlFor="filled-adornment-password">
          {t('login.password')}
        </InputLabel>
        <FilledInput
          id="filled-adornment-password"
          type={newValues.showPassword ? 'text' : 'password'}
          value={newValues.password}
          error={!!errorMsgs.password}
          name="password"
          onChange={handleChangeNew('password')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPasswordNew}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {newValues.showPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <FormControl variant="filled">
        <InputLabel htmlFor="filled-adornment-password-second">
          {t('login.confirm_password')}
        </InputLabel>
        <FilledInput
          id="filled-adornment-password-second"
          type={newValues.showPassword ? 'text' : 'password'}
          value={newValues.confirmationPassword}
          error={!!errorMsgs.password}
          name="password"
          onChange={handleChangeNew('confirmationPassword')}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPasswordNew}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {newValues.showPassword ? (
                  <Visibility />
                ) : (
                  <VisibilityOff />
                )}
              </IconButton>
            </InputAdornment>
          }
        />
      </FormControl>
      <Typography variant="caption">{t("login.minmax_password")}</Typography>

      {newValues.hasErrors && (
        <Typography color="error">{errorMsgs.password}</Typography>
      )}

      <ConnectButton
        onClick={handleUpdateUserInfo}
        variant="contained"
        color="primary"
      >
        {t('login.update_user')}
      </ConnectButton>
    </Form>
  )
}

export default ChangePassword
