import React from 'react'
import { CircularProgressStyled } from './Styled'

const Spinner = () => {
  return (
      <CircularProgressStyled size="large" />
  )
}

export default Spinner
