import React, { Fragment, useState } from 'react'
// import { useAnimation } from 'framer-motion'
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentLineState, currentStepValue } from '../../Recoil/GlobalState';
import { convertToHex } from '../../helper';
import { Steps } from '../../Api/Core/Interfaces';

interface Props {
  hex: boolean
}

const ProcessorRiscSimple = ({ hex }: Props) => {
  // const [currentLine] = useRecoilState(currentLineState);
  const currentStepVal = useRecoilValue(currentStepValue)
  const [hexState, setHexState] = useState({ ma: false, pc: false, ir: true, acc: false })

  // const primaryFontColor = '#000000'
  const hexColor = '#a7501e'
  // const secondaryFontColor = '#fff'
  const registreColor = '#a5d9f1'
  // const otherColors = '#f1f0a5'
  const titleBoxColor = '#1a1a1a'
  const titleBoxWeight = 'bold'
  // const arrowColorOn = "#1279ee"

  const lineColorOn = 'red'
  // const circlesSize = "6"
  // const regularFontFamily = "Arial"
  const lineColorOff = 'white'
  const strokeWidth = '3'
  const titleBoxSize = '14'
  const fontSize = '20'
  const numberFontFamily = 'consolas'
  // const smallTextSize = '10'
  const registreMemColor = "#ff3"
  const instrMemColor = "#007a00"
  const dataMemColor = "#0c0"

  // useEffect(() => {
  //   if (stepList[currentStep] && stepList[currentStep].state_status === StateStatus.Fetch) {
  //     console.log('supposed to do something here')
  //     controls.start(() => ({
  //       stroke: "#f75e5e",
  //       pathLength: 1,
  //       transition: { duration: 10, ease: "easeOut" },
  //     }))
  //   }
  //   return controls.stop
  // }, [currentStep, stepList])


  const getStatus = () => {
    let status = ''
    console.log('🚀 ~ getStatus ~ currentStepVal', currentStepVal);
    switch ((currentStepVal as Steps)?.state_status) {
      case 0:
        status = 'Start'
        break;
      case 1:
        status = 'Fetch'
        break;
      case 2:
        status = 'Decode'
        break;
      case 3:
        status = 'Execute'
        break;
      case 4:
        status = 'End'
        break;
    }
    return status

  }

  // const variants = {
  //   hidden: { opacity: 0, strokeWidth: 1, fill: "red" },
  // }

  const overHex = (hexValue: any) => {
    switch (hexValue) {
      case 'ma':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ma }))
        break
      case 'pc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.pc }))
        break
      case 'acc':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.acc }))
        break
      case 'ir':
        setHexState((prev) => ({ ...prev, [hexValue]: !hexState.ir }))
        break
    }
    console.log('supposed to change')
  }

  return (
    <Fragment>{currentStepVal && (currentStepVal as Steps).lines &&
      <svg width="98%" data-name="Layer 1" viewBox="0 0 1530 398">
        {/* 
        <g id="instruction-box"
          transform="translate(-470 10)"
        >
          <path
            fill="#fff"
            stroke="#000"
            d="M639.75.5h290v60h-290z"
          />
          <text
            transform="translate(770 34)"
            fontSize={fontSize}
            textAnchor="middle"
            fontWeight="bold"
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
          >
            {currentLine ? currentLine.split("//")[0] : currentLine}
          </text>
        </g> */}

        <g id="state-box" transform="translate(-500 10)">
          <path fill="#fff" stroke="#000" d="M501.5.5h120v60h-120z" />
          <text
            transform="translate(560 36)"
            fontSize="18"
            textAnchor="middle"
            fontWeight="bold"
            fontFamily="ArialMT, Arial"
            style={{ isolation: 'isolate' }}
          >
            {getStatus()}
          </text>
        </g>

        {/* ============================================== Boxes ========================================================= */}
        <g id="registre-box">
          <rect x="856.43" y="90.5" width="160" height="260" fill={registreMemColor} />
          <text transform="translate(897.92 225)" fontSize={titleBoxSize}
            fill={titleBoxColor}
            fontWeight={titleBoxWeight} fontFamily="ArialMT, Arial">Registers</text>
          <path d="M857.5,330.5l11.25,7.5-11.25,7.5Z" transform="translate(-1.07 -7)" stroke="#000" fill="none" />

          <text transform="translate(869.25 114)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">data_in</text>

          <text transform="translate(870.93 243)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">rdst</text>

          <text transform="translate(867.6 273)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">rsrc1</text>

          <text transform="translate(867.6 303)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">rsrc2</text>

          <text transform="translate(991.93 194)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic" letterSpacing="-0.04em">A</text>

          <text transform="translate(991.93 274)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">B</text>
        </g>


        <g id="instr-box">
          <rect x="369" y="137.5" width="110" height="160" fill={instrMemColor} />
          <rect x="376.57" y="138.5" width="102.43" height="23" fill="none" />
          <text transform="translate(390 155)" fontSize={titleBoxSize}
            fill={titleBoxColor}
            fontWeight={titleBoxWeight} fontFamily="ArialMT, Arial">Instr Mem</text>

          <text transform="translate(376.92 223)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">addr</text>

          <text transform="translate(417.57 223)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">data_out</text>
        </g>


        <g id="pc-box">
          <rect x="193.43" y="169.5" width="60" height="30" fill="none" />
          <rect x="150" y="173.25" width="150" height="90" fill={registreColor} />
          <text transform="translate(215.98 189)"
            fontSize={titleBoxSize}
            fill={titleBoxColor}
            fontWeight={titleBoxWeight}
            fontFamily="ArialMT, Arial">PC</text>
          <path d="M151.07,246.5l11.25,7.5-11.25,7.5Z" transform="translate(-1.07 -7)" stroke="#000" fill="none" />
          <g data-name="cell- 9MH3P SVimNwaclgp3E-16">
            <path fill="none" d="M159.27 150.5h40v20h-40z" />
            <text
              transform="translate(225 225)"
              fontWeight="bold"
              textAnchor="middle"
              fontSize={fontSize}
              fontFamily={numberFontFamily}
              style={{ isolation: 'isolate' }}
            >
              {currentStepVal && hexState.pc ? convertToHex((currentStepVal as Steps).pc_status) : (currentStepVal as Steps).pc_status}
            </text>
          </g>

          <g
            onClick={() => overHex('pc')}
            style={{ cursor: "pointer" }}
            transform="translate(-5 110)"
          >
            <rect
              x="220.33"
              y="134.5"
              width="24.77"
              height="16"
              rx="2.4"
              fill={hexState.pc ? hexColor : '#fff'}
              stroke={hexState.pc ? '#fff' : hexColor}
            />
            <text
              transform="translate(223.11 145)"
              fontSize="10"
              fill={hexState.pc ? 'snow' : hexColor}
              fontFamily="ArialMT, Arial"
            >
              {hexState.pc ? "Hex" : "Dec"}
            </text>
          </g>
        </g>

        <g id="ir-box">
          <rect x="561.43" y="175.5" width="60" height="30" fill="none" />
          <rect x="516.43" y="176.75" width="150" height="87.5" fill={registreColor} />
          <path d="M517.5,248.5l11.25,7.5-11.25,7.5Z" transform="translate(-1.07 -7)" stroke="#000" fill="none" />
          <text transform="translate(585.93 193)"
            fontSize={titleBoxSize}
            fill={titleBoxColor}
            fontWeight={titleBoxWeight}
            fontFamily="ArialMT, Arial">IR</text>

          <text
            transform="translate(590 227)"
            fontWeight="bold"
            textAnchor="middle"
            fontSize={fontSize}
            fontFamily={numberFontFamily}
            style={{ isolation: 'isolate' }}
          >
            {(currentStepVal as Steps) && hexState.ir ? convertToHex((currentStepVal as Steps).ir_status) : (currentStepVal as Steps).ir_status}
          </text>

          <g
            onClick={() => overHex('ir')}
            style={{ cursor: "pointer" }}
            transform="translate(360 -50)"
          >
            <rect
              x="221.5"
              y="294"
              width="24.77"
              height="16"
              rx="2.4"
              fill={hexState.ir ? hexColor : '#fff'}
              stroke={hexState.ir ? '#fff' : hexColor}
            />
            <text
              transform="translate(225.11 305)"
              fontSize="10"
              fill={hexState.ir ? 'snow' : hexColor}
              fontFamily="ArialMT, Arial"
              style={{ isolation: 'isolate' }}
            >
              {hexState.ir ? "Hex" : "Dec"}
            </text>
          </g>
        </g>

        <g id="alu-box">
          <polygon points="1105.31 146.82 1105.31 223.55 1203.31 246.22 1203.31 169.48 1105.31 146.82" fill="#9a9b9b" />
          <polygon points="1105.31 312.98 1203.31 294.52 1203.31 224.08 1105.31 240.45 1105.31 312.98" fill="#9a9b9b" />
          <text transform="translate(1169.26 187)" fontSize={titleBoxSize}
            fill={titleBoxColor}
            fontWeight={titleBoxWeight}
            fontFamily="ArialMT, Arial">ALU</text>

          <text transform="translate(1110.93 192)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic"
            letterSpacing="-0.04em">A</text>

          <text transform="translate(1111.93 273)" fontSize="12" fontFamily="Arial-ItalicMT, Arial"
            fontStyle="italic">B</text>

          <text transform="translate(1190.26 234)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic"
            letterSpacing="-0.02em">F</text>
        </g>


        <g id="data_mem-box">
          <rect x="1366.43" y="153.5" width="110" height="160" fill={dataMemColor} />

          <text transform="translate(1378.92 194)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">addr</text>

          <text transform="translate(1376.25 272)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">data_in</text>

          <text transform="translate(1422.57 234)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">data_out</text>
          <text transform="translate(1390 172)"
            fontSize={titleBoxSize}
            fill={titleBoxColor}
            fontWeight={titleBoxWeight} fontFamily="ArialMT, Arial">Data Mem</text>
        </g>

        <g id="+1-box">
          <rect y="179.5" width="40" height="40" fill="#dae8fc" />
          <text transform="translate(9.67 204)" fontSize="18" fontFamily="ArialMT, Arial">+1</text>
        </g>


        <g id="second-multiplexeur">
          <path d="M757.5,47.5l40,20v80l-40,20Z" transform="translate(-1.07 -7)" fill="#dae8fc" />

          <g id="cell-0nYCs-QFj4XH6EBm0FDg-25">
            <rect x="753.43" y="53.5" width="25" height="30" fill="none" />
            <text transform="translate(762.59 72)" fontSize="12" fontFamily="ArialMT, Arial">0</text>
          </g>
          <g id="cell-0nYCs-QFj4XH6EBm0FDg-26">
            <rect x="753.43" y="83.5" width="25" height="30" fill="none" />
            <text transform="translate(762.59 102)" fontSize="12" fontFamily="ArialMT, Arial">1</text>
          </g>
          <g id="cell-0nYCs-QFj4XH6EBm0FDg-27">
            <rect x="753.43" y="114.5" width="25" height="30" fill="none" />
            <text transform="translate(762.59 133)" fontSize="12" fontFamily="ArialMT, Arial">2</text>
          </g>
        </g>


        <g id="first-multiplexeur">
          <g id="cell-Vg62LgSES3xolwbW8EE_-179" data-name="cell-Vg62LgSES3xolwbW8EE -179">
            <path d="M81.07,186.5l40,20v40l-40,20Z" transform="translate(-1.07 -7)" fill="#dae8fc" stroke="#6c8ebf" strokeMiterlimit="10" />
          </g>

          <g id="cell-0nYCs-QFj4XH6EBm0FDg-28">
            <rect x="79" y="188.5" width="20" height="22" fill="none" />
            <text transform="translate(85.59 203)" fontSize="12" fontFamily="ArialMT, Arial">0</text>
          </g>
          <g id="cell-0nYCs-QFj4XH6EBm0FDg-29">
            <rect x="79" y="223.5" width="20" height="22" fill="none" />
            <text transform="translate(85.59 238)" fontSize="12" fontFamily="ArialMT, Arial">1</text>
          </g>
        </g>
        {/* ============================================== Lines ========================================================= */}

        <g id="line-1"
          stroke={
            (currentStepVal as Steps).lines.l1
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M333.5,224.5v-87H21.5l-.37,42.63" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <circle cx="332.43" cy="220.5" r="3" fill={
            (currentStepVal as Steps).lines.l1
              ? lineColorOn
              : lineColorOff
          } />
          <path d="M21.08,185.38l-3.44-7,3.49,1.78,3.51-1.72Z" transform="translate(-1.07 -7)"
            fill={
              (currentStepVal as Steps).lines.l1
                ? lineColorOn
                : lineColorOff
            } strokeMiterlimit="10" />
        </g>
        <g id="cell-Vg62LgSES3xolwbW8EE_-167" data-name="cell-Vg62LgSES3xolwbW8EE -167">
          <path d="M1487.5,217.5l22.93-22.93,12.07-12.07,5-5" transform="translate(-1.07 -7)" fill="none" />
        </g>


        <g id="line-19"
          stroke={
            (currentStepVal as Steps).lines.l19
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1067.5,197.5l33.63.21" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M1106.38,197.74l-7,3.46,1.77-3.49-1.73-3.51Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l19
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>

        <g id="line-14"
          stroke={
            (currentStepVal as Steps).lines.l14
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M73.14,242.22,7.5,243.5v94h720v-100l-10-10" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M78.39,242.12l-6.93,3.64,1.68-3.54-1.81-3.46Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l14
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>

        <g id="line-5"
          stroke={
            (currentStepVal as Steps).lines.l5
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M330.76,227.46l33.22-.3" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M369.23,227.11l-7,3.56,1.72-3.51-1.78-3.49Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l5
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>


        <g id="line-15"
          stroke={
            (currentStepVal as Steps).lines.l15
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M852.13,308.76,747.5,308.5v-71l-10-10" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M857.38,308.78l-7,3.48,1.76-3.5-1.74-3.5Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l15
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>


        <g id="line-17"
          stroke={
            (currentStepVal as Steps).lines.l17
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M850.49,249.83l-63-.33v-12l-10-10" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M855.74,249.85l-7,3.47,1.77-3.49-1.73-3.51Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l17
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>


        <g id="line-7"
          stroke={
            (currentStepVal as Steps).lines.l7
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M667.5,228l30-.5" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
        </g>


        <g id="line-16"
          stroke={
            (currentStepVal as Steps).lines.l16
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M851.57,276.76l-84.07-.26v-39l-10-10" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M856.82,276.78l-7,3.47,1.76-3.49-1.74-3.51Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l17
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>


        <g id="line-9"
          stroke={
            (currentStepVal as Steps).lines.l9
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M697.5,227.5h20" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
        </g>


        <g id="line-10"
          stroke={
            (currentStepVal as Steps).lines.l10
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M717.5,227.5h20" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
        </g>


        <g id="line-11"
          stroke={
            (currentStepVal as Steps).lines.l11
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M737.5,227.5h20" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
        </g>


        <g id="line-12"
          stroke={
            (currentStepVal as Steps).lines.l12
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M757.5,227.5h20" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
        </g>

        <g id="line-23"
          stroke={
            (currentStepVal as Steps).lines.l23
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1077.5,277.5h23.63" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M1106.38,277.5l-7,3.5,1.75-3.5-1.75-3.5Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l23
              ? lineColorOn
              : lineColorOff
          }
            strokeMiterlimit="10" />
        </g>


        <g id="line-21"
          stroke={
            (currentStepVal as Steps).lines.l21
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1186.12,325.63l.38-20.13" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M1186,330.88l-3.37-7.06,3.47,1.81,3.53-1.68Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l21
              ? lineColorOn
              : lineColorOff
          }
            strokeMiterlimit="10" />
          <text transform="translate(1176.93 338)" fill={
            (currentStepVal as Steps).lines.l21
              ? lineColorOn
              : lineColorOff
          } fontSize="12" fontFamily="Arial-ItalicMT, Arial" strokeWidth=".1"
            fontStyle="italic">NZ</text>
        </g>


        <g id="line-26"
          stroke={
            (currentStepVal as Steps).lines.l26
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M797.5,117.26l54.11.93" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M856.86,118.28l-7.06,3.38,1.81-3.47-1.69-3.53Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l26
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>

        <g id="line-6"
          stroke={
            (currentStepVal as Steps).lines.l6
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M480.29,228l30.84,0" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M516.38,228l-7,3.5,1.75-3.5-1.75-3.5Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l6
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>

        <g id="line-4"
          stroke={
            (currentStepVal as Steps).lines.l4
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M300.47,227.87l30.6-.37" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l4
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>


        <g id="line-20"
          stroke={
            (currentStepVal as Steps).lines.l20
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1067.5,197.5l10-10v-90h250v103h33.63" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M1366.38,200.5l-7,3.5,1.75-3.5-1.75-3.5Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l20
              ? lineColorOn
              : lineColorOff
          }
            strokeMiterlimit="10" />
        </g>


        <g id="line-22"
          stroke={
            (currentStepVal as Steps).lines.l22
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1017.5,277.68l60-.18" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
        </g>


        <g id="line-18"
          stroke={
            (currentStepVal as Steps).lines.l18
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1018.14,197.34l49.36.16" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
        </g>


        <g id="line-24"
          stroke={
            (currentStepVal as Steps).lines.l24
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1360.13,277.5H1327.5v80h-250v-77" transform="translate(-1.07 -7)" fill="none"
            strokeMiterlimit="10" />
          <path d="M1365.38,277.5l-7,3.5,1.75-3.5-1.75-3.5Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l24
              ? lineColorOn
              : lineColorOff
          }
            strokeMiterlimit="10" />
          <circle cx="1076.43" cy="270.5" r="3" fill={
            (currentStepVal as Steps).lines.l24
              ? lineColorOn
              : lineColorOff
          } />
        </g>

        <g id="line-25"
          stroke={
            (currentStepVal as Steps).lines.l25
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1478.52,237.5h29V7.5h-810v99l53.63-.18" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M756.38,106.3l-7,3.53,1.74-3.51-1.76-3.49Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l25
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>

        <g id="line-8"
          stroke={
            (currentStepVal as Steps).lines.l8
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M697.5,224.5v-87h53.63" transform="translate(-1.07 -7)" fill="none" />
          <circle cx="696.43" cy="220.5" r="3" fill={
            (currentStepVal as Steps).lines.l8
              ? lineColorOn
              : lineColorOff
          } />
          <path d="M756.38,137.5l-7,3.5,1.75-3.5-1.75-3.5Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l8
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>


        <g id="line-21"
          stroke={
            (currentStepVal as Steps).lines.l21
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1205.5,241.5h82V27.5h-560v50h23.63" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M756.38,77.5l-7,3.5,1.75-3.5L749.38,74Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l21
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>


        <g id="line-3"
          stroke={
            (currentStepVal as Steps).lines.l3
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M121.07,226.5h7.3q7.31,0,11.82-.08l4.51-.09" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M150,226.24,143,229.86l1.68-3.53-1.81-3.46Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l3
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
        </g>

        <g id="line-2"
          stroke={
            (currentStepVal as Steps).lines.l2
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M41.07,206.5H74.7" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M80,206.5,73,210l1.75-3.5L73,203Z" transform="translate(-1.07 -7)"
            fill={
              (currentStepVal as Steps).lines.l2
                ? lineColorOn
                : lineColorOff
            }
            strokeMiterlimit="10" />
        </g>

        <g id="line-29"
          stroke={
            (currentStepVal as Steps).lines.l29
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M1142,332.5l.2-13.63" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M1142.27,313.62l3.4,7.05-3.47-1.8-3.53,1.7Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l29
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />

          <text transform="translate(1122.91 339)" strokeWidth="0.4" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">op_alu</text>
        </g>


        <g id="line-27"
          stroke={
            (currentStepVal as Steps).lines.l27
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M100.36,276.5l.2-13.63" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M100.63,257.62l3.4,7.05-3.47-1.8L97,264.57Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l27
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
          <text transform="translate(71.57 283)" fontSize="12" strokeWidth="0.4" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">do_branch</text>
        </g>

        <g id="line-30"
          stroke={
            (currentStepVal as Steps).lines.l30
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <text transform="translate(1397.93 346)" strokeWidth="0.1" fill={
            (currentStepVal as Steps).lines.l30
              ? lineColorOn
              : lineColorOff
          } fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">
            wr_mem
          </text>
          <path d="M1422,339.5l.2-13.63" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M1422.27,320.62l3.4,7.05-3.47-1.8-3.53,1.7Z" transform="translate(-1.07 -7)"
            fill={
              (currentStepVal as Steps).lines.l30
                ? lineColorOn
                : lineColorOff
            } strokeMiterlimit="10"
          />
        </g>

        <g id="line-31"
          stroke={
            (currentStepVal as Steps).lines.l31
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M776.91,177.5l.2-13.63" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M777.18,158.62l3.4,7-3.47-1.8-3.53,1.7Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l31
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />
          <text transform="translate(741.24 184)" fill={
            (currentStepVal as Steps).lines.l31
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" fontSize="12" strokeWidth="0.1" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">sel_reg_data</text>
        </g>


        <g id="line-31"
          stroke={
            (currentStepVal as Steps).lines.l28
              ? lineColorOn
              : lineColorOff
          }
          strokeMiterlimit="10"
          strokeWidth={strokeWidth}>
          <path d="M937,377.5l.2-13.63" transform="translate(-1.07 -7)" fill="none" strokeMiterlimit="10" />
          <path d="M937.27,358.62l3.4,7.05-3.47-1.8-3.53,1.7Z" transform="translate(-1.07 -7)" fill={
            (currentStepVal as Steps).lines.l28
              ? lineColorOn
              : lineColorOff
          } strokeMiterlimit="10" />

          <text strokeWidth="0.1" fill={
            (currentStepVal as Steps).lines.l28
              ? lineColorOn
              : lineColorOff
          } transform="translate(917.59 384)" fontSize="12" fontFamily="Arial-ItalicMT, Arial" fontStyle="italic">wr_reg</text>
        </g>



        {/* ============================================== Lines ========================================================= */}


        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-23">
          <path d="M338.07,241.5l30-30" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>


        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-8">
          <rect x="60" y="269.5" width="80" height="20" fill="none" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-12">
          <rect x="646.43" y="310.5" width="30" height="20" fill="none" />
          <text transform="translate(654.26 324)" fontSize="12" fontFamily="ArialMT, Arial">12</text>
        </g>

        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-46">
          <path d="M787.5,266.5l22.93-22.93L822.5,231.5l5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-47">
          <path d="M787.5,296.5l22.93-22.93L822.5,261.5l5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-48">
          <path d="M787.5,326.5l22.93-22.93L822.5,291.5l5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-58">
          <path d="M1237.5,257.5l22.93-22.93,12.07-12.07,5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-82">
          <path d="M1097.5,117.5l22.93-22.93L1132.5,82.5l5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-83">
          <path d="M1027.5,297.5l22.93-22.93,12.07-12.07,5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-88">
          <path d="M1027.5,217.5l22.93-22.93,12.07-12.07,5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-0nYCs-QFj4XH6EBm0FDg-3">
          <path d="M653.5,352.5l30-30" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-0nYCs-QFj4XH6EBm0FDg-4">
          <path d="M492.07,238.5l19.43-23.72" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-0nYCs-QFj4XH6EBm0FDg-8">
          <path d="M672.79,239.11l19.43-21.72" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-0nYCs-QFj4XH6EBm0FDg-9">
          <path d="M687.5,171.22l19.43-21.72" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-0nYCs-QFj4XH6EBm0FDg-38">
          <path d="M1227.5,377.5l22.93-22.93,12.07-12.07,5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Vg62LgSES3xolwbW8EE_-167" data-name="cell-Vg62LgSES3xolwbW8EE -167">
          <path d="M1487.5,217.5l22.93-22.93,12.07-12.07,5-5" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Ph2drCMUZSP0VuwJ4tQr-23">
          <path d="M338.07,241.5l30-30" transform="translate(-1.07 -7)" stroke='#fff' strokeMiterlimit="10" />
        </g>
        <g id="cell-Vg62LgSES3xolwbW8EE_-158" data-name="cell-Vg62LgSES3xolwbW8EE -158">
          <text fill="#fff" transform="translate(337.26 215)" fontSize="12" fontFamily="ArialMT, Arial">12</text>
        </g>
        <g fill="#fff">

          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-12">
            <text transform="translate(654.26 324)" fontSize="12" fontFamily="ArialMT, Arial" >12</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-24">
            <rect x="658.43" y="138.5" width="30" height="20" fill="none" />
            <text transform="translate(666.26 152)" fontSize="12" fontFamily="ArialMT, Arial" >16</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-87">
            <rect x="1026.43" y="250.5" width="30" height="20" fill="none" />
            <text transform="translate(1034.26 264)" fontSize="12" fontFamily="ArialMT, Arial" >16</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-92">
            <rect x="1021.43" y="170.5" width="30" height="20" fill="none" />
            <text transform="translate(1029.26 184)" fontSize="12" fontFamily="ArialMT, Arial" >16</text>
          </g>
          <g id="cell-0nYCs-QFj4XH6EBm0FDg-39">
            <rect x="1222.43" y="329.5" width="30" height="20" fill="none" />
            <text transform="translate(1230.26 343)" fontSize="12" fontFamily="ArialMT, Arial" >16</text>
          </g>
          <g id="cell-Vg62LgSES3xolwbW8EE_-163" data-name="cell-Vg62LgSES3xolwbW8EE -163">
            <rect x="1236.43" y="210.5" width="30" height="20" fill="none" />
            <text transform="translate(1244.26 224)" fontSize="12" fontFamily="ArialMT, Arial" >16</text>
          </g>
          <g id="cell-Vg62LgSES3xolwbW8EE_-168" data-name="cell-Vg62LgSES3xolwbW8EE -168">
            <rect x="1481.43" y="174.5" width="30" height="20" fill="none" />
            <text transform="translate(1489.26 188)" fontSize="12" fontFamily="ArialMT, Arial" >16</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-85">
            <rect x="1099.43" y="70.5" width="20" height="20" fill="none" />
            <text transform="translate(1105.59 84)" fontSize="12" fontFamily="ArialMT, Arial" >8</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-49">
            <rect x="797.43" y="218.5" width="20" height="20" fill="none" />
            <text transform="translate(803.59 232)" fontSize="12" fontFamily="ArialMT, Arial" >5</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-50">
            <rect x="796.43" y="279.5" width="20" height="20" fill="none" />
            <text transform="translate(802.59 293)" fontSize="12" fontFamily="ArialMT, Arial" >5</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-51">
            <rect x="796.43" y="249.5" width="20" height="20" fill="none" />
            <text transform="translate(802.59 263)" fontSize="12" fontFamily="ArialMT, Arial" >5</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-16">
            <rect x="479" y="201.5" width="30" height="20" fill="none" />
            <text transform="translate(487.26 215)" fontSize="12" fontFamily="ArialMT, Arial" >28</text>
          </g>
          <g id="cell-Ph2drCMUZSP0VuwJ4tQr-18">
            <rect x="660.43" y="202.5" width="30" height="20" fill="none" />
            <text transform="translate(668.26 216)" fontSize="12" fontFamily="ArialMT, Arial" >28</text>
          </g>
        </g>



      </svg>}
    </Fragment >)
}

export default ProcessorRiscSimple