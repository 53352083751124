import styled from "styled-components";
import { Button, Paper } from "@mui/material";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  margin-top: 50px;
  align-items: center;
  `
export const Form = styled.form`
  margin: .5em 1em;
  display: flex;
  flex-direction: column;

  > div{
    margin: .5em 0;
  }
`

export const Logo = styled.img`
  width: 130px;
  height: 130px;
  margin-bottom: .5em;
`;
export const PaperStyled = styled(Paper)`
justify-content: center;
display: flex;
align-items: center;
flex-direction: column;
  background-color: #1f2227;
  padding: 1em;
`

export const ConnectButton = styled(Button)`
  margin: 1em 0;
`
