import { createTheme } from '@mui/material/styles'
import blue from '@mui/material/colors/blue'
import green from '@mui/material/colors/green'
import red from '@mui/material/colors/red'

const theme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: red[200]
    },
    primary: {
      main: blue[500],
    },
    secondary: {
      main: green[500],
    },
    text: {
      primary: '#f3f2f2',
      secondary: 'rgba(253, 253, 253, 0.7)',
      disabled: 'rgba(104, 95, 95, 0.5)'
    },
  },
  typography: {
    fontSize: 12,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  }
})

export default theme
