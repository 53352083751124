import React from 'react'

import { Header } from './Containers/Header/Header'

// import ProcessorsPage from './Pages/ProcessorsPage/ProcessorsPage'
// import HomePage from './Pages/HomePage/HomePage'
// import UserPage from './Pages/UserPage/UserPage'
// import { ProvideAuth } from './Api/Auth/use-auth'
// import PrivateRoute from './Components/PrivateRoute/PrivateRoute'
// import LoginPage from './Pages/LoginPage/LoginPage'
import CustomSnackbar from './Components/Snackbar/Snackbar'
// import AdminPage from './Pages/AdminPage/AdminPage'
// import ProfilePage from './Pages/ProfilePage/ProfilePage'
// import ResetPasswordPage from './Pages/ResetPasswordPage/ResetPasswordPage';
// import CachePage from './Pages/CachePage/CachePage'
import { Outlet } from 'react-router'
import { Container } from './style'

function App() {

  return (
    <Container>
      <Header />
      <Outlet />
      <CustomSnackbar />
    </Container>

  )
}

export default App
