// import axios from "axios";
import React, { useState, useContext, useMemo, createContext } from "react";
// import * as firebase from "firebase/app";
// import "firebase/auth";
import { useSetRecoilState } from 'recoil';
import { getApiAddress } from "../Core/apiAddress";
import { ApiResponse, Roles, UserData } from "../Core/Interfaces";
import { userState } from './../../Recoil/GlobalState';
import { useLocalStorage } from "./useLocalStorage";

// // Add your Firebase credentials
// firebase.initializeApp({
//   apiKey: "",
//   authDomain: "",
//   projectId: "",
//   appID: "",
// });

interface User {
  matricule: number;
  password: string;
  token: string;
}

const authContext = createContext({
  user: null as User | null | undefined,
  signIn: (matricule: number, password: string): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  // signup,
  bulkSignup: (users: UserData[]) => { },
  reset: () => { },
  // removeUser,
  changePassword: (matricule: number, newPassword: string): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  removeUser: (data: number[]): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  addUser: (data: UserData): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  isAdmin: false,
  fetchAll: (): Promise<UserData[]> => new Promise((res, rej) => []),
  signOut: () => { },
  checkUserExists: (matricule: number): Promise<boolean> => new Promise((res, rej) => false),
  checkConnected: (): Promise<boolean> => new Promise((res, rej) => false),
  getUserInfo: (matricule: number): Promise<ApiResponse> => new Promise((res, rej) => ({ hasErrors: false })),
  // sendPasswordResetEmail,
  // confirmPasswordReset,
  isConnected: false
});
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};


function useProvideAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [isConnected, setConnected] = useState(false);
  // const setUserInfo = useSetRecoilState(userState)
  const [isAdmin, setIsAdmin] = useState(false)
  // const [error, setError] = useState('');

  // Wrap any Firebase methods we want to use making sure ...
  // ... to save the user to state.
  const signIn = async (matricule: number, password: string) => {
    const response = await fetch(`${getApiAddress()}api/auth`, {
      method: 'POST',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify({ matricule, password }) // body data type must match "Content-Type" header
    });

    const res = await response.json()
    // console.log('🚀 ~ signIn ~ res', res);
    if (!res.hasErrors) {
      localStorage.setItem('token', res.payload.token)
      setUser(prev => ({ ...prev, matricule, password, token: res.payload.token, role: res.payload.role }))
      console.log('🚀 ~ signIn ~ res.payload.token', res.payload.token);
      if (res.payload.role === Roles.ADMIN || res.payload.role === Roles.TEACHER) {
        setIsAdmin(true);
      }
      setConnected(true)
    } else {
      setUser(null)
    }
    return res
  }

  const addUser = async (data: UserData): Promise<ApiResponse> => {
    try {
      const response = await fetch(`${getApiAddress()}api/user/add`, {
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token') || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      });

      const res = await response.json()
      if (res.errors) {
        console.log('🚀 ~ addUser ~ res.errors', res.errors);
        return { hasErrors: true, errors: res.errors, type: res.type }
      }
      return { hasErrors: false }

    } catch (err: any) {
      console.error(err)
      throw new Error(err.message)
    }
  }

  const removeUser = async (data: number[]) => {
    try {
      const response = await fetch(`${getApiAddress()}api/user/remove`, {
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token') || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ matricules: data }) // body data type must match "Content-Type" header
      });

      return await response.json()

    } catch (err: any) {
      console.error(err)
      throw err;
    }
  }

  const reset = async () => {
    try {
      await fetch(`${getApiAddress()}api/user/reset`, {
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token') || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });
    } catch (err: any) {
      console.error(err)
    }
  }

  const fetchAll = async () => {
    try {
      const response = await fetch(`${getApiAddress()}api/user/all`, {
        method: 'GET',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token') || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      return await response.json()

    } catch (err: any) {
      console.error(err)
    }
  }

  const bulkSignup = async (users: UserData[]) => {
    try {

      const response = await fetch(`${getApiAddress()}api/user/bulkSignup`, {
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token') || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ users }) // body data type must match "Content-Type" header
      });

      await response.json()
    } catch (err: any) {
      console.error("error: ", err.message)
      throw new Error(err.message)
    }
  }

  const getUserInfo = async (matricule: number) => {
    try {
      const response = await fetch(`${getApiAddress()}api/user`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('token') || ''
        },
        body: JSON.stringify({ matricule }) // body data type must match "Content-Type" header
      });

      const data: ApiResponse = await response.json()
      return data
    } catch (err: any) {
      console.error(err)
      throw err
    }
  }

  const changePassword = async (matricule: number, newPassword: string): Promise<ApiResponse> => {
    try {
      const response = await fetch(`${getApiAddress()}api/auth/changePassword`, {
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem("token") || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ matricule, newPassword }) // body data type must match "Content-Type" header

      });
      return await response.json();
    } catch (err: any) {
      console.error("checkUserExists: ", err.message)
      throw err
    }
  }

  const checkUserExists = async (matricule: number): Promise<boolean> => {
    try {
      const response = await fetch(`${getApiAddress()}api/auth/isUnique`, {
        method: 'POST',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem("token") || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify({ matricule }) // body data type must match "Content-Type" header

      });

      const res = await response.json();
      if (res.payload) {
        return true;
      } else {
        return false;
      }
    } catch (err: any) {
      console.error("checkUserExists: ", err.message)
      throw err
    }
  }

  const checkConnected = async (): Promise<boolean> => {
    if (user) {
      console.log("user exists")
      setConnected(true)
      return true
    }

    console.log("check connected is called")

    try {
      const response = await fetch(`${getApiAddress()}api/auth`, {
        method: 'GET',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem("token") || ''
        },
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      });

      const res = await response.json()

      if (res.hasErrors) {
        return false;
      }

      if (!res.payload) {
        throw new Error("no_payload")
      }

      console.log(`supposed to update the user here`)

      setUser(prev => ({ ...prev, matricule: res.payload.matricule, password: '', token: localStorage.getItem("token") || '' }))

      if (res.payload.role && (res.payload.role === Roles.ADMIN || res.payload.role === Roles.TEACHER)) {
        console.log("setting it to true")
        setIsAdmin(true);
      }

      setConnected(true)

      return true;
    } catch (err: any) {
      console.error('🚀 ~ checkConnected ~ err', err.message);
      setConnected(false)
      return false
    }
  }

  // const signup = (email, password) => {
  //   return firebase
  //     .auth()
  //     .createUserWithEmailAndPassword(email, password)
  //     .then((response) => {
  //       setUser(response.user);
  //       return response.user;
  //     });
  // };
  const signOut = () => {
    localStorage.removeItem("token")
    setIsAdmin(false)
    setUser(null);
  };
  // const sendPasswordResetEmail = (email) => {
  //   return firebase
  //     .auth()
  //     .sendPasswordResetEmail(email)
  //     .then(() => {
  //       return true;
  //     });
  // };
  // const confirmPasswordReset = (code, password) => {
  //   return firebase
  //     .auth()
  //     .confirmPasswordReset(code, password)
  //     .then(() => {
  //       return true;
  //     });
  // };
  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  // useEffect(() => {
  //   const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
  //     if (user) {
  //       setUser(user);
  //     } else {
  //       setUser(null);
  //     }
  //   });
  //   // Cleanup subscription on unmount
  //   return () => unsubscribe();
  // }, []);
  // Return the user object and auth methods
  return useMemo(() => ({
    user,
    signIn,
    // signup,
    addUser,
    signOut,
    checkConnected,
    getUserInfo,
    bulkSignup,
    isAdmin,
    removeUser,
    reset,
    checkUserExists,
    fetchAll,
    changePassword,
    isConnected
    // sendPasswordResetEmail,
    // confirmPasswordReset,
  }), [user]);
}