import React, { Fragment } from 'react'
import { CircularProgress, Collapse, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material'
import { Answer, Severity } from '../../Api/Core/Interfaces'
import { Typography } from '@mui/material'
import { Code, NoAnswersYet, Wrapper } from './Styled';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { assemblerEditorState, errorState, hexaEditorState, loadingState, modalState, stepListState } from '../../Recoil/GlobalState';
import { compile } from '../../Api/Core/Processors';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../Api/Auth/use-auth';

interface Props {
  answers: Answer[];
  processorId: number;
  problemId: string;
}

const Answers = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const [collapse, setCollapse] = React.useState(-1);
  const [loadingValues, setLoadingState] = useRecoilState(loadingState)
  const setHexaState = useSetRecoilState(hexaEditorState)
  const setStepList = useSetRecoilState(stepListState)
  const setCurrentErrorState = useSetRecoilState(errorState)
  const setAssemblerState = useSetRecoilState(assemblerEditorState)
  const setOpenModal = useSetRecoilState(modalState)
  const { t } = useTranslation()
  const auth = useAuth()

  const handleSubmit = async (content: string[], index: number) => {
    try {
      setCollapse(index);

      setLoadingState((prev) => ({ ...prev, compiling: true }))
      if (auth.user) {

        const data = await compile(auth.user.matricule, content, props.processorId, props.problemId)

        if (data) {
          setStepList(data.steps)
          setAssemblerState((prev) => ({
            ...prev,
            content: content.join('\n'),
            newLoadedData: true
          }))
          setHexaState((prev) => ({
            ...prev,
            content: data.hex.join('\n'),
          }))
          setOpenModal((prev) => ({ ...prev, showAnswers: false }))

        }
      } else {

        setCurrentErrorState((prev) => ({
          ...prev,
          message: "no_user",
          open: true,
          severity: Severity.Error,
        }))
      }

      setLoadingState((prev) => ({ ...prev, compiling: false }))
    } catch (err: any) {
      setCurrentErrorState((prev) => ({
        ...prev,
        message: err.message,
        open: true,
        severity: Severity.Error,
      }))
      setLoadingState((prev) => ({ ...prev, compiling: false }))
    }
  }

  // const handleListItemClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
  //   console.log("hello daddy: ", index)
  // };

  const handleCollapse = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, index: number) => {
    setCollapse(index);
    setOpen(!open)
  };

  return (
    <Wrapper>
      {props.answers.length ?
        <List component="nav" aria-label="main mailbox folders">
          {props.answers.length && props.answers.map((answer: Answer, i: number) =>
            <Fragment>

              <ListItem
                key={i}
              >
                <ListItemText primary={new Date(answer.published).toUTCString()} />
                {open && i === collapse ?
                  <IconButton onClick={(event) => handleCollapse(event, i)} edge="end" aria-label="send">
                    <ExpandLess />
                  </IconButton> :
                  <IconButton onClick={(event) => handleCollapse(event, i)} edge="end" aria-label="send">
                    <ExpandMore />
                  </IconButton>
                }

                <ListItemSecondaryAction>
                  {loadingValues.compiling && i === collapse ?
                    <CircularProgress size={24} /> :
                    <IconButton onClick={(event) => handleSubmit(answer.content, i)} edge="end" aria-label="send">
                      <SendIcon />
                    </IconButton>
                  }
                </ListItemSecondaryAction>

              </ListItem>
              <Collapse in={open && i === collapse} timeout="auto" unmountOnExit>
                <Code>

                  {answer.content.map(
                    (line: string, i: number) =>
                      <Typography key={i}>
                        {line}
                      </Typography>
                  )}
                </Code>
              </Collapse>
            </Fragment>
          )}
        </List>
        : <NoAnswersYet>{t('answers.no_answer')}</NoAnswersYet>}
    </Wrapper>
  )
}

export default Answers
