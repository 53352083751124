import styled from "styled-components";
import { Button, Paper, Typography } from "@mui/material";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  /* justify-content: center; */
  margin-top: 50px;
  /* align-items: center; */
  `
export const Form = styled.div`
  margin: .5em 1em;
  display: flex;
  flex-direction: column;

  > div{
    margin: .5em 0;
  }
`
export const PaperStyled = styled(Paper)`
/* justify-content: center; */
display: flex;
margin: 1em;
/* align-items: center; */
  padding: 1em;
`

export const CourseTitle = styled(Typography)`
  margin: .5em .5em;

`

export const ProcessorImg = styled.img`
  width: 200px;
  height: 80px;
  display: flex;
  align-self: flex-start;
`

export const ProcessorTitle = styled(Typography)`
  margin: .5em .5em;

`

export const ProblemContainer = styled.div`
  margin: .5em .5em;
`

export const ConnectButton = styled(Button)`
  margin: 1em 0;
`
