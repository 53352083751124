import React, { useState } from 'react'
import { ButtonContainer, CancelButton, ConnectButton, Container, Form } from './Styled'
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import { useAuth } from '../../Api/Auth/testingAuth'
import { useTranslation } from 'react-i18next'
import { useSetRecoilState } from 'recoil'
import { errorState } from '../../Recoil/GlobalState'
import { Roles, Severity, UserApiData } from '../../Api/Core/Interfaces'
import { UserData } from './../../Api/Core/Interfaces';

interface Props {
  showAddUser: React.Dispatch<React.SetStateAction<boolean>>
}

const AddUser = ({ showAddUser }: Props) => {
  const [values, setValues] = useState({
    matricule: '',
    firstname: '',
    lastname: '',
    email: '',
    role: Roles.STUDENT
  })
  const [errorMsgs, setErrors] = useState({
    matricule: 0,
    firstname: '',
    lastname: '',
    email: '',
    role: Roles.STUDENT
  } as UserApiData)

  const [loading, setLoading] = useState(false)

  const { t } = useTranslation()
  const setCurrentErrorState = useSetRecoilState(errorState)
  let auth = useAuth()

  const handleCancel = () => {
    showAddUser(false)
  }

  const handleChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClick = async () => {
    try {
      setLoading(true)
      const { hasErrors, errors, type } = await auth.addUser({
        matricule: parseInt(values.matricule),
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        role: values.role
      })

      if (hasErrors && errors) {
        if (type) {
          if (type === 'user_already_exists') {
            setCurrentErrorState((prev) => ({
              ...prev,
              open: true,
              message: t('errors.user_already_exists'),
              severity: Severity.Error,
            }))
            setLoading(false)
          }
        } else {
          let tempError: UserApiData = {
            matricule: 0,
            firstname: '',
            lastname: '',
            email: '',
            role: Roles.STUDENT
          };
          for (const err of errors) {
            if (err.param) {
              tempError = { ...tempError, [err.param]: err.msg };
            }
          }
          setErrors(tempError)
        }
      } else {

      }

      setLoading(false)
      setCurrentErrorState((prev) => ({
        ...prev,
        open: true,
        message: t('add_user.added_successfully'),
        severity: Severity.Success,
      }))
      setValues({
        matricule: '',
        firstname: '',
        lastname: '',
        email: '',
        role: Roles.STUDENT
      })
    } catch (err: any) {
      console.log('🚀 ~ handleClick ~ err', err);
      setCurrentErrorState((prev) => ({
        ...prev,
        open: true,
        message: err.message[0],
        severity: Severity.Error,
      }))
      setLoading(false)
    }
  }


  return (
    <Container>
      {/* <Typography>{t('login.welcome_to')} Code Machine</Typography> */}
      <Form>
        <TextField
          label={t('add_user.firstname')}
          error={!!errorMsgs.firstname}
          helperText={!!errorMsgs.firstname ? errorMsgs.firstname : ""}
          variant="filled"
          name="firstname"
          value={values.firstname}
          onChange={handleChange('firstname')}
        />
        <TextField
          label={t('add_user.lastname')}
          error={!!errorMsgs.lastname}
          helperText={!!errorMsgs.lastname ? errorMsgs.lastname : ""}
          variant="filled"
          name="lastname"
          value={values.lastname}
          onChange={handleChange('lastname')}
        />
        <TextField
          label={t('add_user.email')}
          error={!!errorMsgs.email}
          helperText={!!errorMsgs.email ? errorMsgs.email : ""}
          variant="filled"
          name="email"
          value={values.email}
          onChange={handleChange('email')}
        />
        <TextField
          label={t('add_user.matricule')}
          error={!!errorMsgs.matricule}
          helperText={!!errorMsgs.matricule ? errorMsgs.matricule : ""}
          variant="filled"
          type="number"
          name="matricule"
          value={values.matricule}
          onChange={handleChange('matricule')}
        />
        <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">{t('admin_page.role')}</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-outlined"
            value={values.role}
            onChange={handleChange('role')}
            label={t("admin_page.role")}
          >
            <MenuItem value={Roles.STUDENT}>{t("admin_page.student")}</MenuItem>
            <MenuItem value={Roles.ADMIN}>{t("admin_page.admin")}</MenuItem>
            <MenuItem value={Roles.TEACHER}>{t("admin_page.teacher")}</MenuItem>
          </Select>
        </FormControl>

        {/* {errorMsgs.map(()) && (
          <Typography color="error">{values.error}</Typography>
        )} */}
        <ButtonContainer>

          <ConnectButton
            variant="contained"
            color="primary"
            onClick={handleClick}
          >
            {loading ? <CircularProgress size={24} />
              : t('add_user.add_user')}
          </ConnectButton>
          <CancelButton
            variant="contained"
            color="primary"
            onClick={handleCancel}
          >
            {t('add_user.cancel')}
          </CancelButton>
        </ButtonContainer>
      </Form>

    </Container>
  )
}

export default AddUser
