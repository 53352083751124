import styled from 'styled-components'
import Slider from '@mui/material/Slider'
import { Box, FormControl } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';

// import SettingsIcon from '@mui/icons-material/Settings';

export const FormControlStyled = styled(FormControl)`
    width: 120px;
`;

export const MediaStyled = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1em;
`

export const StyledCircularProgress = styled(CircularProgress)`
    /* margin-top: 10px; */
`

export const SliderStyled = styled(Slider)`
    margin-top: 1.5em;
    `

export const IconContainer = styled.div`
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-right: 1.5em;
`

export const SliderContainer = styled.div`
    margin-right: .5em;
    width:80%;
`
export const DefaultBox = styled(Box)`
    width: 150px;
    display: flex;
    margin: 1em;
    height: 120px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
export const IrStatusBox = styled(DefaultBox)`
background-color: #f57e7e;
`

export const StateStatusBox = styled(DefaultBox)`
background-color: #7ecff5;
`

export const AccStatusBox = styled(DefaultBox)`
background-color: #94d887;
`

export const PcStatusBox = styled(DefaultBox)`
background-color: #f17ef5;
`
